import { Box, HStack, VStack } from '@chakra-ui/react';
import { Button } from 'Atoms';
import { useMaterialStandardId } from 'containers/Esrs/EsrsAssessment.hooks';
import { useGetBuDisclosureRequirementMetricsQuery, useGetShortEsrsStandardQuery } from 'models';
import { ContentLayout, Loader } from 'Molecules';
import { TOP_MENU_HEIGHT } from 'containers/Navigation/pieces';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Typography } from 'Tokens';
import { ArrowLeftIcon } from 'Tokens/Icons/Direction';
import { MetricViewEnums, UNKONWN_ROUTE } from '..';
import { Structure } from '../../Assessment/Structure';
import { Metrics } from './Metrics';

export const MetricsDisclosureRequirements = () => {
  const {
    companyId = '',
    standardRef = '',
    esrsAssessmentId = '',
    reportingUnitId = '',
    disclosureRequirementRef,
    view,
  } = useParams();
  const navigate = useNavigate();
  const [selectedNodeKey, setSelectedNodeKey] = useState<string>('');

  const { data: standardData, loading: standardLoading } = useGetShortEsrsStandardQuery({
    variables: { reference: standardRef },
    skip: !standardRef,
  });

  const { companyAssessmentId: companyStandardId, parentAssessmentId: parentStandardId } =
    useMaterialStandardId(standardRef, esrsAssessmentId);

  const { data, loading: metricLoading } = useGetBuDisclosureRequirementMetricsQuery({
    variables: {
      standardRef,
      companyStandardId: companyStandardId,
      parentStandardId: parentStandardId || companyStandardId,
    },
    skip: !companyStandardId,
  });

  const standard = useMemo(() => standardData?.esrsStandard, [standardData]);
  const disclosureRequirements = useMemo(
    () =>
      [...(data?.requirements ?? [])].sort((a, b) => {
        if (a.order !== b.order) {
          return a.order - b.order;
        }
        return a.reference.localeCompare(b.reference);
      }),
    [data]
  );

  const handleNodeSelect = (nodeKey: string) => {
    navigate(
      `/${companyId}/esrs/${esrsAssessmentId}/bu-standard/${standardRef}/bu/${reportingUnitId}/disclosure-requirement/${nodeKey}/${
        view ?? MetricViewEnums.dataInput
      }`
    );
    setSelectedNodeKey(nodeKey);
  };

  useEffect(() => {
    if (!disclosureRequirementRef || disclosureRequirementRef === UNKONWN_ROUTE) {
      setSelectedNodeKey(disclosureRequirements[0]?.reference);
    } else {
      setSelectedNodeKey(disclosureRequirementRef);
    }
  }, [disclosureRequirements, disclosureRequirementRef]);

  if (standardLoading || metricLoading) {
    return <Loader />;
  }

  return (
    <Box flexGrow="1" width="100%" display="flex" alignItems="flex-start">
      <HStack alignItems="stretch" flexGrow="1" gap="0">
        <VStack
          height={`calc(100vh - ${TOP_MENU_HEIGHT})`}
          borderRight="1px solid"
          borderRightColor="border.decorative"
          overflow="hidden"
        >
          <Structure
            minWidth="200px"
            maxWidth="288px"
            overflow="auto"
            header={
              <VStack p="10px 16px 8px" spacing="24px" alignItems="start">
                <Button
                  variant="ghost"
                  onClick={() => navigate(`/${companyId}/esrs/${esrsAssessmentId}`)}
                  leftIcon={<ArrowLeftIcon />}
                >
                  Back
                </Button>
                <VStack spacing="4px" alignItems="start" pb="8px !important">
                  <Typography variant="h2">{standard?.title}</Typography>
                  <Typography variant="body" color="text.muted">
                    {standard?.description}
                  </Typography>
                </VStack>
                <Typography variant="overline" color="text.muted" textTransform="uppercase">
                  {'Disclosure requirements'}
                </Typography>
              </VStack>
            }
            nodes={[
              ...disclosureRequirements?.map((dr) => ({
                title: dr.title,
                key: dr.reference,
                reference: dr.reference,
              })),
            ]}
            selectedNodeKey={selectedNodeKey}
            onSelectNode={handleNodeSelect}
            size="sm"
          />
        </VStack>
        <VStack height={`calc(100vh - ${TOP_MENU_HEIGHT})`} width="100%" overflow="hidden auto">
          <ContentLayout variant="inline" header={false}>
            <Metrics
              disclosureRequirementRef={selectedNodeKey}
              disclosureRequirement={
                disclosureRequirements?.find((dr) => dr.reference === selectedNodeKey) ?? undefined
              }
            />
          </ContentLayout>
        </VStack>
      </HStack>
    </Box>
  );
};
