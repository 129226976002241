import { useUserData } from '@nhost/react';
import {
  DatapointFieldsFragment_,
  ShortUser,
  useUpsertDatapointMutation,
  Esrs_Answer_Constraint_,
  Esrs_Answer_Update_Column_,
  GetEsrsMetricAnswerDocument_,
  CompanyLevelMetricsPerDisclosureQuery_,
  Esrs_DatapointTag_Constraint_,
  AttachmentBox_Constraint_,
  AttachmentBox_Update_Column_,
  useGetEsrsMetricsAnswersSubscription,
  GetEsrsMetricsDatapointsDocument_,
  NoteHistory_Constraint_,
  NoteHistory_Update_Column_,
  useGetSingleEsrsMetricAnswerQuery,
  GetMetricAnswersDocument_,
  EsrsAssessmentAnswersAggregateDocument_,
  Esrs_DatapointChoice_Constraint_,
  useDeleteDatapointChoicesMutation,
} from 'models';
import { useMemo, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { AssessableMetrics } from './Metrics';
import { TableData } from 'Molecules/NestedTable';
import { FrequencyEnums, TimePeriods, TIME_FRAMES_ARRAY } from '../Requirement';
import {
  ESRS_KNOWN_VARIABLES,
  filterByCorrectTags,
  filterByHasTags,
  resolveMetricCalculation,
  showResultAsPercentage,
} from './AggregatedMetrics/calculations';
import { getNestedMetrics } from './AggregatedMetrics';
import { uniq } from 'lodash';

type TagOptions =
  CompanyLevelMetricsPerDisclosureQuery_['assessableMetrics'][number]['materialMetrics'][number]['materialMetricTags'];
type Combination = Record<string, string>;

type MetricsExtraData = {
  metric: AssessableMetrics[number];
  lastEditedByName?: string;
  lastEditedDate?: string;
  tags?: {
    tagType: string;
    tagValue: string;
  }[];
  referenceToSource?: string;
  tagName?: string;
  tagType?: string;
  isChild?: boolean;
  parentMetric?: AssessableMetrics[number];
  hidden?: boolean;
};

export type MetricsTableData = TableData<MetricsExtraData>;

export const useEsrsAnswer = (metricRef: string, companyReportingUnit?: string) => {
  const [upsertDatapoint, { loading: isSaving }] = useUpsertDatapointMutation();
  const [deleteChoices] = useDeleteDatapointChoicesMutation();
  const user: ShortUser | null = useUserData();
  const { reportingUnitId, esrsAssessmentId = '' } = useParams();
  const { data, loading, refetch } = useGetSingleEsrsMetricAnswerQuery({
    variables: {
      reportingUnitId: reportingUnitId || companyReportingUnit,
      assessmentId: esrsAssessmentId,
      metricRef,
    },
    skip: !esrsAssessmentId || !metricRef || (!reportingUnitId && !companyReportingUnit),
  });
  const answer = useMemo(() => {
    return data?.esrs_Answer[0];
  }, [data]);

  const datapoints: DatapointFieldsFragment_[] = useMemo(() => {
    return data?.esrs_Answer[0]?.datapoints ?? [];
  }, [data]);

  const onDatapointChange = useCallback(
    async ({
      value,
      factValue,
      hasOptedOut,
      optOutReason,
      dp,
      tags,
      assessmentProjectLeaderId,
      choices,
    }: {
      value: string | null;
      factValue?: string | null;
      hasOptedOut: boolean;
      optOutReason: string;
      dp: Partial<DatapointFieldsFragment_>;
      tags?: {
        tagType: string;
        tagValue: string;
      }[];
      assessmentProjectLeaderId?: string;
      choices?: string[];
    }) => {
      const choicesToDelete = dp?.datapointChoices
        ?.filter((d) => !choices?.includes(d.choiceRef))
        ?.map((c) => c.choiceRef);

      if (!!choicesToDelete?.length) {
        await deleteChoices({
          variables: {
            datapointId: dp.id,
            choices: choicesToDelete,
          },
        });
      }

      await upsertDatapoint({
        variables: {
          objects: {
            id: dp?.id,
            value: value,
            factValue: factValue,
            timeframe: dp?.timeframe,
            authorId: user?.id,
            ownerId: dp?.ownerId ?? assessmentProjectLeaderId,
            isAIGenerated: false,
            datapointTags: {
              data: tags ?? [],
              on_conflict: {
                constraint:
                  Esrs_DatapointTag_Constraint_.DatapointTagDatapointIdTagValueTagTypeKey_,
              },
            },
            datapointChoices: {
              data: choices?.map((choice) => ({ choiceRef: choice })) ?? [],
              on_conflict: {
                constraint:
                  Esrs_DatapointChoice_Constraint_.DatapointChoiceDatapointIdChoiceRefKey_,
              },
            },
            answer: {
              data: {
                metricRef: metricRef,
                hasOptedOut: hasOptedOut,
                optOutReason: optOutReason,
                reportingUnitId: reportingUnitId || companyReportingUnit,
                assessmentId: esrsAssessmentId,
                attachmentBox: {
                  data: {},
                  on_conflict: {
                    constraint: AttachmentBox_Constraint_.AttachmentBoxMetricAnswerIdKey1_,
                    update_columns: [AttachmentBox_Update_Column_.MetricAnswerId_],
                  },
                },
                noteHistory: {
                  data: {},
                  on_conflict: {
                    constraint: NoteHistory_Constraint_.NoteHistoryMetricAnswerIdKey_,
                    update_columns: [NoteHistory_Update_Column_.MetricAnswerId_],
                  },
                },
                optOutAuthorId: user?.id,
              },
              on_conflict: {
                constraint: Esrs_Answer_Constraint_.AnswerMetricRefAssessmentIdReportingUnitIdKey_,
                update_columns: [
                  Esrs_Answer_Update_Column_.HasOptedOut_,
                  Esrs_Answer_Update_Column_.OptOutReason_,
                  Esrs_Answer_Update_Column_.OptOutAuthorId_,
                ],
              },
            },
          },
        },
        refetchQueries: [
          GetEsrsMetricAnswerDocument_,
          GetEsrsMetricsDatapointsDocument_,
          GetMetricAnswersDocument_,
          EsrsAssessmentAnswersAggregateDocument_,
        ],
      });
      refetch();
    },
    [upsertDatapoint, datapoints]
  );
  return {
    answer,
    datapoints,
    isSaving,
    loading,
    onDatapointChange,
  };
};

export const isFrequencyYearly = (row: MetricsTableData, companyStandardId: string) => {
  return (
    row.metric?.materialMetrics.find((mm) => mm.materialStandardId === companyStandardId)
      ?.frequency === FrequencyEnums.yearly
  );
};

export const areArraysOfObjectsEqual = (arr1: Combination[] = [], arr2: Combination[] = []) => {
  if (arr1.length !== arr2.length) {
    return false;
  }

  const sortedArr1 = [...arr1].sort((a, b) => {
    return a?.tagType.localeCompare(b?.tagType);
  });
  const sortedArr2 = [...arr2].sort((a, b) => {
    return a?.tagType.localeCompare(b?.tagType);
  });
  return sortedArr1.every((item, index) =>
    Object.keys(item).every((key) => item[key] === sortedArr2[index][key])
  );
};

export const addUpValues = (values: Array<string | number>) => {
  if (values.some((x) => typeof x === 'string')) return ESRS_KNOWN_VARIABLES.NONE;
  return values.reduce((a, b) => Number(a) + Number(b), 0);
};

const getRefsFromSubRows = (row: MetricsTableData): string[] => {
  if (row?.subRows) {
    return row?.subRows
      ?.map((subrow) => [subrow?.metric?.reference, ...getRefsFromSubRows(subrow)])
      .flat();
  }
  return [
    row?.metric?.reference,
    ...(row?.parentMetric?.reference ? [row?.parentMetric?.reference] : []),
  ];
};

type ExtendedDatapoint = {
  value?: any;
  timeframe: string;
  updatedAt: string;
  answerId: string;
  metricRef: string;
  tagValues: string[];
};

export const getUniqueDataPoints = (data: ExtendedDatapoint[]) => {
  const uniqueMap = data.reduce<{ [key: string]: any }>((acc, datapoint) => {
    // Create a unique key based on answerId, stringified tagValues, and timeframe
    const key = `${datapoint.answerId}-${JSON.stringify(datapoint.tagValues)}-${
      datapoint.timeframe
    }`;

    // If the key doesn't exist in the acc or the current datapoint is newer: add/replace it
    if (!acc[key] || new Date(datapoint.updatedAt) > new Date(acc[key].updatedAt)) {
      acc[key] = datapoint;
    }

    return acc;
  }, {});

  return Object.values(uniqueMap);
};

export const useAggregatedRowValue = (
  row: MetricsTableData,
  isYearly: boolean,
  companyReportingUnit?: string,
  ignoreTags?: boolean
) => {
  const { reportingUnitId, esrsAssessmentId = '' } = useParams();
  const metricRefs = useMemo(() => uniq(getRefsFromSubRows(row)), [row]);
  const { data, loading } = useGetEsrsMetricsAnswersSubscription({
    variables: {
      reportingUnitId: reportingUnitId || companyReportingUnit,
      assessmentId: esrsAssessmentId,
      metricRefs,
    },
    skip: !esrsAssessmentId || !metricRefs || (!reportingUnitId && !companyReportingUnit),
  });
  const metricWithChildren = useMemo(() => getNestedMetrics([row]), [row]);

  const datapoints = useMemo(() => {
    // This deals with a metric that has children (tags) but DID NOT USE TO, so it has some old dps that are not tagged

    const dps = data?.answers.flatMap((answer) => {
      const currentMetric = metricWithChildren.find((m) => m.metric.reference === answer.metricRef);
      const hasTags =
        currentMetric?.metric?.materialMetrics?.find(
          (mm) => mm.materialStandard.esrsAssessment.id === esrsAssessmentId
        )?.materialMetricTags.length ?? 0 > 0;
      const metricWithChildrenAndTags =
        !!row.tagType && !row.tagName && row.metric.childrenMetrics.length;

      const isATagOfAParentWithChildren =
        !row.tagType && !!row.tagName && row.metric.childrenMetrics.length;

      return answer.datapoints
        .map((dp) => ({
          ...dp,
          metricRef: answer.metricRef,
          tagValues: dp.datapointTags?.map((t) => t.tagValue) ?? [],
        }))
        .filter((dp) => {
          if (ignoreTags || metricWithChildrenAndTags) return true;
          if (isATagOfAParentWithChildren) {
            return dp.tagValues.includes(row?.tagName ?? '');
          }
          if (hasTags) {
            const rowWithNestedTags = currentMetric?.subRows
              ?.flatMap((subRow) => subRow.subRows)
              .filter((r) => !!r) as TableData<MetricsExtraData>[] | undefined;
            const tagRows = !!rowWithNestedTags?.length
              ? rowWithNestedTags
              : currentMetric?.subRows;
            return tagRows?.some((subRow) =>
              areArraysOfObjectsEqual(subRow.tags ?? [], dp.datapointTags)
            );
          }
          if (!hasTags && !answer.metric.childrenMetrics.length) {
            return dp.tagValues.length <= 0;
          }
          if (!!answer.metric.childrenMetrics.length) {
            return false;
          }
          return true;
        });
    });
    return getUniqueDataPoints(dps ?? []);
  }, [data, ignoreTags, metricWithChildren]);

  const datapointsPerTimeframe = useMemo(() => {
    return TIME_FRAMES_ARRAY.map((timeframe) => {
      const correspondingDatapoint = datapoints?.find((datapoint) => {
        if (datapoint.timeframe !== timeframe) return false;

        if (ignoreTags) return true;
        if (row.tags?.length && !datapoint.datapointTags.length) {
          return false;
        }
        // Row IS a child with a tag
        if (!!row.tagName || !!row.tagType) {
          return row.subRows?.some((subRow) =>
            filterByCorrectTags(datapoint, [subRow?.tags ?? []])
          );
        }
        if (row?.subRows && filterByHasTags(datapoint, false)) {
          return false;
        }
        // Get ones with tags
        if (row?.tags?.length) {
          return filterByCorrectTags(datapoint, [row?.tags]);
        }

        // Get ones without tags
        return filterByHasTags(datapoint, false);
      });
      return { field: timeframe, value: correspondingDatapoint };
    });
  }, [datapoints, row, ignoreTags]);

  const totalPerTimeframe = useMemo(() => {
    const allTimeFrames = [
      ...new Set(datapointsPerTimeframe?.map((datapoint) => datapoint.field)),
    ] as TimePeriods[];

    return allTimeFrames.reduce(
      (totals, timeframe) => {
        const correctDps = datapoints
          ?.filter((dp) => dp.timeframe === timeframe)
          .filter((dp) => {
            if (ignoreTags) return true;
            if (row.tagName) {
              return row.subRows?.some(
                (subRow) => subRow?.tags && areArraysOfObjectsEqual(subRow?.tags, dp?.datapointTags)
              );
            }
            return true;
          });
        totals[timeframe] = resolveMetricCalculation(
          (correctDps ?? [])?.map((x) => ({
            value: x?.value ?? 0,
            metricRef: x.metricRef,
            tagValues: x.tagValues ?? [],
          })),
          row?.metric?.calculation
        );
        return totals;
      },
      {} as { [key in TimePeriods]: number | string }
    );
  }, [datapointsPerTimeframe, ignoreTags, row]);

  const year = useMemo(() => {
    return isYearly ? totalPerTimeframe?.Year : addUpValues(Object.values(totalPerTimeframe));
  }, [totalPerTimeframe]);

  const latestDate =
    datapoints && new Date(Math.max(...datapoints.map((dp) => new Date(dp.updatedAt).getTime())));
  const latestAuthor =
    datapoints &&
    datapoints.find((dp) => new Date(dp.updatedAt).getTime() === latestDate?.getTime())?.author;
  return {
    result: {
      ...totalPerTimeframe,
      Year: year,
      latestDate: latestDate,
      latestAuthor: latestAuthor,
    } as {
      [key in TimePeriods]: number;
    } & { latestDate: Date; latestAuthor: ShortUser },
    isLoading: loading,
  };
};

export const findParentRow = (nestedRows: MetricsTableData, row: MetricsTableData) => {
  const metric = row.metric.reference;
  if (nestedRows.metric.reference === metric) return nestedRows;
  else nestedRows.subRows?.map((subRow) => findParentRow(subRow, row));
  return row;
};

export const usePercentageValues = (
  parentRow: MetricsTableData,
  row: MetricsTableData,
  selectedQuarter: TimePeriods,
  isYearly: boolean,
  materialStandardId: string,
  aggregatedValue?: number | string | null,
  companyReportingUnitId?: string
) => {
  const parentHasChildrenAndTags =
    !!row.parentMetric &&
    !!row.parentMetric.materialMetrics.find((mm) => mm.materialStandardId === materialStandardId)
      ?.materialMetricTags.length;

  const { result: parentTotals } = useAggregatedRowValue(
    parentRow,
    isYearly,
    companyReportingUnitId,
    parentHasChildrenAndTags
  );

  const isParent = (row?.subRows?.length ?? 0) > 0;

  if (isParent) return '(100%)';

  return showResultAsPercentage(aggregatedValue, parentTotals[selectedQuarter]);
};

export const generateTagsCombinations = (tagOptions: TagOptions): Combination[] => {
  const combinations: Combination[] = [];

  function recursiveCombinations(index: number, currentCombination: Combination) {
    if (index === tagOptions?.length) {
      combinations.push({ ...currentCombination });
      return;
    }

    const { tagType, valueOptions } = tagOptions[index];

    valueOptions.forEach((option) => {
      currentCombination[tagType] = option.tagValue;
      recursiveCombinations(index + 1, currentCombination);
      delete currentCombination[tagType];
    });
  }
  recursiveCombinations(0, {});

  return combinations;
};

const getMultiTagCombinationValues = (combination: Combination) =>
  Object.values(combination).slice(0, -1).join(' - ');

const getMultiTagCombinationType = (combination: Combination) => {
  const arrayCombination = Object.keys(combination);
  const lastItemIndex = arrayCombination?.length - 1;
  return arrayCombination[lastItemIndex] ?? '';
};

const getSingleTagValue = (combination: Combination) =>
  Object.values(combination)[Object.keys(combination)?.length - 1];

export const getTagsCombination = (tags: TagOptions) => {
  const combinations = generateTagsCombinations(tags ?? []);
  const lastTagValueLength = tags[tags.length - 1].valueOptions.length;
  const splitCombinations = [];
  for (let i = 0; i < combinations.length; i += lastTagValueLength) {
    splitCombinations.push(combinations.slice(i, i + lastTagValueLength));
  }
  const allTagsArray = splitCombinations.flatMap((currentCombination) =>
    currentCombination.map((row) =>
      Object.entries(row ?? {}).map(([key, val]) => ({
        tagType: key,
        tagValue: val,
      }))
    )
  );

  return { splitCombinations, allTagsArray };
};

const getNestedTags = (
  metric: AssessableMetrics[number],
  tags: TagOptions,
  materialStandardId: string,
  parentMetric?: AssessableMetrics[number]
): MetricsTableData => {
  const { splitCombinations } = getTagsCombination(tags);

  const isSingleType = tags.length === 1;

  if (isSingleType) {
    if (!metric.childrenMetrics?.length)
      return {
        metric: metric,
        tagType: tags?.[0]?.tagType,
        subRows: tags?.[0]?.valueOptions.map((value) => ({
          metric: metric,
          tagName: value.tagValue,
          tags: [{ tagType: tags[0].tagType, tagValue: value.tagValue }],
        })),
        isChild: true,
        parentMetric: parentMetric,
      };

    return {
      metric: metric,
      tagType: tags[0].tagType,
      subRows: tags[0].valueOptions.map((value) => ({
        metric: metric,
        tagName: value.tagValue,
        subRows: metric.childrenMetrics.map((child) =>
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          getNestedRows(
            child?.childMetric as AssessableMetrics[number],
            materialStandardId,
            metric,
            true,
            {
              tagName: `${value.tagValue}`,
              tags: [{ tagType: tags[0].tagType, tagValue: value.tagValue }],
            }
          )
        ),
      })),
      isChild: true,
      parentMetric: parentMetric,
    };
  }

  // Deal with case of multiple tags for children case?
  return {
    metric: metric,
    subRows: splitCombinations.map((currentCombination) => ({
      metric: metric,
      tagName: getMultiTagCombinationValues(currentCombination[0]),
      tagType: getMultiTagCombinationType(currentCombination[0]),
      subRows: currentCombination.map((row) => {
        const tagsObject = Object.entries(row ?? {}).map(([key, val]) => ({
          tagType: key,
          tagValue: val,
        }));
        return {
          metric: metric,
          tagName: getSingleTagValue(row),
          tags: tagsObject,
        };
      }),
      isChild: true,
    })),
    parentMetric: parentMetric,
  };
};

export const getNestedRows = (
  metric: AssessableMetrics[number],
  materialStandardId: string,
  parentMetric?: AssessableMetrics[number],
  tagFromParent = false,
  withSpecialTag?: any
): MetricsTableData => {
  const tags =
    (metric?.materialMetrics?.length ?? 0) > 0
      ? metric?.materialMetrics.find((mm) => mm.materialStandardId === materialStandardId)
          ?.materialMetricTags
      : metric?.materialMetrics?.flatMap((mm) => mm.materialMetricTags);
  if (!!tags?.length && !tagFromParent) {
    return getNestedTags(metric, tags, materialStandardId, parentMetric);
  } else if (metric?.childrenMetrics?.length) {
    return {
      metric: metric,
      subRows: (
        metric?.childrenMetrics?.filter((child) => !!child.childMetric) as Array<{
          childMetric: AssessableMetrics[number];
        }>
      ).map((child) => getNestedRows(child.childMetric, materialStandardId, metric, tagFromParent)),
      isChild: (metric.parentMetrics?.length ?? 0) > 0,
      parentMetric: parentMetric,
    };
  } else {
    return {
      metric: metric,
      isChild: (metric?.parentMetrics?.length ?? 0) > 0,
      parentMetric: parentMetric,
      ...(withSpecialTag ? withSpecialTag : {}),
    };
  }
};

export const getMetricWithChildren = (metric: MetricsTableData) => {
  const childrenMetricRows: MetricsTableData[] = [metric];

  const recurseChildren = (row: MetricsTableData) => {
    if (!row.tagType && !row.tags?.length) {
      row.subRows?.forEach((subRow) => {
        childrenMetricRows.push(subRow);
        recurseChildren(subRow);
      });
    }
  };

  if (metric.subRows?.length) {
    recurseChildren(metric);
  }
  const filteredMetrics =
    (childrenMetricRows.filter((m) => m !== undefined) as MetricsTableData[]) ?? [];
  return filteredMetrics;
};

export const getFlattenedMetricRows = (metric: MetricsTableData) => {
  const flattenedRows: MetricsTableData[] = [metric];

  const recurseRows = (subRows: MetricsTableData[]) => {
    if (!!subRows.length) {
      subRows.forEach((subRow) => {
        flattenedRows.push(subRow);
        recurseRows(subRow.subRows ?? []);
      });
    }
  };

  if (metric?.subRows?.length) {
    recurseRows(metric?.subRows);
  }

  return flattenedRows;
};
