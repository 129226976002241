import { useTranslation } from 'utils/translation';
import { ContentHeader, ContentLayout, SearchInput, Select } from 'Molecules';
import {
  Box,
  Button,
  HStack,
  Popover,
  PopoverAnchor,
  PopoverArrow,
  PopoverCloseButton,
  PopoverContent,
  useMediaQuery,
  VStack,
} from '@chakra-ui/react';
import { useEffect, useMemo, useState } from 'react';
import { Portfolio, PortfolioFieldsFragment_ } from 'models';
import usePortfolios, { useUpdateDefaultReportingPeriod } from './Portfolios.hooks';
import { PortfoliosList, PortfolioSummary } from './pieces/PortfoliosList';
import { Route, Routes } from 'react-router-dom';
import { PortfolioView, useCurrentReportPeriod } from 'Features/PortfolioView';
import { PortfolioEditorModal } from './pieces/PortfolioEditor';
import { NothingFoundIllustration, PortfolioIllustration, Typography } from 'Tokens';
import { formatDigits } from 'utils/numbers';
import { useCurrentCompany } from 'utils/hooks';
import { EmptyState } from 'Atoms';
import { ErrorElement } from 'App';
import { RefreshIcon } from 'Tokens/Icons/Function';
import PortfolioSettings from './PortfolioSettings';
import { useUserSetting } from 'containers/Navigation/Navigation.hooks';
import { useIsAuditor } from 'containers/CompanyPai/CompanyPai.hooks';

const SUMMARY_HEIGHT_MAP = {
  max: '246px',
  large: '230px',
  medium: '198px',
  small: '182px',
  default: '172px',
};

export const PortfoliosPage = () => {
  const { t } = useTranslation(['portfolio', 'common']);
  const { portfolios, loading } = usePortfolios();
  const [isCreatingPortfolio, setIsCreatingPortfolio] = useState(false);
  const [portfolioToEdit, setPortfolioToEdit] = useState<Portfolio | undefined>(undefined);
  const [searchValue, setSearchValue] = useState<string>('');
  const { company: currentCompany } = useCurrentCompany();
  const { reportPeriod } = useCurrentReportPeriod();
  const [isFundsPeriodSelectorPopupDismissed, setIsFundsPeriodSelectorPopupDismissed] =
    useUserSetting('funds-period-selector-popup-dismissed', false);
  const { defaultReportingPeriod, updateDefaultReportingPeriod } = useUpdateDefaultReportingPeriod(
    currentCompany?.id
  );
  const { isAuditor } = useIsAuditor(currentCompany?.id);

  const allYears = useMemo(() => {
    const tempAllYears: Array<number> = [
      ...new Set(portfolios.flatMap((ps) => ps.portfolioCompanies).map((c) => c?.year)),
    ]
      .filter((year): year is number => year !== undefined && year !== null)
      .sort((a, b) => (a ?? 0) - (b ?? 0));

    return tempAllYears;
  }, [portfolios]);

  const latestPortfolioYear = useMemo(() => allYears[allYears.length - 1], [allYears]);
  const onlyOneReportingYear = useMemo(() => allYears.length === 1, [allYears]);

  const [selectedPortfolioYear, setSelectedPortfolioYear] = useState<number>(
    new Date().getFullYear()
  );

  const closeFundsPeriodSelectorPopup = () => {
    setIsFundsPeriodSelectorPopupDismissed(true);
  };

  useEffect(() => {
    if (onlyOneReportingYear) {
      setSelectedPortfolioYear(latestPortfolioYear);
    } else if (defaultReportingPeriod || latestPortfolioYear) {
      setSelectedPortfolioYear(defaultReportingPeriod ?? latestPortfolioYear);
    }
  }, [latestPortfolioYear, defaultReportingPeriod, onlyOneReportingYear]);

  const filteredPortfolios = useMemo(() => {
    if (!searchValue) return portfolios;
    return portfolios.filter((portfolio) =>
      portfolio.name.toLowerCase().includes(searchValue.toLowerCase())
    );
  }, [searchValue, portfolios]);

  const portfolioCompanies = useMemo(() => {
    return portfolios.flatMap((object) => object.portfolioCompanies);
  }, [portfolios]);

  const portfolioCompaniesForSelectedYear: PortfolioFieldsFragment_['portfolioCompanies'] =
    useMemo(() => {
      return portfolioCompanies.filter((c) => c?.year === selectedPortfolioYear);
    }, [selectedPortfolioYear, portfolioCompanies]);

  const allocation = useMemo(() => {
    return portfolioCompaniesForSelectedYear
      ?.map((pc) => pc.valueOfInvestments)
      .reduce((acc, curr) => {
        const quartersAverage = (curr.q1 + curr.q2 + curr.q3 + curr.q4) / 4;
        return acc + quartersAverage;
      }, 0);
  }, [portfolioCompaniesForSelectedYear]);

  const [isMax, isLarge, isMedium, isSmall] = useMediaQuery([
    '(width <= 1462px)',
    '(width <= 1452px)',
    '(width <= 1215px)',
    '(width <= 1132px)',
  ]);

  const summaryHeight = useMemo(() => {
    if (isSmall) {
      return SUMMARY_HEIGHT_MAP.max;
    }
    if (isMedium) return SUMMARY_HEIGHT_MAP.large;
    if (isLarge) {
      return SUMMARY_HEIGHT_MAP.medium;
    }
    if (isMax) {
      return SUMMARY_HEIGHT_MAP.small;
    }
    return SUMMARY_HEIGHT_MAP.default;
  }, [isMax, isLarge, isMedium, isSmall]);

  return (
    <ContentLayout
      isLoading={loading}
      header={
        <ContentHeader
          title={t('portfolio:portfolio_plural')}
          actions={
            allYears.length > 1 ? (
              <Popover
                placement="bottom-end"
                isOpen={!isFundsPeriodSelectorPopupDismissed}
                onClose={closeFundsPeriodSelectorPopup}
                closeOnBlur={false}
                isLazy={true}
              >
                <Box>
                  <PopoverAnchor>
                    <HStack spacing="8px" justifyContent="flex-end" zIndex={2} w="275px">
                      <Typography variant="bodyStrong">
                        {t('portfolio:reportingPeriod')}:
                      </Typography>
                      <Select<{ value: number; label: number }>
                        width="90px"
                        value={{ value: selectedPortfolioYear, label: selectedPortfolioYear }}
                        onChange={(value) => {
                          if (value?.value) {
                            updateDefaultReportingPeriod(value.value);
                            setSelectedPortfolioYear(value.value);
                          }
                        }}
                        onFocus={closeFundsPeriodSelectorPopup}
                        options={allYears.map((year) => ({ value: year, label: year }))}
                      />
                    </HStack>
                  </PopoverAnchor>
                </Box>
                <Box zIndex={1}>
                  <PopoverContent
                    bg="bg.accent"
                    border="none"
                    w="275px"
                    sx={{
                      '.chakra-popover__arrow-positioner': {
                        transform: 'translate3d(226px, 0px, 0px)!important',
                      },
                    }}
                  >
                    <PopoverArrow bg="bg.accent" border="none" />
                    <PopoverCloseButton color="white" />
                    <VStack w="100%" alignContent="start" padding="16px" spacing="4px" zIndex={1}>
                      <Typography variant="h2" color="white" w="100%">
                        {t('portfolio:yearSelectorPopup.title')}
                      </Typography>
                      <Typography variant="body" color="white">
                        {t('portfolio:yearSelectorPopup.body')}
                      </Typography>
                    </VStack>
                  </PopoverContent>
                </Box>
              </Popover>
            ) : undefined
          }
        />
      }
    >
      {filteredPortfolios.length || searchValue ? (
        <>
          <HStack justifyContent="space-evenly" spacing="12px" w="100%">
            <VStack
              width="100%"
              alignItems="stretch"
              justifyContent="flex-start"
              bg="bg.muted"
              borderRadius="8px"
              flex="1"
              height={summaryHeight}
              minHeight="174px"
            >
              <HStack pt="16px" pl="16px" width="100%">
                <Typography variant="h3">{t('portfolio:overview')}</Typography>
              </HStack>
              <VStack px="16px" mt="12px" alignItems="flex-start">
                <HStack mb="20px" spacing="85px">
                  <VStack alignItems="flex-start" spacing="0px">
                    <Typography variant="bodyLarge">{portfolios.length}</Typography>
                    <Typography variant="detail">{t('portfolio:portfolio_plural')}</Typography>
                  </VStack>
                  <VStack alignItems="flex-start" spacing="0px">
                    <Typography variant="bodyLarge">
                      {portfolioCompaniesForSelectedYear.length}
                    </Typography>
                    <Typography variant="detail">{t('portfolio:companies')}</Typography>
                  </VStack>
                </HStack>
                <VStack alignItems="flex-start" spacing="0px">
                  <Typography variant="bodyLarge">{formatDigits(allocation)}</Typography>
                  <Typography variant="detail">
                    {currentCompany?.currency} {t('portfolio:invested')}
                  </Typography>
                </VStack>
              </VStack>
            </VStack>
            <Box flex="3" height={summaryHeight}>
              <PortfolioSummary
                portfolioCompanies={portfolioCompaniesForSelectedYear}
                reportPeriod={reportPeriod}
              />
            </Box>
          </HStack>

          <HStack mt="40px" justifyContent="space-between">
            <SearchInput
              placeholder={t('common:words.filter')}
              search={searchValue}
              setSearch={setSearchValue}
              minW="200px"
            />
            <Button
              variant="primary"
              onClick={() => setIsCreatingPortfolio(true)}
              isDisabled={isAuditor}
            >
              {t('portfolio:createButton')}
            </Button>
          </HStack>

          {filteredPortfolios.length ? (
            <PortfoliosList
              portfolios={filteredPortfolios}
              onEdit={(p) => setPortfolioToEdit(p)}
              year={selectedPortfolioYear}
              isAuditor={isAuditor}
            />
          ) : (
            <Box w="100%" flexGrow="1" alignItems="stretch" mt="16px">
              <EmptyState
                title={t('common:search.filter.emptyTitle')}
                description={t('common:search.filter.emptyDescription')}
                callToAction={{
                  text: t('common:search.filter.emptyBtn'),
                  variant: 'secondary',
                  onClick: () => {
                    setSearchValue('');
                  },
                  leftIcon: <RefreshIcon color="inherit" />,
                }}
                icon={<NothingFoundIllustration boxSize="120px" />}
              />
            </Box>
          )}
        </>
      ) : (
        <Box w="100%" flexGrow="1">
          <EmptyState
            title={t('portfolio:noPortfolio')}
            description={t('portfolio:noPortfolioDescription')}
            callToAction={{
              text: t('portfolio:createButton'),
              variant: 'primary',
              onClick: () => setIsCreatingPortfolio(true),
              isDisabled: isAuditor,
            }}
            icon={<PortfolioIllustration boxSize="120px" />}
          />
        </Box>
      )}
      <PortfolioEditorModal
        isOpen={isCreatingPortfolio || !!portfolioToEdit}
        onClose={() => {
          setIsCreatingPortfolio(false);
          setPortfolioToEdit(undefined);
        }}
        portfolio={portfolioToEdit}
      />
    </ContentLayout>
  );
};

export const Portfolios = () => {
  return (
    <Routes>
      <Route index element={<PortfoliosPage />} errorElement={<ErrorElement />} />
      <Route
        path=":portfolioId/:reportingYear/create"
        element={<PortfolioSettings isEditing={false} />}
        errorElement={<ErrorElement />}
      />
      <Route
        path=":portfolioId/:reportingYear/edit"
        element={<PortfolioSettings isEditing={true} />}
        errorElement={<ErrorElement />}
      />
      <Route path=":portfolioId/*" element={<PortfolioView />} errorElement={<ErrorElement />}>
        <Route path=":reportingYear/*" element={<PortfolioView />} errorElement={<ErrorElement />}>
          <Route path=":currentTab" element={<PortfolioView />} errorElement={<ErrorElement />} />
        </Route>
      </Route>
    </Routes>
  );
};
