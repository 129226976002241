import { Box, Table as ChakraTable, HStack, IconButton, Thead, Tr, VStack } from '@chakra-ui/react';
import { ESRSNumberInput, Infobox, TruncatableText } from 'Atoms';
import { GetReportingUnitsQuery_, GetTargetQuery_, useDeleteBaselinesMutation } from 'models';
import { Modal } from 'Molecules';
import { Typography, colors } from 'Tokens';
import { KeyResultEnums, TargetFields, TargetFieldsEnum } from '../../Requirement';
import {
  getBaselineTotal,
  getSubsidiaryBaselineTotal,
  openSubsidiary,
  SubsidiariesTargetsType,
} from '../AddTarget/AddTargets.hooks';
import { Control, Controller, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { useEffect, useMemo, useRef } from 'react';
import { ArrowUpRightIcon } from 'Tokens/Icons/Direction';
import { DeleteIcon } from 'Tokens/Icons/Function';
import { getBaselineInfoBoxText } from './TextUtils';
import { BaselineCell } from './Milestones/MilestonesUtils';
import { TableTD, TableTH } from './AddTargetUtils';

const BaselineHeader = ({
  isGroup,
  year,
  unit,
}: {
  isGroup: boolean;
  year?: number;
  unit: string;
}) => {
  return (
    <Thead>
      <TableTH w={isGroup ? '52%' : '60%'}>Department</TableTH>
      <TableTH w="40%" unit={unit}>
        Baseline {year}
      </TableTH>
      {isGroup && <TableTH w="40px"></TableTH>}
    </Thead>
  );
};

const BaselineNumberInput = ({
  value,
  onChange,
}: {
  value: string | number | null;
  onChange: (val?: any) => void;
}) => {
  const hStackRef = useRef<HTMLDivElement>(null);
  const handleFocus = () => {
    if (hStackRef.current) {
      hStackRef.current.style.border = '2px solid';
      hStackRef.current.style.borderColor = colors['border.selected.accent'].default;
      hStackRef.current.style.borderRadius = '6px';
    }
  };

  const handleBlur = () => {
    if (hStackRef.current) {
      hStackRef.current.style.border = 'none';
    }
  };

  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const target = e.target as HTMLElement;
    if (hStackRef.current && target.tagName !== 'INPUT') {
      hStackRef.current.focus();
    }
  };
  return (
    <HStack
      w="100%"
      h="100%"
      tabIndex={0}
      ref={hStackRef}
      onClick={handleClick}
      _focus={{ border: '1px solid', borderColor: 'border.selected.accent' }}
    >
      <ESRSNumberInput
        value={Number(value)}
        onChange={onChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        height="100%"
        width="100%"
        isBorderless={true}
      />
    </HStack>
  );
};

const TopDownInputTable = ({
  watch,
  control,
  companyName,
  unit,
}: {
  watch: UseFormWatch<TargetFields>;
  control: Control<TargetFields, any>;
  companyName: string;
  unit: string;
}) => {
  return (
    <VStack gap="8px" alignItems="start">
      <Typography variant="h3">{companyName} baseline</Typography>
      <Box border="1px solid" borderColor="border.decorative" borderRadius="8px">
        <ChakraTable sx={{ tableLayout: 'fixed' }}>
          <BaselineHeader
            isGroup={false}
            year={new Date(watch(TargetFieldsEnum.baseYear) ?? '')?.getFullYear()}
            unit={unit}
          />
          <Tr border="none" height="48px">
            <TableTD hasBorder={false}>
              <TruncatableText text={companyName} variant="body" />
            </TableTD>
            <TableTD hasBorder={false} height="48px">
              <Controller
                name={KeyResultEnums.baseline}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <BaselineNumberInput
                    value={Number.isNaN(value) ? '' : value}
                    onChange={onChange}
                  />
                )}
              />
            </TableTD>
          </Tr>
        </ChakraTable>
      </Box>
    </VStack>
  );
};

export const BaseLineModal = ({
  isOpen,
  onClose,
  isGroup,
  subsidiariesTargets,
  reportingUnits,
  control,
  unit,
  companyName,
  watch,
  setValue,
  parentTargetId,
  targetData,
  localAnswers,
  isFlagged,
}: {
  isOpen: boolean;
  onClose: () => void;
  isGroup: boolean;
  subsidiariesTargets: SubsidiariesTargetsType;
  reportingUnits?: GetReportingUnitsQuery_['reportingUnits'];
  control: Control<TargetFields, any>;
  unit: string;
  companyName: string;
  watch: UseFormWatch<TargetFields>;
  setValue: UseFormSetValue<TargetFields>;
  parentTargetId?: string | null;
  targetData: GetTargetQuery_['target'];
  localAnswers: TargetFields;
  isFlagged: boolean;
}) => {
  const [deleteBaselines] = useDeleteBaselinesMutation();
  const isBottomUp = watch('isBottomUp');
  const hideDeleteButton = !!parentTargetId;

  const total = useMemo(
    () =>
      isGroup
        ? getSubsidiaryBaselineTotal(subsidiariesTargets ?? [])
        : getBaselineTotal(reportingUnits ?? [], localAnswers),
    [subsidiariesTargets, reportingUnits, isGroup, localAnswers]
  );

  useEffect(() => {
    if (isBottomUp) {
      setValue(TargetFieldsEnum.baseline, total ?? '');
    }
  }, [total, isBottomUp]);

  const allSubsAnswered = useMemo(() => {
    const subBaselineValues = subsidiariesTargets?.map(
      (subsidiary) =>
        subsidiary.target?.keyResults.find((kr) => kr.reportingUnitId === null)?.baseline
    );
    const existingValues = subBaselineValues?.filter((b) => !!b);
    return isGroup && subsidiariesTargets?.length === existingValues?.length;
  }, [subsidiariesTargets, isGroup]);

  const handleDeleteBaseline = () => {
    const baselineIds = targetData?.keyResults.map((kr) => kr.id);
    const baselineKeys = Object.keys(localAnswers).filter((key) => key.includes('baseline'));

    if (baselineIds?.length) {
      deleteBaselines({
        variables: {
          baselineIds,
        },
      });
      onClose();
    }
    baselineKeys.map((key) => {
      setValue(key, 0, { shouldDirty: true });
    });
    setValue(TargetFieldsEnum.baseYear, null, { shouldDirty: true });
    onClose();
  };

  return (
    <Modal
      title="Add baseline"
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={() => {
        setValue(TargetFieldsEnum.isFlagged, false);
        onClose();
      }}
      topRightIcon={
        !hideDeleteButton ? (
          <IconButton
            aria-label="delete-baseline"
            variant="ghost"
            icon={<DeleteIcon />}
            onClick={handleDeleteBaseline}
          />
        ) : undefined
      }
    >
      <VStack spacing="16px" alignItems="start" w="100%">
        <Infobox
          status="neutral"
          withIcon={false}
          closable={false}
          description={getBaselineInfoBoxText({ isGroup, isBottomUp, companyName })}
        />
        {isFlagged && (
          <Infobox
            status="warning"
            withIcon={false}
            closable={false}
            description="The parent company updated this baseline’s year. Please update the baseline value and save your new answer."
          />
        )}
        {!isBottomUp && (
          <TopDownInputTable
            watch={watch}
            control={control}
            companyName={companyName}
            unit={unit}
          />
        )}
        {(isBottomUp || isGroup) && (
          <VStack alignItems="start" gap="8px">
            {isGroup && !isBottomUp ? (
              <VStack gap="2px" alignItems="start">
                <Typography variant="h3">Subsidiaries baselines</Typography>
                <Typography variant="body">
                  Here you can see baselines aggregated from subsidiaries, and if their total match
                  your baseline. These values will not show up in your report — you can just use
                  them as a reference point.
                </Typography>
              </VStack>
            ) : (
              <Typography variant="bodyStrong">Baseline</Typography>
            )}
            <Box border="1px solid" borderColor="border.decorative" borderRadius="8px">
              <ChakraTable sx={{ tableLayout: 'fixed' }}>
                <BaselineHeader isGroup={isGroup} unit={unit} />

                {isGroup
                  ? subsidiariesTargets?.map((s) => {
                      const baseline = s.target?.keyResults.find(
                        (kr) => kr.reportingUnitId === null
                      );
                      return (
                        <Tr height="48px">
                          <TableTD>
                            <TruncatableText text={s.company.name} variant="body" />
                          </TableTD>
                          <TableTD key={`${KeyResultEnums.baseline}_${s.id}`}>
                            <BaselineCell
                              baselineValue={baseline?.baseline}
                              baselineYear={new Date(baseline?.baseYear ?? '').getFullYear()}
                              year={new Date(watch('baseYear') ?? '').getFullYear()}
                            />
                          </TableTD>
                          <TableTD>
                            {s.target && (
                              <IconButton
                                aria-label="open sub"
                                variant="ghost"
                                size="sm"
                                icon={<ArrowUpRightIcon />}
                                onClick={() => openSubsidiary(s)}
                              />
                            )}
                          </TableTD>
                        </Tr>
                      );
                    })
                  : reportingUnits?.map((ru) => (
                      <Tr height="48px">
                        <TableTD>
                          <TruncatableText text={ru.name ?? ''} variant="body" />
                        </TableTD>
                        <TableTD key={`${KeyResultEnums.baseline}${ru.id}`} height="48px">
                          <Controller
                            name={`${KeyResultEnums.baseline}_${ru.id}`}
                            control={control}
                            render={({ field: { onChange, value } }) => (
                              <BaselineNumberInput
                                value={Number.isNaN(value) ? '' : value}
                                onChange={onChange}
                              />
                            )}
                          />
                        </TableTD>
                      </Tr>
                    ))}

                <Tr bg="bg.muted" height="48px">
                  <TableTD borderBottom="none" borderBottomLeftRadius="8px">
                    <Typography variant="bodyStrong" as="span">
                      Total {isBottomUp ? '(your baseline)' : ''}
                    </Typography>
                  </TableTD>
                  <TableTD borderBottom="none" borderBottomRightRadius={isGroup ? '' : '8px'}>
                    <Typography
                      variant="bodyStrong"
                      pl={isBottomUp && !isGroup ? '8px' : ''}
                      color={total === 0 || (isGroup && !allSubsAnswered) ? 'text.hint' : ''}
                    >
                      {Number.isNaN(total) || total === 0 || (isGroup && !allSubsAnswered)
                        ? 'N/A'
                        : `${total}`}
                    </Typography>
                  </TableTD>
                  {isGroup && <TableTD borderBottom="none" borderBottomRightRadius="8px"></TableTD>}
                </Tr>
              </ChakraTable>
            </Box>
          </VStack>
        )}
      </VStack>
    </Modal>
  );
};
