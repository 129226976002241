import { useUserSetting } from 'containers/Navigation/Navigation.hooks';

export type BusinessUnitsSortingType = {
  sortAlphabetically: boolean;
  groupsFirst: boolean;
  setSortAlphabetically: (value: boolean) => void;
  setGroupsFirst: (value: boolean) => void;
  toggleSortingAlphabetically: () => void;
  toggleSortingGroupsFirst: () => void;
};

export const businessUnitsSorter = (a: string, b: string) => {
  return a.localeCompare(b);
};

export const useBusinessUnitsSorting = (): BusinessUnitsSortingType => {
  const [sortAlphabetically, setSortAlphabetically] = useUserSetting(
    'companyStructureSortAlphabetically',
    false
  );

  const [groupsFirst, setGroupsFirst] = useUserSetting('companyStructureGroupsFirst', false);

  const toggleSortingAlphabetically = () => {
    setSortAlphabetically(!sortAlphabetically);
  };

  const toggleSortingGroupsFirst = () => {
    setGroupsFirst(!groupsFirst);
  };

  return {
    sortAlphabetically,
    groupsFirst,
    setSortAlphabetically,
    setGroupsFirst,
    toggleSortingAlphabetically,
    toggleSortingGroupsFirst,
  };
};
