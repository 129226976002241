import { Packer } from 'docx';
import { saveAs } from 'file-saver';
import { DocumentCreator } from './report-generator';
import { CompanyAssessment, CompanyAssessmentResults, CompanyDetails } from 'models';
import { useTaxonomyResultsTableData } from 'Features/TaxonomyResultsTable/TaxonomyResultsTable.hooks';
import { useTranslation } from 'utils/translation';
import { DocumentFileIcon } from 'Tokens/Icons/FileIcons';
import { HStack, VStack } from '@chakra-ui/react';
import { Typography } from 'Tokens';
import { useMemo } from 'react';
import { stringToYear } from 'utils/date';
import { nhost } from 'utils/nhost';
import { captureException } from '@sentry/react';

export const DocsGenerator = ({
  company,
  cAssessmentResults,
  assessment,
}: {
  company?: CompanyDetails;
  cAssessmentResults?: CompanyAssessmentResults;
  assessment?: CompanyAssessment;
}) => {
  const { t } = useTranslation('common');
  const { data: tableData } = useTaxonomyResultsTableData(cAssessmentResults, []);

  const cachedResults = useMemo(() => cAssessmentResults?.cachedResult, [cAssessmentResults]);

  const generate = async () => {
    const documentCreator = new DocumentCreator();
    const doc = await documentCreator.create({
      t,
      period: `${stringToYear(assessment?.startDate)}`,
      company: {
        name: company?.name ?? '',
        score: cachedResults?.score,
        financials: cachedResults?.financials,
        currency: company?.currency,
        numberOfReportingUnits: assessment?.bAssessments?.length,
      },
      activities:
        tableData?.rows.map((activity) => ({
          rowKey: activity.rowKey,
          name: activity?.rowName ?? '',
          financials: activity.columnData.financials,
          alignment: activity.columnData.alignment,
          tag: activity.columnData.activityTagStatus,
        })) ?? [],
    });

    Packer.toBlob(doc).then((blob) => {
      saveAs(blob, `${company?.name}-eu-taxonomy-report.docx`);
    });

    const cAssessmentId = assessment?.id;
    const { res, error } = await nhost.functions.call<{ cAssessmentId: string }>(
      'log-report-downloads',
      {
        cAssessmentId: cAssessmentId,
      }
    );

    if (error) {
      captureException(error, {
        extra: { errorMessage: 'Error calling logReportDownloads' },
      });
      console.error('Error calling logReportDownloads:', error);
    } else {
      console.log('Log Downloads Result:', res);
    }
  };
  return (
    <HStack w="100%" spacing="10.33px" onClick={generate}>
      <DocumentFileIcon />
      <VStack alignItems="start" spacing="0px">
        <Typography variant="bodyStrong">{t('assessment:export.euReport.report')}</Typography>
        <Typography variant="detail">{t('assessment:export.euReport.doc')}</Typography>
      </VStack>
    </HStack>
  );
};
