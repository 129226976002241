import { useTranslation } from 'utils/translation';
import { AlertDialog, ContentLayout, SearchInput, Table } from 'Molecules';
import { Tag, Button, Checkbox, EmptyState, Infobox, TruncatableText } from 'Atoms';
import { EUTaxonomyIllustration, LabelText, NothingFoundIllustration, Typography } from 'Tokens';
import {
  useCompanyAssessmentsQuery,
  CompanyAssessmentDetails,
  useInvestorsQuery,
  InvestorsQuery_,
} from 'models';
import { useCurrentCompany, useCurrentCompanyId, useToast } from 'utils/hooks';
import { useMemo, useState } from 'react';
import { Box, HStack, useDisclosure } from '@chakra-ui/react';
import { useDeleteCompanyAssessment } from '../Assessments.hooks';
import { CompanyAvatarWithName, UserAvatar } from 'Organisms';
import { useNavigate } from 'react-router-dom';
import { TRACKING_EVENTS } from 'utils/mixpanel';
import mixpanel from 'mixpanel-browser';
import { noop, uniqBy } from 'lodash';
import { ColumnDef } from '@tanstack/react-table';
import { dateToYYYYMMDD, stringToYear } from 'utils/date';
import { DuplicateAssessmentModal } from './DuplicateAssessmentModal';
import { Menu } from 'Molecules/Menu';
import { CopyIcon, DeleteIcon, EditIcon, PlayIcon, RefreshIcon } from 'Tokens/Icons/Function';
import { AssessmentStatusTag } from 'Organisms/AssessmentStatusTag';
import { useKnowledgeBase } from 'Features';
import { useUserSetting } from 'containers/Navigation';
import { ShareButton } from 'Molecules/ShareButton';
import { ShareAssessmentModal } from './ShareAssessmentModal';
import { AccessStatusType } from './Assessment';
import { useShareCompanyAssessment, useUnshareCompanyAssessment } from 'containers/Investors';
import { ARTICLE_SIX_STEPS } from 'Features/KnowledgeBase/KnowledgeBaseArticles';
import { getLatestTaxonomyDate } from 'containers/Home/Home.hooks';

type AssessmentsTableProps = {
  withSelection?: {
    selected: string[];
    setSelected: (val: string[]) => void;
  };
  hideActions?: boolean;
  hideSharedWith?: boolean;
  assessments: CompanyAssessmentDetails[];
  openModal?: () => void;
  investors?: InvestorsQuery_['investors'];
  setCompanyAssessmentId?: (val: string) => void;
};

export const TAXONOMY_LEARN_MORE_MODAL = 'taxonomy_learn_more_modal_is_closed';

export const AssessmentsTable = ({
  assessments,
  hideActions = false,
  hideSharedWith = false,
  withSelection = undefined,
  openModal,
  investors,
  setCompanyAssessmentId,
}: AssessmentsTableProps) => {
  const { t } = useTranslation(['assessment', 'common']);
  const { company } = useCurrentCompany();
  const [toDelete, setToDelete] = useState<string>();
  const showSelectors = withSelection !== undefined;
  const deleteCompanyAssessment = useDeleteCompanyAssessment();
  const [assessmentToDuplicate, setAssessmentToDuplicate] = useState<{
    id: string;
    startDate: string;
  }>();

  const showSharedWithColumn = useMemo(
    () => hideSharedWith || !investors?.length,
    [hideSharedWith, investors]
  );

  const navigate = useNavigate();
  const toast = useToast();

  const columns = useMemo(() => {
    const { selected = [], setSelected } = withSelection ?? {};
    const checkboxColumn: ColumnDef<CompanyAssessmentDetails> = {
      header: '',
      id: 'checkbox',
      cell: ({ row }) => (
        <Checkbox
          isChecked={selected?.includes(row.original.id)}
          onChange={() => {
            if (selected?.includes(row.original.id)) {
              setSelected?.(selected?.filter((id) => id !== row.original.id));
            } else {
              setSelected?.([...(selected ?? []), row.original.id]);
            }
          }}
        />
      ),
    };
    const actionsColumn: ColumnDef<CompanyAssessmentDetails>[] = [
      {
        header: '',
        id: 'actions',
        meta: {
          width: '2%',
        },
        cell: ({ row }) => {
          return (
            <Menu
              key={row.id}
              sections={[
                {
                  actions: [
                    {
                      id: `edit-${row.id}`,
                      title: t('common:actions.edit'),
                      onClick: () => {
                        navigate(`${row.original.id}/edit`);
                      },
                      isDisabled: row.original.isLocked,
                      leftElement: <EditIcon color="inherit" />,
                    },
                    {
                      id: `duplicate-${row.id}`,
                      title: t('common:actions.duplicate'),
                      onClick: () =>
                        setAssessmentToDuplicate({
                          id: row.original.id,
                          startDate: row.original.startDate,
                        }),
                      leftElement: <CopyIcon color="inherit" />,
                    },
                  ],
                },
                {
                  actions: [
                    {
                      id: `delete-${row.id}`,
                      title: t('common:actions.delete'),
                      variant: 'destructive',
                      onClick: () => {
                        mixpanel.track(TRACKING_EVENTS.ASSESSMENTS.DELETE, {
                          companyId: company?.id,
                        });
                        setToDelete(row.original.id);
                      },
                      isDisabled: row.original.isLocked,
                      leftElement: <DeleteIcon color="inherit" />,
                    },
                  ],
                },
              ]}
            />
          );
        },
      },
    ];

    const nameAndPeriodColumns: ColumnDef<CompanyAssessmentDetails>[] = [
      {
        header: t('common:assessmentTable.name'),
        accessorKey: 'aggregateTitle',
        cell: ({ row }) => (
          <TruncatableText
            variant="bodyStrong"
            text={row.original.aggregate.title}
            color="text.default"
          />
        ),
      },
      {
        header: t('common:assessmentTable.fiscalPeriod'),
        id: 'fiscalPeriod',
        cell: ({ row }) => <Tag size="xs">{stringToYear(row.original.startDate)}</Tag>,
      },
    ];

    const statusColumn: ColumnDef<CompanyAssessmentDetails> = {
      header: t('common:assessmentTable.status'),
      id: 'status',
      cell: ({ row }) => <AssessmentStatusTag isLocked={row.original.isLocked} />,
    };

    const stakeHoldersAccessColumn: ColumnDef<CompanyAssessmentDetails> = {
      header: t('common:assessmentTable.stakeholders'),
      id: 'status',
      cell: ({ row }) => {
        const companiesSharedWith = uniqBy(
          investors
            ?.filter((c) => c.sharedAssessment?.id === row.original.id)
            .map((investor) => investor?.portfolio?.ownerCompany),
          'name'
        );

        return (
          <ShareButton
            isShared={!!row.original.portfoliosWithAccess.length}
            companiesSharedWith={companiesSharedWith}
            onClick={() => {
              if (openModal) openModal();
              if (setCompanyAssessmentId) setCompanyAssessmentId(row.original.id);
            }}
            size="sm"
          />
        );
      },
    };

    const contactColumn: ColumnDef<CompanyAssessmentDetails>[] = [
      {
        header: t('common:assessmentTable.assignedTo'),
        id: 'contactPerson',
        cell: ({ row }) => {
          const { contactPerson } = row.original.aggregate;
          if (contactPerson)
            return (
              <HStack>
                <UserAvatar user={contactPerson} size="sm" />
                <LabelText fontSize="md">{contactPerson.displayName}</LabelText>
              </HStack>
            );
          return <CompanyAvatarWithName company={company} />;
        },
      },
    ];

    const lastEditedColumn: ColumnDef<CompanyAssessmentDetails> = {
      header: t('common:assessmentTable.lastEdited'),
      id: 'lastEdited',
      cell: ({ row }) => {
        const date = getLatestTaxonomyDate(row.original);
        const newDate = dateToYYYYMMDD(new Date(date));
        return <Typography>{newDate}</Typography>;
      },
    };

    return [
      ...(showSelectors ? [checkboxColumn] : []),
      ...nameAndPeriodColumns,
      statusColumn,
      ...(showSharedWithColumn ? [] : [stakeHoldersAccessColumn]),
      ...contactColumn,
      lastEditedColumn,
      ...(hideActions ? [] : actionsColumn),
    ];
  }, [showSelectors, hideActions, withSelection, investors, hideSharedWith]);
  return (
    <>
      <Table<CompanyAssessmentDetails>
        data={assessments}
        columns={columns}
        onRowClick={(original: any) =>
          withSelection ? noop() : navigate(`${(original as CompanyAssessmentDetails).id}`)
        }
        rowProps={{
          _hover: {
            cursor: 'pointer',
            bg: 'bg.hover',
          },
        }}
        cellProps={{
          paddingTop: '0',
          paddingBottom: '0',
        }}
      />
      <AlertDialog
        isOpen={!!toDelete}
        onClose={() => setToDelete(undefined)}
        onConfirm={() => {
          if (toDelete)
            deleteCompanyAssessment(
              toDelete,
              assessments.find((a) => a.id === toDelete)?.aggregate.id
            ).then(() => {
              toast({
                text: t('assessment:list.delete.success'),
              });
              setToDelete(undefined);
            });
        }}
        title={t('assessment:list.title')}
        confirmLabel={t('assessment:list.confirm')}
      >
        <Typography variant="body">{t('assessment:list.alert')}</Typography>
      </AlertDialog>
      {assessmentToDuplicate && (
        <DuplicateAssessmentModal
          isOpen={!!assessmentToDuplicate}
          onClose={() => setAssessmentToDuplicate(undefined)}
          id={assessmentToDuplicate.id}
          startDate={assessmentToDuplicate.startDate}
        />
      )}
    </>
  );
};

export const AssessmentsEmptyState = () => {
  const { t } = useTranslation(['assessment', 'common']);

  const navigate = useNavigate();

  return (
    <Box w="100%" flexGrow="1">
      <EmptyState
        title={t('common:report.noReport')}
        description={t('common:report.noReportDescription')}
        callToAction={{
          text: t('common:assessment.createAssessment'),
          variant: 'primary',
          onClick: () => navigate('new'),
        }}
        icon={<EUTaxonomyIllustration boxSize="120px" color="border.default" />}
      />
    </Box>
  );
};

export const AssessmentsSearchEmptyState = ({ setSearch }: { setSearch: (s: string) => void }) => {
  const { t } = useTranslation('common');
  return (
    <Box w="100%" flexGrow="1">
      <EmptyState
        title={t('common:search.filter.emptyTitle')}
        description={t('common:search.filter.emptyDescription')}
        icon={<NothingFoundIllustration boxSize="120px" />}
        callToAction={{
          text: t('common:search.filter.emptyBtn'),
          variant: 'secondary',
          onClick: () => {
            setSearch('');
          },
          leftIcon: <RefreshIcon color="inherit" />,
        }}
      />
    </Box>
  );
};

export const AssessmentsList = () => {
  const { companyId } = useCurrentCompanyId();
  const [cAssessmentId, setCompanyAssessmentId] = useState('');
  const [search, setSearch] = useState('');
  const navigate = useNavigate();
  const { t } = useTranslation(['common', 'home']);
  const { onOpen } = useKnowledgeBase();
  const [isTaxonomyLearnMoreClosed, onToggleTaxonomyModal] = useUserSetting(
    TAXONOMY_LEARN_MORE_MODAL,
    false
  );
  const { data, loading } = useCompanyAssessmentsQuery({
    variables: {
      companyId,
    },
    skip: !companyId,
  });
  const assessments = useMemo(() => {
    return data?.assessments ?? [];
  }, [data]);

  const { data: investorsData } = useInvestorsQuery({
    variables: {
      companyId: companyId,
    },
    skip: !companyId,
  });

  const investors = useMemo(() => {
    return investorsData?.investors ?? [];
  }, [investorsData]);

  const {
    isOpen: isOpenShareModal,
    onClose: onCloseShareModal,
    onOpen: onOpenShareModal,
  } = useDisclosure();

  const { shareCompanyAssessment } = useShareCompanyAssessment();
  const { unshareCompanyAssessment } = useUnshareCompanyAssessment();
  const handleSaveChanges = async (updatedList: AccessStatusType[]) => {
    const changed = updatedList.filter(
      ({ portfolioCompanyId, portfolioId, assessmentId }) =>
        !investors.find(
          (investor) =>
            investor.portfolioId === portfolioId &&
            investor.id === portfolioCompanyId &&
            investor.sharedCompanyAssessmentId === assessmentId
        )
    );

    const added = changed.filter(({ assessmentId }) => assessmentId !== null);
    const removed = changed.filter(({ assessmentId }) => assessmentId === null);

    await Promise.all([
      ...added.map(({ portfolioCompanyId, portfolioId, assessmentId }) =>
        shareCompanyAssessment(assessmentId ?? '', portfolioCompanyId, portfolioId)
      ),
      ...removed.map(({ portfolioCompanyId, portfolioId }) =>
        unshareCompanyAssessment(portfolioCompanyId, portfolioId)
      ),
    ]).then(onCloseShareModal);
  };

  const filteredAssessmets = useMemo(() => {
    if (!search) return assessments;
    return assessments.filter((assessment) =>
      assessment.aggregate.title.toLowerCase().includes(search.toLowerCase())
    );
  }, [search, assessments]);

  return (
    <ContentLayout isLoading={loading} header={t('common:menu.assessments')}>
      {!isTaxonomyLearnMoreClosed && (
        <Infobox
          status="neutral"
          withIcon={false}
          title={t('home:emptyState.taxonomy.subTitle')}
          description={t('home:taxonomy.description')}
          onClose={() => onToggleTaxonomyModal(true)}
          extra={
            <Button
              ml="-8px"
              variant="ghost"
              onClick={() => onOpen(ARTICLE_SIX_STEPS.slug)}
              size="sm"
              color="text.selected"
              leftIcon={<PlayIcon color="inherit" />}
            >
              {t('home:emptyState.taxonomy.infoButton')}
            </Button>
          }
        />
      )}
      {assessments.length ? (
        <>
          <HStack width="100%" justifyContent="space-between" paddingY="16px">
            <SearchInput
              placeholder={t('common:words.filter')}
              search={search}
              setSearch={setSearch}
            />
            <Button
              onClick={() => navigate('new')}
              minWidth="none"
              paddingX="16px"
              variant="primary"
              size="md"
            >
              {t('common:assessment.createAssessment')}
            </Button>
          </HStack>
          {filteredAssessmets.length ? (
            <AssessmentsTable
              assessments={filteredAssessmets}
              openModal={onOpenShareModal}
              investors={investors}
              setCompanyAssessmentId={setCompanyAssessmentId}
            />
          ) : (
            <AssessmentsSearchEmptyState setSearch={setSearch} />
          )}
        </>
      ) : (
        <AssessmentsEmptyState />
      )}
      <ShareAssessmentModal
        key="share-assessment-modal"
        isOpen={isOpenShareModal}
        onClose={onCloseShareModal}
        investors={investors}
        cAssessmentId={cAssessmentId}
        handleSaveChanges={handleSaveChanges}
      />
    </ContentLayout>
  );
};
