import { Button, Infobox, Link, Tooltip } from 'Atoms';
import { AggregatedQualitativeAnswers } from '../../AggregatedMetrics/AggregatedMetrics.hooks';
import { AnswerGenerationDetails, MetricsTableData, TimePeriodsEnums } from '../../..';
import { useMemo, useState } from 'react';
import { summarizeNarrativeAnswers } from '../../MetricAI/AIRequestFunctions';
import { useCurrentCompany, useToast } from 'utils/hooks';
import { Typography } from 'Tokens';
import { VStack, useToast as useChakraToast } from '@chakra-ui/react';
import { AIIcon } from 'Tokens/Icons/Function';
import { useTranslation } from 'utils/translation';
import { usePopulateMetricSummary } from './AnswersSummarizer.hook';
import { useFeatureFlags } from 'containers/Navigation';

const cleanUpHTMLText = (text: string) => {
  return (
    text
      .replace(/(<([^>]+)>)/gi, ' ')
      .replace(/\n/g, ' ')
      .trim() ?? ''
  );
};

export const AnswersSummarizer = ({
  metric,
  answersData,
  companyReportingUnit,
  setAnswerGenerationDetails,
}: {
  metric: MetricsTableData['metric'];
  answersData: AggregatedQualitativeAnswers[number];
  companyReportingUnit?: string;
  setAnswerGenerationDetails?: React.Dispatch<React.SetStateAction<AnswerGenerationDetails>>;
}) => {
  const { t } = useTranslation('ai');

  const company = useCurrentCompany();
  const { hasAiAccess } = useFeatureFlags(company);

  const [isGenerating, setIsGenerating] = useState<boolean>(false);

  const toast = useToast();
  const chakraToast = useChakraToast();

  const metricAnswers = useMemo(() => {
    return {
      metricRef: answersData.metricRef,
      metricTitle: metric?.title,
      metricDescription: cleanUpHTMLText(metric?.description ?? ''),
      subsidiaries: answersData.subsidiaries?.map((s) => s.subsidiary.company.name),
      answers: answersData.subsidiaries
        ?.map(
          (sub) =>
            sub.answer?.datapoints.find((dp) => dp.timeframe === TimePeriodsEnums.year)?.value
        )
        .filter((a) => !!a),
    };
  }, [answersData, metric]);

  const datapoint = useMemo(() => {
    return answersData.answer?.datapoints.find((dp) => dp.timeframe === TimePeriodsEnums.year);
  }, [answersData]);

  const { populateAnswer } = usePopulateMetricSummary({ answersData });

  const generateAggregatedNarrativeAnswers = async () => {
    const answersString = metricAnswers.answers?.join(' - ') ?? '';
    const subsidiariesString = metricAnswers.subsidiaries?.map((s) => `"${s}"`).join(', ') ?? '';

    if (!answersString) {
      throw new Error(t('esrs.summary.noSubsidiaryAnswers'));
    }

    const timedToastId = toast({
      text: t('esrs.summary.writingSummary'),
      closable: true,
      duration: null,
    });

    try {
      setAnswerGenerationDetails?.({
        isGenerating: true,
        metricRef: metricAnswers.metricRef ?? '',
      });
      setIsGenerating(true);

      const summarizedAnswer = await summarizeNarrativeAnswers(
        metricAnswers.metricTitle,
        metricAnswers.metricDescription ?? '',
        answersString,
        subsidiariesString
      );

      const aggregatedAnswerData = summarizedAnswer.data.summary;

      setAnswerGenerationDetails?.({
        isGenerating: false,
        metricRef: metricAnswers.metricRef ?? '',
      });
      setIsGenerating(false);
      chakraToast.close(timedToastId);

      return aggregatedAnswerData;
    } catch (error) {
      setAnswerGenerationDetails?.({
        isGenerating: false,
        metricRef: metricAnswers.metricRef ?? '',
      });
      setIsGenerating(false);

      chakraToast.close(timedToastId);
      toast({
        variant: 'danger',
        text: t('esrs.errors.summary'),
      });
      console.log(error);
    }
  };

  const handleSummarize = async () => {
    try {
      const generatedSummary = await generateAggregatedNarrativeAnswers();
      populateAnswer(generatedSummary, companyReportingUnit ?? '');
    } catch (error) {
      toast({
        text: t('esrs.summary.noSubsidiaryAnswers'),
      });
    }
  };

  return (
    <Infobox
      status="info"
      closable={false}
      withIcon={false}
      title="Summarize answers from subsidiaries"
      description={
        <VStack spacing="20px" alignItems="start">
          <Typography variant="body">{t('esrs.summary.subsidiaryInfoboxTitle')}</Typography>

          {datapoint?.isAIGenerated ? (
            <Typography>
              {t('esrs.answers.generated')}{' '}
              <Link color="text.action" to="#" onClick={handleSummarize}>
                {t('esrs.errors.tryAgain')}
              </Link>
              .
            </Typography>
          ) : (
            <Tooltip label={t('common:aiConsent')} isDisabled={hasAiAccess}>
              <Button
                isLoading={isGenerating}
                leftIcon={<AIIcon color="inherit" />}
                onClick={handleSummarize}
                isDisabled={!hasAiAccess}
              >
                {t('summarize')}
              </Button>
            </Tooltip>
          )}
        </VStack>
      }
    ></Infobox>
  );
};
