import {
  useUpdateCompanyMutation,
  CompanyDetails,
  useInvestorsQuery,
  usePaiReportRequestsQuery,
  useUpdateUserProfileMutation,
  useUserMetadataQuery,
} from 'models';
import { useUserData, useSignOut } from '@nhost/react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLatest } from 'react-use';
import { useDisclosure } from '@chakra-ui/react';
import { useTranslation } from 'utils/translation';
import { LanguageEditor } from 'Organisms/LanguageEditor';
import { nhost } from 'utils/nhost';
import { EligibilityStatus } from 'Features/PortfolioView';
import { WorldIcon } from 'Tokens/Icons/Data';
import { SignOutIcon } from 'Tokens/Icons/Function';
import { isCelsiaSupport } from 'utils/users';
import { useApolloClient } from '@apollo/client';
import { captureException } from '@sentry/react';

export function useUserSetting<T>(
  name: string,
  def: T
): [T, (value: T) => void, { loading: boolean }] {
  const currentUser = useUserData();
  const { data: userData, loading } = useUserMetadataQuery({
    variables: { userId: currentUser?.id },
    skip: !currentUser?.id,
  });
  const userMetadata = useMemo(() => ({ ...userData?.user?.metadata }), [userData]);

  const [updateUser] = useUpdateUserProfileMutation();

  const userRef = useLatest(currentUser);
  const [optimisticValue, setOptimisticValue] = useState<T>(
    (currentUser?.metadata?.settings as any)?.[name] as T
  );

  useEffect(() => {
    if (currentUser && !loading) setOptimisticValue((userMetadata?.settings as any)?.[name] ?? def);
  }, [userMetadata]);

  useEffect(() => {
    if (!currentUser?.metadata?.settings) {
      if (currentUser?.metadata) {
        Object.assign(currentUser.metadata, { settings: {} });
      } else if (currentUser) {
        currentUser.metadata = { settings: {} };
      } else {
        captureException(new Error('No user found'));
      }
    }
    (currentUser?.metadata?.settings as any)[name] = optimisticValue ?? def;
  }, [optimisticValue]);

  const updateSetting = useCallback(
    (value: T) => {
      setOptimisticValue(value);
      const user = userRef.current;
      return (
        user &&
        updateUser({
          variables: {
            id: user.id,
            user: {
              metadata: {
                ...(user.metadata ?? {}),
                settings: {
                  ...((user?.metadata?.settings as object) ?? {}),
                  [name]: value,
                },
              },
            },
          },
          optimisticResponse: {
            user: {
              ...user,
              metadata: {
                ...(user.metadata ?? {}),
                settings: {
                  ...((user?.metadata?.settings as object) ?? {}),
                  [name]: value,
                },
              },
            },
          },
        })
      );
    },
    [name]
  );
  return [optimisticValue ?? def, updateSetting, { loading: !currentUser || loading }];
}

export function useChangeLanguage() {
  const { i18n } = useTranslation();
  const changeLanguage = useCallback((lang: string) => {
    i18n.changeLanguage(lang);
  }, []);
  return { changeLanguage, language: i18n.language };
}

export function useUserActions() {
  const { t } = useTranslation('common');
  const user = useUserData();
  const { signOut: nhostSignOut } = useSignOut();
  const { changeLanguage, language } = useChangeLanguage();
  const client = useApolloClient();

  const signOut = useCallback(() => {
    client.stop();
    nhostSignOut();
  }, [nhostSignOut]);

  useEffect(() => {
    if (user?.locale && language != user?.locale && language) {
      changeLanguage(user.locale);
    }
  }, [language, user?.locale]);
  const {
    isOpen: isLanguageEditorOpen,
    onOpen: onLanguageEditorOpen,
    onClose: onLanguageEditorClose,
  } = useDisclosure();
  return useMemo(
    () => ({
      userActions: [
        {
          id: 'language',
          title: t('common:actions.language'),
          leftElement: <WorldIcon color="inherit" />,
          onClick: onLanguageEditorOpen,
        },
        {
          id: 'logout',
          title: t('common:actions.logOut'),
          leftElement: <SignOutIcon color="inherit" />,
          onClick: signOut,
        },
      ],
      user,
      languageEditor: user && (
        <LanguageEditor isOpen={isLanguageEditorOpen} onClose={onLanguageEditorClose} user={user} />
      ),
    }),
    [onLanguageEditorOpen, signOut, user]
  );
}

export function useUpdateCompanySettings() {
  const [updateCompany] = useUpdateCompanyMutation();
  return useCallback(
    ({ id, settings }: { id: string; settings: any }) =>
      updateCompany({
        variables: {
          company: {
            id,
            settings,
          },
        },
      }),
    [updateCompany]
  );
}

export function useCreateInitialCompany() {
  return useCallback(
    async (
      userId: string,
      name = '',
      isPortfolioOwner = false,
      isGroupOwner = false,
      currency?: string
    ) => {
      try {
        const res = await nhost.functions.call('create-initial-company', {
          userId,
          name,
          isPortfolioOwner,
          isGroupOwner,
          currency,
        });
        const companyId = (res?.res?.data as { companyId?: string })?.companyId;
        return companyId;
      } catch (e) {
        throw e;
      }
    },
    []
  );
}

export const useFeatureFlags = ({
  company,
}: {
  company: CompanyDetails | undefined;
}): { [key: string]: boolean } => {
  const user = useUserData();
  const { data: investorData, refetch: refetchInvestors } = useInvestorsQuery({
    variables: {
      companyId: company?.id,
    },
    skip: !company?.id,
  });
  const companyInvestors = useMemo(() => {
    return investorData?.investors ?? [];
  }, [investorData]);

  const { data: paiRequestsData } = usePaiReportRequestsQuery({
    variables: {
      companyId: company?.id,
    },
    skip: !company?.id,
  });

  const pai = useMemo(() => {
    if (company?.hasPaiAccess && company?.isPortfolioOwner) return true;

    if (!!paiRequestsData?.paiReportRequests?.length) {
      return true;
      // if (companyInvestors.some((i) => i.portfolio.ownerCompany?.hasPaiAccess)) return true;
      // else return false;
    }
    return false;
  }, [company, paiRequestsData, companyInvestors]);

  const assessments = useMemo(() => {
    if (company?.hasTaxonomyAccess) {
      if (company?.isPortfolioOwner) return true;
      if (!companyInvestors.length) return true;
      if (
        !!companyInvestors.length &&
        companyInvestors.every((i) => i.eligibilityStatus === EligibilityStatus.notEligible)
      )
        return false;
      else return true;
    }
    return false;
  }, [company, companyInvestors]);

  const community = useMemo(
    () => !!company?.hasCommunityAccess || !!company?.additionalCommunitySpaces.length,
    [company]
  );

  const hasAiAccess = useMemo(
    () => !!company?.hasAiAccess || isCelsiaSupport(user?.email),
    [company, user]
  );

  const isPayingCustomer = useMemo(() => !!company?.isPayingCustomer, [company]);
  const esrs = useMemo(() => !!company?.hasESRSAccess || isCelsiaSupport(user?.email), [company]);

  const hasAuditLogsAccess = useMemo(() => isCelsiaSupport(user?.email), [user]);

  const fileUploadBlockList = [
    'e99ec3bb-6e67-4c92-9468-74f6ff7845ae',
    'a5124a38-ce46-4d9f-8f0c-2d48fd59e826',
    '572003e6-78ce-4cc4-a6db-0380a3fea881',
    'f42fbf51-9a8b-43b8-a97a-c49947855e79',
    'e346bf75-a7e2-491f-8b12-ba90728d8e92', // staging test company
  ];

  const drive = useMemo(() => !fileUploadBlockList.includes(company?.id), [company]);

  useEffect(() => {
    if (company) {
      refetchInvestors();
    }
  }, [company, refetchInvestors]);

  return {
    pai,
    assessments,
    community,
    drive,
    isPayingCustomer,
    esrs,
    hasAiAccess,
    hasAuditLogsAccess,
  };
};

const HIDE_MODULES_PATH_CONFIG: Record<string, { embedUrls: string[]; directUrls: string[] }> = {
  esrs: {
    embedUrls: ['stratsys.com', 'stratsys.se', 'stratsys.net'],
    directUrls: [],
  },
};

export const useHiddenFlags = () => {
  const hiddenModules: Record<string, boolean> = useMemo(
    () =>
      Object.entries(HIDE_MODULES_PATH_CONFIG).reduce((flags, [modulePath, config]) => {
        const hideDirectly = config.directUrls.some((link) =>
          window.location.origin.includes(link)
        );
        const hideWhenEmbedded = config.embedUrls.some((link) =>
          (document.location.ancestorOrigins?.[0] || document.referrer).includes(link)
        );
        const shouldHide = hideDirectly || hideWhenEmbedded;
        return { ...flags, [modulePath]: shouldHide };
      }, {}),
    [window.location.origin, document.location.ancestorOrigins?.[0], document.referrer]
  );

  return hiddenModules;
};
