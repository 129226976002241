import { MutableRefObject, useEffect, useMemo, useRef, useState } from 'react';

import {
  MetricsTableData,
  getMetricWithChildren,
  areArraysOfObjectsEqual,
  isFrequencyYearly,
  useAggregatedRowValue,
} from '../MetricAnswers.hooks';
import { useGetDatapointValues } from './QuarterInput';
import {
  DatapointFieldsFragment_,
  QuestionType_Enum_,
  ShortUser,
  useGetChildrenMetricsMetadataQuery,
} from 'models';
import { TableData } from 'Molecules/NestedTable';
import { HStack, VStack } from '@chakra-ui/react';
import { Row } from '@tanstack/react-table';
import { MetricTypeIcon, MetricTypes } from 'Molecules/MetricTypeIcon';
import { MetricNameWithTag } from 'containers/Esrs/pieces/DataCollection/DataCollectionUtils';
import { getRowName } from '../Metrics.utils';
import { DataCollectionLevel } from 'containers/Esrs/pieces/DataCollection';
import { FrequencyEnums, TimePeriodsEnums } from '../../Requirement';
import { Button, IconButton } from 'Atoms';
import { CancelIcon, RemoveIcon } from 'Tokens/Icons/Function';
import { GeneratedAnswer, GeneratedAnswerStatus } from '../MetricAI';
import { AssessableMetrics } from '../Metrics';

export const getLastEditedData = (date?: Date, user?: ShortUser | null) => {
  if (!date) return { date: '', user: undefined };
  const formattedDate = new Date(date)
    .toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    })
    .replace(/\//g, '.');
  return { date: formattedDate, user: user };
};

export const useLastEdited = (
  esrsAssessmentId: string,
  row: MetricsTableData,
  companyStandardId: string,
  selectedQuarter: string,
  metricLevel?: boolean,
  reportingUnitId?: string
) => {
  const { data: childrenMetadata } = useGetChildrenMetricsMetadataQuery({
    variables: {
      reportingUnitId,
      assessmentId: esrsAssessmentId,
      parentMetricRef: row.metric?.reference,
    },
    skip: !reportingUnitId || !esrsAssessmentId || !row.metric?.reference,
  });
  const { dataPointsPerQuarter, dataPointPerYear, datapointText, isAggregated } =
    useGetDatapointValues(row, reportingUnitId);
  const isYearly = useMemo(
    () => isFrequencyYearly(row, companyStandardId),
    [row, companyStandardId]
  );
  const { result: aggregatedValues } = useAggregatedRowValue(row, isYearly, reportingUnitId);

  if (row.metric?.metricType === QuestionType_Enum_.LongText_) {
    if (isAggregated && !!childrenMetadata?.esrs_Answer[0]?.datapoints?.length) {
      const latestChild = childrenMetadata?.esrs_Answer[0]?.datapoints[0];
      return getLastEditedData(new Date(latestChild.updatedAt), latestChild.author);
    }

    return getLastEditedData(datapointText?.updatedAt, datapointText?.author);
  }

  if ((!isAggregated && aggregatedValues.latestAuthor) || isAggregated) {
    return getLastEditedData(aggregatedValues.latestDate, aggregatedValues.latestAuthor);
  }

  if (isYearly) {
    return getLastEditedData(dataPointPerYear?.updatedAt, dataPointPerYear?.author);
  }

  const getQuarterlyData = () => {
    if (metricLevel) {
      return dataPointsPerQuarter.reduce((a, b) =>
        a?.value?.updatedAt > b?.value?.updatedAt ? a : b
      );
    }
    return dataPointsPerQuarter.find((dp) => dp.field === selectedQuarter);
  };

  const date = isAggregated
    ? aggregatedValues.latestDate
    : isYearly
      ? dataPointPerYear?.updatedAt
      : getQuarterlyData()?.value?.updatedAt;

  return getLastEditedData(date, getQuarterlyData()?.value?.author);
};

const getLastEditedDataForQuarter = (
  quarter: string,
  dataPointsPerQuarter: {
    field: 'Q1' | 'Q2' | 'Q3' | 'Q4';
    value: DatapointFieldsFragment_ | undefined;
  }[]
) => {
  const dp = dataPointsPerQuarter.find((d) => d.field === quarter)?.value;
  return getLastEditedData(dp?.updatedAt, dp?.author);
};

export const useLastEditedPerQuarter = (row: MetricsTableData, reportingUnitId?: string) => {
  const { dataPointsPerQuarter } = useGetDatapointValues(row, reportingUnitId);

  return {
    Q1: getLastEditedDataForQuarter('Q1', dataPointsPerQuarter),
    Q2: getLastEditedDataForQuarter('Q2', dataPointsPerQuarter),
    Q3: getLastEditedDataForQuarter('Q3', dataPointsPerQuarter),
    Q4: getLastEditedDataForQuarter('Q4', dataPointsPerQuarter),
  };
};

export const searchForParentMetric = (metrics: MetricsTableData[], ref: string) => {
  const searchMetric = (metric: MetricsTableData): boolean => {
    if (metric.metric.reference === ref && !metric.subRows?.some((row) => row.referenceToSource)) {
      return true;
    }

    if (metric.subRows) {
      return metric.subRows.some((row) => searchMetric(row));
    }

    return false;
  };

  return metrics?.find((metric) => searchMetric(metric));
};

export const MetricRowTitle = ({
  row,
  rowData,
  companyStandardId,
  onClick,
  isAggregated,
}: {
  row: Row<TableData<MetricsTableData>>;
  rowData?: MetricsTableData;
  companyStandardId: string;
  onClick?: () => void;
  isAggregated?: boolean;
}) => {
  const materialMetric = useMemo(
    () =>
      row.original.metric.materialMetrics?.find(
        (mm) => mm.materialStandardId === companyStandardId
      ),
    [row, companyStandardId]
  );

  const [isCalculated, isChildMetric] = useMemo(() => {
    const isChild =
      row.getParentRow()?.original.tagName &&
      !row.getParentRow()?.original.tags?.length &&
      row.original.parentMetric;

    const calculated = row.getCanExpand() && !!row.original.metric.calculation;

    return [calculated, isChild];
  }, [row]);

  const rowType = useMemo(() => {
    if (isCalculated) return MetricTypes.calculated;
    if (isAggregated) return MetricTypes.aggregated;
    else if (!!row.subRows.length) return MetricTypes.aggregated;
    return MetricTypes.number;
  }, [row, isCalculated]);

  const subMetrics = useMemo(() => {
    if (isAggregated) {
      const metrics = getMetricWithChildren(row.original).slice(1);
      return metrics?.map((metricRow) => ({
        name: metricRow.metric?.shortTitle ?? metricRow.metric?.title ?? '',
        reference: metricRow.metric?.reference ?? '',
      }));
    }
    return row.subRows.map((subRow) => ({
      name: getRowName(subRow.original, true) ?? '',
      reference: subRow.original.metric.reference,
    }));
  }, [row]);

  const rowTitle = useMemo(() => {
    if (isChildMetric) return row.original.metric.shortTitle ?? row.original.metric.title;
    if (row.original.tagName) return row.original.tagName ?? '';
    return row.original.metric.shortTitle ?? row.original.metric.title;
  }, [row]);

  const parentRowTitle = useMemo(() => {
    if (row.original.tagName) {
      return (
        row.getParentRow()?.original.tagName ??
        row.getParentRow()?.original.metric.shortTitle ??
        row.getParentRow()?.original.metric.title
      );
    }
  }, [row]);

  const showTags = useMemo(
    () => (row.original.tagType || !row.original.isChild) && !row.original.tagName,
    [row]
  );

  const materialMetricTags = useMemo(
    () => (showTags ? materialMetric?.materialMetricTags?.map((tag) => tag.tagType) ?? [] : []),
    [materialMetric, showTags]
  );

  const metricNameRef = useRef<HTMLDivElement>(null);

  return (
    <HStack
      className="metricTitle"
      textDecoration="underline"
      textDecorationColor={row.original === rowData ? 'text.hint' : 'transparent'}
      textUnderlineOffset={row.original === rowData ? '2px' : '4px'}
      transition="0.15s"
      pl={`${row.depth * 24}px`}
      spacing="8px"
      cursor="pointer"
      width="100%"
    >
      <MetricTypeIcon
        type={rowType}
        isBusinessUnits={materialMetric?.dataCollection === DataCollectionLevel.reportingUnits}
        isSubsidiaries={materialMetric?.dataCollection === DataCollectionLevel.subsidiaries}
        isQuarterly={materialMetric?.frequency === FrequencyEnums.quarterly}
        calculation={row.original.metric.calculation ?? undefined}
        subMetrics={subMetrics}
      />
      <VStack
        ref={metricNameRef}
        width="100%"
        onClick={onClick}
        height="40px"
        justifyContent="center"
        alignItems="start"
      >
        <MetricNameWithTag
          name={rowTitle}
          parentRowName={parentRowTitle}
          tags={materialMetricTags}
          rowRef={metricNameRef as MutableRefObject<HTMLDivElement>}
        />
      </VStack>
    </HStack>
  );
};

export const AnswerApprovalButton = ({
  state,
  onApprove,
  onDiscard,
  onCancelApprove,
  onCancelDiscard,
}: {
  onApprove: () => void;
  onDiscard: () => void;
  onCancelApprove: () => void;
  onCancelDiscard: () => void;
  state: GeneratedAnswerStatus | null;
}) => {
  if (state === GeneratedAnswerStatus.approved) {
    return (
      <HStack spacing="2px">
        <Button selected variant="secondary" size="sm">
          Approved
        </Button>
        <IconButton
          tooltipLabel="Cancel"
          aria-label="Cancel"
          size="sm"
          variant="ghost"
          icon={<CancelIcon />}
          onClick={onCancelApprove}
        />
      </HStack>
    );
  }

  if (state === GeneratedAnswerStatus.discarded) {
    return (
      <HStack spacing="2px">
        <Button selected variant="secondary" size="sm">
          Discarded
        </Button>{' '}
        <IconButton
          tooltipLabel="Cancel"
          aria-label="Cancel"
          size="sm"
          variant="ghost"
          icon={<CancelIcon />}
          onClick={onCancelDiscard}
        />
      </HStack>
    );
  }

  return (
    <HStack spacing="2px">
      <Button variant="secondary" size="sm" onClick={onApprove}>
        Approve
      </Button>
      <IconButton
        tooltipLabel="Discard"
        aria-label="Discard"
        size="sm"
        variant="ghost"
        icon={<RemoveIcon />}
        onClick={onDiscard}
      />
    </HStack>
  );
};

export const AnswerApprovalCell = ({
  row,
  companyReportingUnitId,
  generatedAnswers,
  setGeneratedAnswers,
  populateQuantitativeAnswers,
  populateNarrativeAnswers,
  assessmentProjectLeaderId,
}: {
  row: MetricsTableData;
  companyReportingUnitId?: string;
  generatedAnswers: GeneratedAnswer[];
  setGeneratedAnswers?: (generatedAnswers: GeneratedAnswer[]) => void;
  populateQuantitativeAnswers?: (
    generatedAnswers: GeneratedAnswer[],
    numericMetrics: MetricsTableData[]
  ) => void;
  populateNarrativeAnswers?: (
    generatedAnswers: GeneratedAnswer[],
    narrativeMetrics: AssessableMetrics
  ) => void;
  assessmentProjectLeaderId?: string;
}) => {
  const [prevValue, setPrevValue] = useState<string | null>();

  const { dataPointPerYear, answer, onDatapointChange } = useGetDatapointValues(
    row,
    companyReportingUnitId
  );

  const generatedAnswer = useMemo(
    () =>
      generatedAnswers?.find(
        (a) => a.metricRef === row?.metric?.reference && areArraysOfObjectsEqual(a.tags, row?.tags)
      ),
    [generatedAnswers, row]
  );

  useEffect(() => {
    if (dataPointPerYear) {
      setPrevValue(dataPointPerYear.value);
    }
  }, []);

  const handleChangeStatus = (status: GeneratedAnswerStatus | null) => {
    setGeneratedAnswers?.(
      (generatedAnswers ?? []).map((a) => {
        if (a.metricRef === row.metric.reference && areArraysOfObjectsEqual(a.tags, row?.tags)) {
          return {
            ...a,
            status,
          };
        }
        return a;
      })
    );
  };

  return (
    generatedAnswer && (
      <AnswerApprovalButton
        state={generatedAnswer?.status}
        onApprove={() => {
          if (populateQuantitativeAnswers) populateQuantitativeAnswers([generatedAnswer], [row]);
          if (populateNarrativeAnswers) populateNarrativeAnswers([generatedAnswer], [row.metric]);
          handleChangeStatus(GeneratedAnswerStatus.approved);
        }}
        onDiscard={() => handleChangeStatus(GeneratedAnswerStatus.discarded)}
        onCancelApprove={() => {
          handleChangeStatus(null);
          if (prevValue) {
            onDatapointChange({
              dp: dataPointPerYear ?? { timeframe: TimePeriodsEnums.year },
              value: prevValue ?? null,
              hasOptedOut: answer?.hasOptedOut ?? false,
              optOutReason: answer?.optOutReason ?? '',
              assessmentProjectLeaderId: assessmentProjectLeaderId,
              tags: row.tags,
            });
          }
        }}
        onCancelDiscard={() => handleChangeStatus(null)}
      />
    )
  );
};
