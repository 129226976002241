import { Box, HStack, VStack, useMediaQuery } from '@chakra-ui/react';
import { Button } from 'Atoms';
import { Menu } from 'Molecules/Menu';
import { ViewSwitcher } from 'Molecules/ViewSwitcher';
import { ChevronDownIcon } from 'Tokens/Icons/Direction';
import { useEffect, useMemo, useRef, useState } from 'react';
import { ReportSideMenu } from './ReportSideMenu';
import { EsrsReportDocsGenerator } from './DocGenerator';
import { ReportStandardPage } from './ReportStandardPage';
import { Breadcrumbs } from 'Molecules/Breadcrumbs';
import { StandardSwitcher } from './ReportComponents/StandardSwitcher';
import { useESRSReportStructure, useReportAnswers } from './Report.hooks';
import { useParams, useSearchParams } from 'react-router-dom';
import { Loader } from 'Molecules';
enum ReportViews {
  prepare = 'Prepare',
  preview = 'Preview',
}

export const CreateReport = () => {
  const { esrsAssessmentId = '' } = useParams();
  const [selectedView, setSelectedView] = useState<string>(ReportViews.prepare);
  const {
    structure,
    standards,
    loading: structureLoading,
  } = useESRSReportStructure(esrsAssessmentId);
  const [searchParams] = useSearchParams();
  const urlStandardRef = useMemo(() => searchParams.get('standardRef'), [searchParams]);
  const urlStdIndex = useMemo(
    () => standards.findIndex((std) => std.reference === urlStandardRef),
    [urlStandardRef, standards]
  );
  const [standardIndex, setStandardIndex] = useState(0);
  const [selectedDr, setSelectedDr] = useState('');
  const reportRef = useRef<HTMLDivElement>(null);
  const [isSmall] = useMediaQuery('(max-width: 1600px)');

  useEffect(() => setSelectedDr(''), [standardIndex]);

  const { reportAnswers, loading: loadingReportAnswers } = useReportAnswers(esrsAssessmentId);

  useEffect(() => {
    if (urlStdIndex >= 0) {
      setStandardIndex(urlStdIndex);
    }
  }, [urlStdIndex]);

  return (
    <VStack w="100%" gap="0px" position="relative" overflow="hidden">
      <HStack
        w="100%"
        alignItems="center"
        justifyContent="space-between"
        borderBottom="1px solid"
        borderColor="border.decorative"
        p="12px 16px"
        position="sticky"
        top="0"
        bg="bg.default"
        zIndex={2}
      >
        <Box w="30%">
          <Breadcrumbs noPadding />
        </Box>
        <StandardSwitcher
          standardIndex={standardIndex}
          isLoading={structureLoading}
          setStandardIndex={setStandardIndex}
          standards={standards}
        />
        <HStack w="30%" justifyContent="flex-end">
          <ViewSwitcher
            views={Object.values(ReportViews)}
            selected={selectedView}
            setSelected={setSelectedView}
          />
          <Menu
            size="lg"
            menuButton={
              <Button rightIcon={<ChevronDownIcon color="inherit" />} variant="primary">
                Download
              </Button>
            }
            sections={[
              {
                actions: [
                  {
                    id: 'esrs-report-word-doc',
                    render: () => <EsrsReportDocsGenerator />,
                  },
                ],
              },
            ]}
          />
        </HStack>
      </HStack>
      <HStack
        alignItems="start"
        gap="16px"
        justifyContent={isSmall ? 'flex-start' : 'center'}
        w="100%"
        paddingInline="16px"
        overflow="hidden"
        overflowX="auto"
      >
        <ReportSideMenu
          structure={structure}
          selectedStandard={standards?.[standardIndex]?.title}
          isPreview={selectedView !== ReportViews.prepare}
          selectedDr={selectedDr}
          isLoading={structureLoading}
          setSelectedDr={setSelectedDr}
          reportRef={reportRef}
          setSelectedStandard={(val) =>
            setStandardIndex(standards.findIndex((std) => std.title === val))
          }
        />
        {loadingReportAnswers ? (
          <Loader />
        ) : (
          <ReportStandardPage
            standardIndex={standardIndex}
            reportRef={reportRef}
            standards={standards}
            reportAnswers={reportAnswers}
            setStandardIndex={setStandardIndex}
            isPreview={selectedView !== ReportViews.prepare}
          />
        )}
        <Box minW="200px" h="100%"></Box>
      </HStack>
    </VStack>
  );
};
