import { VStack, Button, HStack, useDisclosure } from '@chakra-ui/react';
import { IconArrowNarrowDown } from '@tabler/icons-react';
import { Typography } from 'Tokens';
import { MetricsInputTable } from './InputTable';
import { MetricsTableData } from './MetricAnswers.hooks';
import { FrequencyEnums, TimePeriods } from '../Requirement';
import { SelectedMetric } from 'containers/Esrs/EsrsAssessment.hooks';
import { Suspense, useMemo, useState } from 'react';
import { DownloadIcon } from '@chakra-ui/icons';
import { LinkAnchor } from 'Molecules/LinkAnchor';
import { UploadIcon } from 'Tokens/Icons/Function';
import { useExportEsrsMetricTable, MetricsExcelUploadModal } from './MetricsExcel';
import { User } from 'models';
import { Menu } from 'Molecules/Menu';
import { useToast } from 'utils/hooks';

const TableTitle = ({
  title,
  metrics,
  disclosureRequirement,
  businessUnit,
  companyStandardId,
}: {
  title: string;
  metrics: MetricsTableData[];
  disclosureRequirement: string;
  businessUnit: string;
  companyStandardId: string;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const areTableMetricsYearly = useMemo(
    () =>
      metrics.every(
        ({ metric }) =>
          metric.materialMetrics.find((mm) => mm.materialStandardId === companyStandardId)
            ?.frequency === FrequencyEnums.yearly
      ),
    [metrics]
  );

  const areTableMetricsQuarterly = useMemo(
    () =>
      metrics.every(
        ({ metric }) =>
          metric.materialMetrics.find((mm) => mm.materialStandardId === companyStandardId)
            ?.frequency === FrequencyEnums.quarterly
      ),
    [metrics]
  );

  const exportMetrics = useExportEsrsMetricTable();

  return (
    <HStack w="100%" justifyContent="space-between">
      <LinkAnchor id={metrics[0].metric.reference} text={title} marginLeft="-20px" />
      <HStack spacing="4px">
        <Button variant="ghost" leftIcon={<UploadIcon />} onClick={onOpen}>
          Import
        </Button>
        <Menu
          size="md"
          sections={[
            {
              actions: [
                {
                  id: 'download',
                  title: 'Download Excel file',
                  variant: 'ghost',
                  onClick: () => {
                    toast({
                      text: 'Downloading file...',
                      closable: true,
                      duration: null,
                    });
                    exportMetrics(
                      metrics,
                      disclosureRequirement,
                      businessUnit,
                      areTableMetricsQuarterly,
                      areTableMetricsYearly,
                      companyStandardId
                    );
                  },
                  leftElement: <DownloadIcon />,
                },
              ],
            },
          ]}
        />
      </HStack>
      <Suspense>
        <MetricsExcelUploadModal
          isOpen={isOpen}
          onClose={onClose}
          metrics={metrics}
          disclosureRequirement={disclosureRequirement}
          businessUnit={businessUnit}
          areTableMetricsQuarterly={areTableMetricsQuarterly}
          areTableMetricsYearly={areTableMetricsYearly}
          companyStandardId={companyStandardId}
        />
      </Suspense>
    </HStack>
  );
};

export const MetricTableWithTitle = ({
  metric,
  selectedQuarter,
  onDrawerOpen,
  onOpen,
  setSelectedMetric,
  isOverview,
  areMetricsYearly,
  rowData,
  isMetricDr,
  companyStandardId,
  filteredMetrics,
  currency,
  esrsAssessmentProjectLeader,
  setRowData,
  disclosureRequirementTitle,
  businessUnitName,
}: {
  metric: MetricsTableData;
  selectedQuarter: TimePeriods;
  onDrawerOpen: () => void;
  onOpen: () => void;
  setSelectedMetric: (param: SelectedMetric) => void;
  companyStandardId: string;
  isOverview: boolean;
  areMetricsYearly: boolean;
  currency: string;
  isGroup?: boolean;
  esrsAssessmentProjectLeader?: Partial<User>;
  rowData?: MetricsTableData;
  setRowData: (param: MetricsTableData | undefined) => void;
  filteredMetrics?: MetricsTableData[];
  isMetricDr: boolean;
  disclosureRequirementTitle: string;
  businessUnitName: string;
}) => {
  const [numberOfRows, setNumberOfRows] = useState<Record<string, number>>({});

  const toggleShowMore = (category: string) => {
    setNumberOfRows((prevStates) => ({
      ...prevStates,
      [category]: (prevStates[category] || 5) + 10,
    }));
  };

  const getTotalRows = (data: MetricsTableData[]): number => {
    let totalRows = data.length;
    for (const row of data) {
      if (row.subRows && row.subRows.length > 0) {
        totalRows += getTotalRows(row.subRows);
      }
    }
    return totalRows;
  };

  const rowsCount = useMemo(() => getTotalRows([metric]), [metric]);
  const remainingCount = useMemo(
    () =>
      rowsCount - (numberOfRows[metric.metric?.reference ?? ''] ?? 5) > 10
        ? 10
        : rowsCount - (numberOfRows[metric.metric?.reference ?? ''] ?? 5),
    [rowsCount, metric, numberOfRows]
  );
  const showMoreButton = useMemo(() => remainingCount > 0, [remainingCount]);

  return (
    <VStack alignItems="start" id={metric.metric.reference}>
      <TableTitle
        title={metric.metric.title + '' + (metric.tagType?.toLowerCase() ?? '')}
        metrics={[metric]}
        disclosureRequirement={disclosureRequirementTitle}
        businessUnit={businessUnitName}
        companyStandardId={companyStandardId}
      />
      <MetricsInputTable
        metrics={[metric]}
        selectedQuarter={selectedQuarter}
        onOpen={onOpen}
        setSelectedMetric={setSelectedMetric}
        onDrawerOpen={onDrawerOpen}
        isOverview={isOverview}
        companyStandardId={companyStandardId}
        areMetricsYearly={areMetricsYearly}
        currency={currency ?? ''}
        esrsAssessmentProjectLeader={esrsAssessmentProjectLeader}
        pageSize={numberOfRows[metric.metric.reference]}
        rowData={rowData}
        setRowData={setRowData}
        filteredMetrics={filteredMetrics}
        setNumberOfRows={setNumberOfRows}
        isMetricDr={isMetricDr}
      />
      {showMoreButton && (
        <Button
          mt="2px"
          variant="ghost"
          size="sm"
          onClick={() => toggleShowMore(metric.metric.reference)}
        >
          <HStack spacing="8px">
            <IconArrowNarrowDown size="16px" />
            <Typography variant="bodyStrong" size="sm">
              Load {remainingCount} more
            </Typography>
          </HStack>
        </Button>
      )}
    </VStack>
  );
};
