import { Box, HStack, Skeleton, Spinner, VStack } from '@chakra-ui/react';
import { TimePeriodsEnums } from '../Requirement';
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { MetricsTableData } from './MetricAnswers.hooks';
import { useGetDatapointValues } from './InputTable/QuarterInput';
import { AutoResizeTextarea, Link } from 'Atoms';
import { Typography } from 'Tokens';
import { WarningIcon } from 'Tokens/Icons/Status';
import { useLocation, useSearchParams } from 'react-router-dom';
import { debounce } from 'lodash';
import { useTranslation } from 'utils/translation';
import { AggregatedQualitativeAnswers, AnswerGenerationDetails } from './AggregatedMetrics';
import { AIIcon } from 'Tokens/Icons/Function';
import { GeneratedAnswer } from './MetricAI';

type TextMetricProps = {
  metric: MetricsTableData['metric'];
  companyReportingUnit?: string;
  autoExpandHeight?: boolean;
  isGeneratingAnswers?: boolean;
  rowData?: MetricsTableData;
  setRowData: (
    param: (MetricsTableData & { sourceData?: AggregatedQualitativeAnswers[number] }) | undefined
  ) => void;
  answersData?: AggregatedQualitativeAnswers;
  answerGenerationDetails?: AnswerGenerationDetails;
  hasRef?: boolean;
  generatedAnswer?: GeneratedAnswer;
  isReadOnly?: boolean;
  isAI?: boolean;
};

const AIInfoWarning = () => {
  const { t } = useTranslation('ai');
  return (
    <HStack spacing="4px" alignItems="flex-start" justifyContent="start">
      <WarningIcon color="text.warning" boxSize="18px"></WarningIcon>
      <Typography variant="body" color="text.warning">
        {t('esrs.answers.fail')}
      </Typography>
    </HStack>
  );
};

const AISummaryAvailable = ({
  metric,
  setRowData,
  metricAnswerData,
}: {
  metric: MetricsTableData['metric'];
  setRowData: (
    param: (MetricsTableData & { sourceData?: AggregatedQualitativeAnswers[number] }) | undefined
  ) => void;
  metricAnswerData?: AggregatedQualitativeAnswers[number];
}) => {
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const { t } = useTranslation('ai');
  return (
    <HStack spacing="4px" alignItems="flex-start" justifyContent="start">
      <AIIcon color="text.action" boxSize="16px" />
      <Link
        to={location.pathname + '?source=true'}
        onClick={() => {
          setRowData?.({ metric: metric, sourceData: metricAnswerData });
          searchParams.set('source', 'true');
        }}
      >
        <Typography variant="detail">{t('esrs.summary.summaryAvailable')}</Typography>
      </Link>
    </HStack>
  );
};

export const LongTextMetricInput: FC<TextMetricProps> = React.memo(
  ({
    metric,
    companyReportingUnit,
    isGeneratingAnswers = false,
    rowData,
    setRowData,
    answersData,
    answerGenerationDetails,
    hasRef = true,
    generatedAnswer,
    isReadOnly = false,
    isAI = false,
  }) => {
    const [searchParams] = useSearchParams();
    const urlDatapointId = useMemo(() => searchParams.get('datapointId'), [searchParams]);
    const openDrawer = new URLSearchParams(location.search).get('openDrawer');
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const [hasStartedTyping, setHasStartedTyping] = useState<boolean>(false);
    const { onDatapointChange, dataPointPerYear, answer, loading } = useGetDatapointValues(
      {
        metric,
      },
      companyReportingUnit
    );

    const metricAnswerData = useMemo(() => {
      return answersData?.find((data) => data.metricRef === metric?.reference);
    }, [answersData, metric]);

    const isIrrelevantResponse = useMemo(() => {
      return dataPointPerYear?.isAIGenerated && dataPointPerYear.value === '-';
    }, [dataPointPerYear]);

    const allSubsidiariesAnswered = useMemo(() => {
      return metricAnswerData?.subsidiaries?.every(
        (subsidiary) =>
          !!subsidiary.answer?.datapoints.find((dp) => dp.timeframe === TimePeriodsEnums.year)
            ?.value
      );
    }, [metricAnswerData]);

    const isAIWriting = useMemo(() => {
      return (
        isGeneratingAnswers ||
        (answerGenerationDetails?.isGenerating &&
          answerGenerationDetails?.metricRef === metric.reference)
      );
    }, [isGeneratingAnswers, answerGenerationDetails]);

    // Use a local state for the input value to ensure it's controlled.
    const [inputValue, setInputValue] = useState(dataPointPerYear?.value ?? null);

    // Condition to show that AI summary is available
    const showAvailableSummary = useMemo(() => {
      return allSubsidiariesAnswered && !dataPointPerYear?.isAIGenerated && !inputValue;
    }, [allSubsidiariesAnswered, dataPointPerYear, inputValue]);

    // Update the local input value whenever the external value changes.
    useEffect(() => {
      if (!hasStartedTyping) setInputValue(dataPointPerYear?.value ?? null);
    }, [dataPointPerYear?.value]);

    // Restored to rerender narrative answers when population finishes
    useEffect(() => {
      if (dataPointPerYear?.value !== inputValue && dataPointPerYear?.isAIGenerated) {
        setHasStartedTyping(false);
      }
    }, [dataPointPerYear, inputValue]);

    useEffect(() => {
      if (dataPointPerYear?.id === urlDatapointId && openDrawer) {
        setRowData?.({ metric: metric });
      }
    }, [urlDatapointId, dataPointPerYear, openDrawer]);

    // Update subsidiaries source data
    useEffect(() => {
      const hasReportingUnitsOrSubsidiaries =
        !!metricAnswerData?.reportingUnits?.length || !!metricAnswerData?.subsidiaries?.length;

      if (rowData?.metric.reference === metric.reference)
        if (hasReportingUnitsOrSubsidiaries) {
          setRowData?.({ ...rowData, sourceData: metricAnswerData });
        }
    }, [metricAnswerData, metric]);

    useEffect(() => {
      if (textareaRef.current) {
        textareaRef.current.style.minHeight = 'auto';
        const tdHeight = textareaRef.current.parentElement?.parentElement?.clientHeight;
        textareaRef.current.style.height = tdHeight ? `${tdHeight - 12}px` : 'unset';
        textareaRef.current.style.minHeight = tdHeight ? `${tdHeight - 12}px` : 'unset';
        if (isIrrelevantResponse || showAvailableSummary) {
          textareaRef.current.style.height = '44px';
          textareaRef.current.style.minHeight = '44px';
        }
      }
    }, [textareaRef.current, loading, isIrrelevantResponse, inputValue]);

    // Debounce function to delay state update and minimize re-renders.
    const debounceSave = useCallback(
      debounce((newValue) => {
        onDatapointChange({
          value: newValue,
          factValue: dataPointPerYear?.factValue,
          hasOptedOut: answer?.hasOptedOut ?? false,
          optOutReason: answer?.optOutReason ?? '',
          dp: dataPointPerYear ?? { timeframe: TimePeriodsEnums.year },
        });
      }, 1000),
      [onDatapointChange, answer, dataPointPerYear]
    );

    // Handle input changes with a debounced call.
    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      const newValue = event.target.value === '' ? null : event.target.value;
      setInputValue(newValue);
      debounceSave(newValue);
      setHasStartedTyping(true);
    };

    if (loading) {
      return <Skeleton height="20px" width="100%" />;
    }

    if (generatedAnswer) {
      return (
        <VStack my="10px" alignItems="start" w="100%">
          <Typography variant="body">{generatedAnswer?.answer}</Typography>
          {generatedAnswer.answer === '-' && (
            <HStack spacing="4px" alignItems="flex-start" justifyContent="start">
              <WarningIcon color="text.warning" boxSize="18px"></WarningIcon>
              <Typography variant="body" color="text.warning">
                AI couldn't generate answer
              </Typography>
            </HStack>
          )}
        </VStack>
      );
    }

    if (isAIWriting) {
      return (
        <HStack my="10px">
          <Spinner boxSize="16px" color="bg.brand.accent" />
          <Typography variant="body">AI is writing...</Typography>
        </HStack>
      );
    }

    return (
      <VStack w="100%" alignItems="start" spacing="8px" h="100%%">
        {isReadOnly ? (
          <VStack p="8px" alignItems="start">
            <Typography color={isAI && !dataPointPerYear?.value ? 'text.hint' : ''} variant="body">
              {dataPointPerYear?.value ? dataPointPerYear.value : isAI ? 'Answer' : 'N/A'}
            </Typography>
          </VStack>
        ) : (
          <AutoResizeTextarea
            minH={hasRef ? 'unset' : '80px'}
            variant="ghost"
            ref={hasRef ? textareaRef : undefined}
            borderRadius="6px"
            lineHeight="20px"
            size="md"
            placeholder="Write your answer"
            value={inputValue ?? ''}
            opacity={answer?.hasOptedOut ? 0.4 : 1}
            onChange={handleChange}
            onClick={(e) => e.stopPropagation()}
            onBlur={() => setHasStartedTyping(false)}
          />
        )}
        {isIrrelevantResponse && <AIInfoWarning />}
        {showAvailableSummary && (
          <AISummaryAvailable
            metric={metric}
            setRowData={setRowData}
            metricAnswerData={metricAnswerData}
          />
        )}
      </VStack>
    );
  }
);
