import { Tag } from 'Atoms';
import { MetricsTableData, isFrequencyYearly } from '../MetricAnswers.hooks';
import { useGetDatapointValues } from './QuarterInput';
import { useMemo } from 'react';
import { TimePeriods } from '../../Requirement';
import { useMdrmAnswers } from '../MetricSidebar/MetricMdrm.hooks';

export const MetricMdrmStatus = ({
  row,
  companyReportingUnit,
  materialStandardId,
  selectedQuarter,
}: {
  row: MetricsTableData;
  companyReportingUnit?: string;
  materialStandardId: string;
  selectedQuarter: TimePeriods;
}) => {
  const { dataPointsPerQuarter, dataPointPerYear } = useGetDatapointValues(
    row,
    companyReportingUnit
  );
  const isYearly = useMemo(
    () => isFrequencyYearly(row, materialStandardId),
    [row, materialStandardId]
  );
  const isParentMetric = useMemo(() => !!row?.subRows?.length, [row]);

  const datapoint = useMemo(
    () =>
      isParentMetric || isYearly
        ? dataPointPerYear
        : dataPointsPerQuarter.find((dp) => dp.field === selectedQuarter)?.value,
    [isYearly, dataPointPerYear, dataPointsPerQuarter, selectedQuarter]
  );

  const { allMdrmAnswered } = useMdrmAnswers({
    datapointId: datapoint?.id,
    metricRef: row.metric.reference,
    row,
  });

  return allMdrmAnswered ? (
    <Tag size="xs" variant="success" title="Added" />
  ) : (
    <Tag size="xs" variant="warning" title="To add" />
  );
};
