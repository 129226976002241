import { AvatarGroup as ChakraAvatarGroup, HStack, VStack } from '@chakra-ui/react';
import { Avatar, AvatarSize } from 'Atoms';
import { Typography } from 'Tokens';
import { getUsernameColor } from 'Organisms/UserAvatar/UserAvatar.utils';
import { TeamMember } from 'containers/TeamMembers';
import { useMemo } from 'react';

export type NameWithLogo = {
  name: string;
  logoUrl?: string;
};

export const AvatarGroup = ({
  names,
  size = 'md',
  namesOrLogos,
  members,
  isRounded = true,
}: {
  names?: string[];
  size?: AvatarSize;
  namesOrLogos?: NameWithLogo[];
  members?: TeamMember[];
  isRounded?: boolean;
}) => {
  const showTruncatedAvatar = useMemo(
    () => (members?.length ?? 0) > 3 || (names?.length ?? 0) > 3 || (namesOrLogos?.length ?? 0) > 3,
    [members, names, namesOrLogos]
  );
  const truncatedCount = useMemo(() => {
    if (names && names.length > 3) return names.slice(3).length;
    else if (namesOrLogos && namesOrLogos.length > 3) return namesOrLogos.slice(3).length;
    else if (members && members.length > 3) return members.slice(3).length;
  }, [names, namesOrLogos, members]);

  const truncatedTootlip = useMemo(() => {
    if (showTruncatedAvatar) {
      return (
        <VStack alignItems="start" gap="8px" p="4px">
          {names &&
            names.slice(3).map((name) => (
              <Typography variant="body" color="text.onAccent" maxW="200px">
                {name}
              </Typography>
            ))}
          {namesOrLogos &&
            namesOrLogos.slice(3).map((nameOrLogo) => (
              <Typography variant="body" color="text.onAccent" maxW="200px">
                {nameOrLogo.name}
              </Typography>
            ))}
          {members &&
            members.slice(3).map((member) => (
              <Typography variant="body" color="text.onAccent" maxW="200px">
                {member.name ?? member.email}
              </Typography>
            ))}
        </VStack>
      );
    }
    return '';
  }, [names, namesOrLogos, members, showTruncatedAvatar]);

  return (
    <HStack>
      <ChakraAvatarGroup size={size} max={3} spacing="-6px">
        {names?.slice(0, 3).map((name) => <Avatar zIndex={3} name={name} size={size} key={name} />)}
        {namesOrLogos?.slice(0, 3).map((avatar) => {
          const hasLogo = avatar.logoUrl && !avatar.logoUrl?.includes('default=blank');
          return (
            <Avatar
              key={avatar.name}
              name={avatar.name}
              zIndex={3}
              size={size}
              src={hasLogo ? avatar.logoUrl : undefined}
              bg={getUsernameColor(avatar.name)}
              borderRadius={isRounded ? '50%' : '6px'}
            />
          );
        })}
        {members?.slice(0, 3).map((member) => {
          const active = member.id && !member.disabled;
          return (
            <Avatar
              key={member.id}
              size="sm"
              zIndex={3}
              name={member?.name ?? member.email}
              color={active ? 'text.onAccent' : 'text.default'}
              backgroundColor={active ? 'bg.accent' : 'bg.disabled.accent'}
            />
          );
        })}
      </ChakraAvatarGroup>
      {showTruncatedAvatar && (
        <Avatar
          ml="-14px"
          zIndex={1}
          size="sm"
          name={`+ ${truncatedCount}`}
          tooltip={truncatedTootlip}
        />
      )}
    </HStack>
  );
};
