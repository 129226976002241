import { DataPoint, addUpValues, areArraysOfObjectsEqual } from 'containers/Esrs';
import { Parser } from 'expr-eval';
import { TimePeriodsEnums } from '../../Requirement';

const parser = new Parser();

export const ESRS_KNOWN_VARIABLES: { [key: string]: string } = {
  NONE: '--',
};

export const parseExpression = (condition: string) => {
  try {
    return parser.parse(condition);
  } catch (error) {
    // TODO: Set up error boundary around assessment to catch this and show appropriate error message
    throw new Error(`Cannot parse condition "${condition}": ${(error as Error).message}`);
  }
};

export const resolveMetricCalculation = (
  dps: { metricRef: string; value: number | string; tagValues: string[] }[],
  calculation?: string | null
) => {
  if (calculation) {
    if (ESRS_KNOWN_VARIABLES[calculation]) {
      return ESRS_KNOWN_VARIABLES[calculation];
    }

    // turn into object with unique keys
    const datapoints = dps.reduce(
      (acc, dp) => {
        acc[dp.metricRef] = (acc[dp.metricRef] ?? 0) + Number(dp.value) ?? 0;
        return acc;
      },
      {} as { [metricRef: string]: number }
    );

    const expr = parseExpression(calculation);
    const allVariables = expr.variables();

    const vars = { ...datapoints };
    // Add missing variables with value 0
    allVariables.forEach((variable) => {
      if (!vars[variable]) {
        vars[variable] = 0;
      }
    });

    try {
      const result = expr.evaluate(vars);
      return result;
    } catch (e) {
      console.log('ERROR', e);
      return 0;
    }
  }

  return addUpValues(dps.map((x) => Number(x.value)));
};

export const filterByTime = (datapoint: DataPoint, isYearly: boolean) => {
  if (isYearly) {
    return datapoint.timeframe === TimePeriodsEnums.year;
  }
  return datapoint.timeframe !== TimePeriodsEnums.year;
};

export const filterByCorrectTags = (
  datapoint: DataPoint,
  tags: {
    tagType: string;
    tagValue: string;
  }[][]
) => {
  const flatTags = tags.flat();
  if (flatTags.length === 0) {
    return true;
  }
  return tags?.length
    ? tags.some((tag) => areArraysOfObjectsEqual(tag, datapoint.datapointTags))
    : true;
};

export const filterByHasTags = (datapoint: DataPoint, shouldHave: boolean) => {
  if (shouldHave) {
    return datapoint.datapointTags.length > 0;
  }
  return !datapoint.datapointTags.length || datapoint.datapointTags.length <= 0;
};

export const combinedFiltersForTags = (
  datapoint: DataPoint,
  isYearly: boolean,
  tags: {
    tagType: string;
    tagValue: string;
  }[][]
) => {
  return (
    filterByHasTags(datapoint, true) &&
    filterByTime(datapoint, isYearly) &&
    filterByCorrectTags(datapoint, tags)
  );
};

export const combinedFiltersForNoTags = (datapoint: DataPoint, isYearly: boolean) => {
  return filterByTime(datapoint, isYearly) && filterByHasTags(datapoint, false);
};

export const showResult = (result: string | number) => {
  if (typeof result === 'string') {
    return result;
  }
  if (isNaN(result)) return ESRS_KNOWN_VARIABLES.NONE;

  return result.toFixed(2).replace(/[.,]00$/, '');
};

export const showResultAsPercentage = (
  value?: number | string | null,
  total?: number | string | null
) => {
  if (value === null || total === null) return ESRS_KNOWN_VARIABLES.NONE;

  const result = (Number(value) / Number(total)) * 100;

  if (isNaN(result)) return '';

  return `(${result.toFixed(2).replace(/[.,]00$/, '')}%)`;
};
