import { Box, Checkbox } from '@chakra-ui/react';
import { Dispatch, SetStateAction } from 'react';
import { MetricsTableData, areArraysOfObjectsEqual } from '..';

const recursivelyCheckSubRows = (
  subRows: MetricsTableData[],
  selectedRows: MetricsTableData[]
): boolean => {
  return subRows.every((subRow) => {
    const isSubRowSelected = selectedRows.some(
      (r) =>
        r.metric.reference === subRow.metric.reference &&
        areArraysOfObjectsEqual(r.tags, subRow.tags)
    );

    if (!isSubRowSelected) {
      return false;
    }

    if (subRow.subRows && subRow.subRows.length > 0) {
      return recursivelyCheckSubRows(subRow.subRows, selectedRows);
    }

    return true;
  });
};

// Recursively get the indeterminate state for subRows
const recursivelyGetIndeterminateState = (
  subRows: MetricsTableData[],
  selectedRows: MetricsTableData[]
): boolean => {
  return subRows.some((subRow) => {
    const isSubRowSelected = selectedRows.some(
      (r) =>
        r.metric.reference === subRow.metric.reference &&
        areArraysOfObjectsEqual(r.tags, subRow.tags)
    );

    if (subRow.subRows && subRow.subRows.length > 0) {
      return recursivelyGetIndeterminateState(subRow.subRows, selectedRows) || isSubRowSelected;
    }

    return isSubRowSelected;
  });
};

// Updates the selected rows recursively based on the checked state
const updateSelectedRows = (
  subRows: MetricsTableData[],
  checked: boolean,
  selectedRows: MetricsTableData[]
): MetricsTableData[] => {
  return subRows.flatMap((subRow) => {
    if (subRow.subRows && subRow.subRows?.length > 0) {
      const updatedChildren = updateSelectedRows(subRow.subRows, checked, selectedRows);
      if (checked) {
        return [subRow, ...updatedChildren];
      } else {
        return updatedChildren;
      }
    }

    if (checked) {
      if (
        !selectedRows.some(
          (r) =>
            r.metric.reference === subRow.metric.reference &&
            areArraysOfObjectsEqual(r.tags, subRow.tags)
        )
      ) {
        return [subRow];
      }
    } else {
      return selectedRows.filter(
        (item) =>
          item.metric.reference === subRow.metric.reference &&
          areArraysOfObjectsEqual(item.tags, subRow.tags)
      );
    }
    return [];
  });
};

export const MetricCheckboxSelector = ({
  row,
  selectedRows,
  setSelectedRows,
}: {
  row: MetricsTableData;
  selectedRows: MetricsTableData[];
  setSelectedRows: Dispatch<SetStateAction<MetricsTableData[]>>;
}) => {
  const hasSubRows = !!row.subRows && row.subRows?.length > 0;
  const subRows = row.subRows ?? [];

  const isChecked = hasSubRows
    ? recursivelyCheckSubRows(subRows, selectedRows)
    : selectedRows.some(
        (r) =>
          r.metric.reference === row.metric.reference && areArraysOfObjectsEqual(r.tags, row.tags)
      );

  const isIndeterminate =
    hasSubRows && !isChecked && recursivelyGetIndeterminateState(subRows, selectedRows);

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.currentTarget.checked;

    if (hasSubRows) {
      setSelectedRows?.((cur) => {
        const updatedRows = updateSelectedRows(subRows, checked, cur);
        return checked
          ? [...cur, ...updatedRows]
          : cur.filter(
              (item) =>
                !updatedRows.some(
                  (subRow) =>
                    subRow.metric.reference === item.metric.reference &&
                    areArraysOfObjectsEqual(subRow.tags, item.tags)
                )
            );
      });
    } else {
      if (checked) {
        setSelectedRows?.((cur) => [...cur, row]);
      } else {
        setSelectedRows?.((cur) =>
          cur.filter(
            (item) =>
              item.metric.reference !== row.metric.reference ||
              !areArraysOfObjectsEqual(item.tags, row.tags)
          )
        );
      }
    }
  };

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Checkbox
        isDisabled={false}
        isChecked={isChecked}
        onChange={handleCheckboxChange}
        isIndeterminate={isIndeterminate}
      />
    </Box>
  );
};
