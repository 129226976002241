import { KeyResultEnums, MilestoneFields, TargetFields } from '../../../Requirement';
import { GetReportingUnitsQuery_ } from 'models';
import { isNullish } from 'utils/numbers';
import { SubsidiariesTargetsType } from '../AddTargets.hooks';
import { percentageToNumber, numberToPercentage } from 'containers/Esrs/utils';

function sumUpArray(numbers: number[]): number {
  return numbers.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
}

const getMilestoneTotal = (
  milestones: number[] | undefined,
  baselines: number[] | undefined,
  requiredLength: number,
  baselineMilestonePairs: {
    baseline: number | undefined;
    milestone: number | undefined;
  }[]
) => {
  const allBaselinesAvailable = !!baselines && baselines?.length === requiredLength;

  const allMilestonesAvailable = !!milestones && milestones?.length === requiredLength;

  const totalBaselines = allBaselinesAvailable ? sumUpArray(baselines) : undefined;

  const totalMilestones = allMilestonesAvailable ? sumUpArray(milestones) : undefined;

  const totalGoal =
    totalBaselines && totalMilestones
      ? baselineMilestonePairs.reduce((acc, pair) => {
          const goal = percentageToNumber(pair.milestone ?? 0, pair.baseline ?? 0);
          return goal + acc;
        }, 0)
      : undefined;

  const totalPercentage =
    totalBaselines && totalMilestones && totalGoal
      ? numberToPercentage(totalBaselines, totalGoal)
      : undefined;

  return { totalBaselines, totalMilestones: totalPercentage, totalGoal };
};

export const getBUMilestoneTotal = (
  target: TargetFields,
  reportingUnits: GetReportingUnitsQuery_['reportingUnits'] | undefined,
  milestone?: MilestoneFields
) => {
  const baselines = reportingUnits
    ?.map((ru) => {
      const baseline = target[`${KeyResultEnums.baseline}_${ru?.id}`];
      if (!isNullish(baseline)) return Number(baseline);
      return baseline;
    })
    .filter((baseline): baseline is number => !!baseline);

  const milestones = reportingUnits
    ?.map((ru) => {
      const milestoneValue = milestone?.reportingUnits?.[`${ru?.id}`];
      if (!isNullish(milestoneValue)) return Number(milestoneValue);
      return milestoneValue;
    })
    .filter((m): m is number => !!m);

  const baselineMilestonePairs =
    reportingUnits?.map((ru) => {
      const milestoneValue = milestone?.reportingUnits?.[`${ru?.id}`];
      const baseline = target[`${KeyResultEnums.baseline}_${ru?.id}`];
      return {
        baseline: !!baseline ? Number(baseline) : undefined,
        milestone: !isNullish(milestoneValue) ? Number(milestoneValue) : undefined,
      };
    }) ?? [];

  return getMilestoneTotal(
    milestones,
    baselines,
    reportingUnits?.length ?? 0,
    baselineMilestonePairs
  );
};

export const getSubMilestoneTotal = (
  subsidiaries: SubsidiariesTargetsType,
  year: number | undefined
) => {
  const baselines = subsidiaries
    ?.map((subsidiary) => {
      const baseline = subsidiary.target?.keyResults.find(
        (keyResult) => keyResult.reportingUnitId === null
      )?.baseline;
      if (!!baseline) return Number(baseline);
    })
    .filter((baseline): baseline is number => !!baseline);

  const milestones = subsidiaries
    ?.map((subsidiary) => {
      const milestone = subsidiary.target?.milestones.find((m) => m.year === year);
      const milestoneValue = milestone?.milestoneResults.find(
        (m) => m.reportingUnitId === null
      )?.value;
      if (!isNullish(milestoneValue)) return Number(milestoneValue);
      return milestoneValue;
    })
    .filter((milestone): milestone is number => !!milestone);

  const baselineMilestonePairs =
    subsidiaries?.map((subsidiary) => {
      const milestone = subsidiary.target?.milestones.find((m) => m.year === year);
      const milestoneValue = milestone?.milestoneResults.find(
        (m) => m.reportingUnitId === null
      )?.value;
      const baseline = subsidiary.target?.keyResults.find(
        (keyResult) => keyResult.reportingUnitId === null
      )?.baseline;
      return {
        baseline: !!baseline ? Number(baseline) : undefined,
        milestone: !isNullish(milestoneValue) ? Number(milestoneValue) : undefined,
      };
    }) ?? [];

  return getMilestoneTotal(
    milestones,
    baselines,
    subsidiaries?.length ?? 0,
    baselineMilestonePairs
  );
};
