import { VStack, useDisclosure, HStack, Grid, Box, GridItem, Skeleton } from '@chakra-ui/react';
import { Typography } from 'Tokens';
import React, { Suspense, useEffect, useMemo, useState } from 'react';
import {
  useGetAggregatedMetrics,
  getGraphObject,
  getNestedMetrics,
  AggregatedQualitativeAnswers,
} from './AggregatedMetrics.hooks';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Button, IconButton, Infobox, TruncatableText } from 'Atoms';
import { HelpIcon } from 'Tokens/Icons/Status';
import { LearnMoreDrawer, Loader, MetricLearnMoreHeader } from 'Molecules';
import { GraphCard } from '../MetricsGraphModal';
import {
  GetAnswersForMetricsOnCompanyLevelDocument_,
  GetAnswersForMetricsOnGroupLevelDocument_,
  QuestionType_Enum_,
  GetEsrsAssessmentMetadataDocument_,
  useUpdateEsrsAssessmentMetaDataMutation,
} from 'models';
import { HistogramIcon, SumIcon, TableIcon } from 'Tokens/Icons/Data';
import { FrequencyEnums, TimePeriods, TimePeriodsEnums } from '../../Requirement';
import { MetricsTableFilters } from '../MetricsTableFilters';
import { AggregatedMetricsTable } from './AggregatedMetricsTable';
import { useCurrentCompany, useToast } from 'utils/hooks';
import { DownloadIcon, MaximizeIcon, UploadIcon } from 'Tokens/Icons/Function';
import {
  SelectedMetric,
  getNestedRows,
  useGetAggregatedMetricsData,
  MetricViewEnums,
  DREnums,
  MetricsTableData,
} from 'containers/Esrs';
import { MetricsInputTable } from '../InputTable';
import { OptOutModal } from '../OptOutModal';
import { useApolloClient } from '@apollo/client';
import { LinkAnchor } from 'Molecules/LinkAnchor';
import { NarrativeMetricsTable } from '../InputTable/NarrativeMetricsTable';
import { MetricSidebar } from '../MetricSidebar';
import { DataCollectionLevel } from 'containers/Esrs/pieces/DataCollection';
import { IconArrowNarrowDown } from '@tabler/icons-react';
import { isMetricStateMaterial } from 'containers/Esrs/utils';
import { MetricsAISection } from '../MetricAI';
import { Menu } from 'Molecules/Menu';
import { MetricsExcelUploadModal, useExportEsrsMetricTable } from '../MetricsExcel';

export const TableViewSwitcher = ({
  setIsChartsView,
  isChartsView,
}: {
  setIsChartsView: (param: boolean) => void;
  isChartsView: boolean;
}) => (
  <HStack
    spacing="0px"
    w="fit-content"
    border="1px solid"
    borderRadius="8px"
    borderColor="border.decorative"
    height="36px"
  >
    <IconButton
      aria-label="table-icon"
      variant="ghost"
      icon={<TableIcon color={isChartsView ? 'text.muted' : 'text.selected'} />}
      borderRightRadius="0px"
      onClick={() => setIsChartsView(false)}
      bg={isChartsView ? 'none' : 'bg.selected'}
    />
    <IconButton
      aria-label="histogram"
      variant="ghost"
      icon={<HistogramIcon color={isChartsView ? 'text.selected' : 'text.muted'} />}
      borderLeftRadius="0px"
      onClick={() => setIsChartsView(true)}
      bg={isChartsView ? 'bg.selected' : 'none'}
    />
  </HStack>
);

export type AnswerGenerationDetails =
  | {
      metricRef: string;
      isGenerating: boolean;
    }
  | undefined;

export const AggregatedMetrics = ({
  extraHeaderContent,
  isGeneratingAnswers = false,
  withAssociation = false,
  withBorder = false,
}: {
  extraHeaderContent?: React.ReactNode;
  isGeneratingAnswers?: boolean;
  withAssociation?: boolean;
  withBorder?: boolean;
}) => {
  const client = useApolloClient();
  const {
    companyId,
    esrsAssessmentId = '',
    standardRef = '',
    disclosureRequirementRef,
    drType,
    view,
  } = useParams();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isDrawerOpen, onOpen: onDrawerOpen, onClose: onDrawerClose } = useDisclosure();
  const { isOpen: isImportOpen, onOpen: onImportOpen, onClose: onImportClose } = useDisclosure();
  const [selectedMetric, setSelectedMetric] = useState<SelectedMetric>();
  const [selectedQuarter, setSelectedQuarter] = useState<TimePeriods>(TimePeriodsEnums.q1);
  const [selectedExcelMetric, setSelectedExcelMetric] = useState<MetricsTableData>();
  const [isOverview, setIsOverview] = useState<boolean>(false);
  const exportMetrics = useExportEsrsMetricTable();
  const toast = useToast();
  const [isChartsView] = useState(false);
  const [rowData, setRowData] = useState<
    MetricsTableData & { sourceData?: AggregatedQualitativeAnswers[number] }
  >();
  const [answerGenerationDetails, setAnswerGenerationDetails] = useState<AnswerGenerationDetails>();

  const navigate = useNavigate();
  const { company } = useCurrentCompany();
  const [updateMetadata] = useUpdateEsrsAssessmentMetaDataMutation();
  const [searchParams] = useSearchParams();
  const urlMetricRef = useMemo(() => searchParams.get('metricRef'), [searchParams]);

  const {
    metricDR,
    metrics,
    filteredMetrics,
    metadata,
    metaDataLoading,
    companyAssessmentId,
    parentAssessmentId,
    hasInputSection,
    companyLevelReportingUnitId,
    esrsAssessment,
    isGroup,
    dataLoading,
  } = useGetAggregatedMetricsData(withAssociation);

  const {
    aggregatedMetrics,
    qualitativeMetrics: aggregatedQualitativeMetrics,
    aggregatedQualitativeMetricsAnswers,
    aggregationQuantitativeMetrics,
    loading,
  } = useGetAggregatedMetrics(esrsAssessmentId, metrics ?? [], standardRef, companyAssessmentId);

  const { title = '', reference = '', description = '' } = metricDR || {};
  const isMetricDisclosure = useMemo(() => drType === DREnums.metric, [drType]);
  const [numberOfRows, setNumberOfRows] = useState<Record<string, number>>({});
  const isOnlyNarrative = useMemo(
    () => filteredMetrics.every((metric) => metric.metricType === QuestionType_Enum_.LongText_),
    [filteredMetrics]
  );
  const isActionOrPolicy = useMemo(
    () => drType === DREnums.action || drType === DREnums.policy,
    [drType]
  );

  const isTarget = useMemo(() => drType === DREnums.target, [drType]);

  //Chart view
  // useEffect(() => {
  //   if (metadata?.isChartView && isMetricDisclosure) setIsChartsView(metadata?.isChartView);
  // }, [metadata, isMetricDisclosure]);
  useEffect(() => {
    if (isChartsView !== metadata?.isChartView && !metaDataLoading) {
      updateMetadata({
        variables: {
          id: esrsAssessmentId,
          metadata: { ...(metadata ?? {}), isChartView: isChartsView },
        },
        refetchQueries: [GetEsrsAssessmentMetadataDocument_],
      });
    }
  }, [isChartsView]);

  useEffect(() => {
    setRowData(undefined);
  }, [disclosureRequirementRef]);

  const summaryQualitativeMetrics = useMemo(
    () =>
      isGroup
        ? aggregatedQualitativeMetrics.filter(
            (metric) =>
              metric.materialMetrics.find((mm) => mm.materialStandardId === companyAssessmentId)
                ?.dataCollection === DataCollectionLevel.subsidiaries
          ) ?? []
        : aggregatedQualitativeMetrics.filter(
            (metric) =>
              metric.materialMetrics.find((mm) => mm.materialStandardId === companyAssessmentId)
                ?.dataCollection === DataCollectionLevel.reportingUnits
          ) ?? [],
    [aggregatedQualitativeMetrics, companyAssessmentId, isGroup]
  );

  // Data Input - Overview Selection
  const isSomeMetricsOnSubsidiaryLevel = useMemo(
    () =>
      isGroup &&
      aggregationQuantitativeMetrics.some(
        (metric) =>
          metric.materialMetrics.find((mm) => mm.materialStandardId === companyAssessmentId)
            ?.dataCollection === DataCollectionLevel.subsidiaries
      ),
    [isGroup, aggregationQuantitativeMetrics, companyAssessmentId]
  );
  useEffect(() => {
    if (isOnlyNarrative) setIsOverview(false);
  }, [isOnlyNarrative]);

  useEffect(() => {
    if (isOverview) {
      if (drType !== DREnums.metric && !isSomeMetricsOnSubsidiaryLevel) {
        setIsOverview(false);
      } else
        client.refetchQueries({
          include: [
            GetAnswersForMetricsOnCompanyLevelDocument_,
            ...(isGroup ? [GetAnswersForMetricsOnGroupLevelDocument_] : []),
          ],
        });
    }
  }, [isOverview, drType, isSomeMetricsOnSubsidiaryLevel]);
  const handleViewChange = (isView: boolean) => {
    const section = isView ? MetricViewEnums.overview : MetricViewEnums.dataInput;
    navigate(
      `/${companyId}/esrs/${esrsAssessmentId}/standard/${standardRef}/disclosure-requirement/${drType}/${disclosureRequirementRef}/${section}`
    );
    setIsOverview(isView);
  };
  useEffect(() => {
    const isView = view === MetricViewEnums.dataInput ? false : true;
    const isRouteNotMatchingState = isView !== isOverview;
    const isMetricDisclosureWithNoInput =
      !hasInputSection && !summaryQualitativeMetrics?.length && isMetricDisclosure;
    if (isRouteNotMatchingState) {
      if (view === MetricViewEnums.dataInput) setIsOverview(false);
      else setIsOverview(true);
    }
    if (isMetricDisclosureWithNoInput) setIsOverview(true);
  }, [view, hasInputSection, isMetricDisclosure]);

  // Quarter selection
  const areMetricsYearly = useMemo(
    () =>
      aggregatedMetrics.every(
        ({ metric }) => metric.materialMetrics[0]?.frequency === FrequencyEnums.yearly
      ) &&
      filteredMetrics.every(
        (metric) =>
          metric.materialMetrics.find((mm) => mm.materialStandardId === companyAssessmentId)
            ?.frequency === FrequencyEnums.yearly
      ),
    [aggregatedMetrics, filteredMetrics]
  );
  const areMetricsQuarterly = useMemo(
    () =>
      aggregatedMetrics.every(
        ({ metric }) => metric.materialMetrics[0]?.frequency === FrequencyEnums.quarterly
      ) &&
      filteredMetrics.every(
        (metric) =>
          metric.materialMetrics.find((mm) => mm.materialStandardId === companyAssessmentId)
            ?.frequency === FrequencyEnums.quarterly
      ),
    [aggregatedMetrics, filteredMetrics]
  );
  useEffect(() => {
    if (areMetricsYearly) setSelectedQuarter('Year');
    else setSelectedQuarter(TimePeriodsEnums.q1);
  }, [areMetricsYearly]);

  const [isSelectedMetricYearly, isSelectedMetricQuarterly] = useMemo(() => {
    const materialMetric = selectedExcelMetric?.metric.materialMetrics.find(
      (m) => m.materialStandardId === companyAssessmentId
    );
    return [
      materialMetric?.frequency === FrequencyEnums.yearly ?? false,
      materialMetric?.frequency === FrequencyEnums.quarterly ?? false,
    ];
  }, [selectedExcelMetric]);

  // Tables data
  const nestedMetrics = useMemo(
    () =>
      filteredMetrics
        .filter((metric) => metric.metricType === QuestionType_Enum_.Decimal_)
        .map((metric) => {
          const materialStandardId = isMetricStateMaterial(
            metric.materialMetrics.find((mm) => mm.materialStandardId === companyAssessmentId)
              ?.isMaterial
          )
            ? companyAssessmentId
            : parentAssessmentId;
          return getNestedRows(metric, materialStandardId);
        }),
    [filteredMetrics, companyAssessmentId, parentAssessmentId]
  );

  const allFilteredMetrics = useMemo(() => {
    const metricArray: string[] = [];
    const filterMetrics = (metric: MetricsTableData, hidden?: boolean): MetricsTableData => {
      if (metric.subRows) {
        if (metricArray.includes(metric.metric.reference) && metric.parentMetric) {
          return {
            ...metric,
            hidden: hidden,
            subRows: [
              {
                metric: metric.metric,
                referenceToSource: metric.metric?.reference,
                hidden: hidden,
                subRows: metric.subRows.map((subrow) => ({
                  ...subrow,
                  hidden: true,
                  subRows: subrow?.subRows?.map((row) => filterMetrics(row, true)),
                })),
              },
            ],
          };
        }
        metricArray.push(metric.metric.reference);
        const subRows = metric?.subRows?.map((row) => filterMetrics(row, hidden));
        if (subRows) {
          return { ...metric, subRows: subRows, hidden: hidden };
        }
      }
      return { ...metric, hidden: hidden };
    };
    return [...nestedMetrics.map((metric) => filterMetrics(metric))];
  }, [nestedMetrics]);

  const qualitativeMetrics = useMemo(
    () =>
      filteredMetrics?.filter(
        (metric) =>
          metric.metricType === QuestionType_Enum_.LongText_ ||
          metric.metricType === QuestionType_Enum_.YesNo_ ||
          metric.metricType === QuestionType_Enum_.SingleChoice_ ||
          metric.metricType === QuestionType_Enum_.MultipleChoice_
      ),
    [filteredMetrics]
  );

  const allAggregatedMetrics = useMemo(
    () => getNestedMetrics(aggregatedMetrics),
    [aggregatedMetrics]
  );

  const showTableFilterBar = useMemo(() => {
    const hasDataOnInputLevel = hasInputSection || !!summaryQualitativeMetrics?.length;
    const enableOverviewSwitchForNonMetricDrs = !isMetricDisclosure
      ? isSomeMetricsOnSubsidiaryLevel
      : true;
    return enableOverviewSwitchForNonMetricDrs && hasDataOnInputLevel;
  }, [
    hasInputSection,
    aggregatedQualitativeMetrics,
    isMetricDisclosure,
    isSomeMetricsOnSubsidiaryLevel,
    summaryQualitativeMetrics,
  ]);

  //Scroll
  useEffect(() => {
    setTimeout(() => {
      const element = document.getElementById(urlMetricRef ?? '');
      if (element && urlMetricRef === element.id) {
        setTimeout(
          () => {
            element.scrollIntoView({
              behavior: 'smooth',
              block: 'center',
              inline: 'center',
            });
          },
          isOverview ? 0 : 300
        );
      }
    }, 500);
  }, [aggregatedMetrics, filteredMetrics, urlMetricRef, allFilteredMetrics]);

  const toggleShowMore = (category: string) => {
    setNumberOfRows((prevStates) => ({
      ...prevStates,
      [category]: (prevStates[category] || 5) + 10,
    }));
  };

  const getTotalRows = (data: MetricsTableData[]): number => {
    let totalRows = data.filter((row) => !row.hidden).length;
    for (const row of data) {
      if (row.subRows && row.subRows.length > 0) {
        totalRows += getTotalRows(row.subRows);
      }
    }
    return totalRows;
  };

  const isLoading = useMemo(() => loading || dataLoading, [loading, dataLoading]);

  if (isLoading) return <Loader />;
  return (
    <HStack w="100%" alignItems="start" spacing="16px">
      <VStack spacing={isMetricDisclosure ? '32px' : '12px'} alignItems="start" w="100%">
        {isTarget ? (
          <></>
        ) : isActionOrPolicy ? (
          <VStack alignItems="start" w="100%">
            {!!qualitativeMetrics.length && (
              <Typography variant="h3">Disclosure Requirements</Typography>
            )}
            {showTableFilterBar && (
              <MetricsTableFilters
                isOverview={isOverview}
                setIsOverview={handleViewChange}
                selectedQuarter={selectedQuarter}
                setSelectedQuarter={setSelectedQuarter}
                areMetricsQuarterly={areMetricsQuarterly}
                areMetricsYearly={areMetricsYearly}
              />
            )}
            {extraHeaderContent}
          </VStack>
        ) : (
          <VStack spacing="8px" alignItems="start" w="100%">
            <HStack w="100%" justifyContent="space-between">
              <HStack spacing="6px" alignItems="start">
                <Typography variant="bodyLarge" as="span" color="text.hint">
                  {reference}{' '}
                  <Typography variant="h3" as="span">
                    {title}
                  </Typography>
                </Typography>

                <IconButton
                  aria-label="learn more"
                  variant="ghost"
                  icon={<HelpIcon />}
                  size="sm"
                  onClick={() => {
                    setSelectedMetric({
                      reference: reference,
                      title: title,
                      description: description ?? '',
                      isDisclosure: true,
                    });
                    onDrawerOpen();
                  }}
                />
              </HStack>
              {/* {!isOnlyNarrative && (
                <TableViewSwitcher setIsChartsView={setIsChartsView} isChartsView={isChartsView} />
              )} */}
            </HStack>
            {showTableFilterBar && (
              <MetricsTableFilters
                isOverview={isOverview}
                setIsOverview={handleViewChange}
                selectedQuarter={selectedQuarter}
                setSelectedQuarter={setSelectedQuarter}
                areMetricsQuarterly={areMetricsQuarterly}
                areMetricsYearly={areMetricsYearly}
              />
            )}
            {isOverview && (
              <Box w="100%">
                <Infobox
                  status="info"
                  bgColor="bg.info.muted"
                  title="Aggregated metrics"
                  icon={<SumIcon />}
                  closable={false}
                  w="100%"
                  description={
                    <Typography variant="body" verticalAlign="baseline">
                      {`Some of the metrics here are aggregated from ${
                        isGroup ? 'subsidiaries' : 'business units'
                      }. They are marked
                      with`}
                      <SumIcon color="text.critical" margin={' 0 2px -3px -2px'} />
                      icon. You can click on each metric to see details.
                    </Typography>
                  }
                />
              </Box>
            )}
          </VStack>
        )}

        {isChartsView ? (
          <VStack alignItems="stretch" gap="16px" pb="16px">
            <Grid templateColumns="repeat(3, 1fr)" gap={2} w="100%">
              {allAggregatedMetrics.map((metric, index) => (
                <GridItem w="99%">
                  <VStack
                    key={index}
                    alignItems="start"
                    border="1px solid"
                    borderColor="border.decorative"
                    borderRadius="8px"
                    w="100%"
                    h="100%"
                    pt="8px"
                  >
                    <HStack
                      p="6px 16px"
                      justifyContent="space-between"
                      borderBottom="1px solid"
                      borderColor="border.decorative"
                      width="100%"
                    >
                      <TruncatableText variant="bodyStrong" text={metric.metric.title} />
                      <IconButton
                        aria-label="maximize"
                        variant="ghost"
                        icon={<MaximizeIcon />}
                        size="sm"
                        onClick={() =>
                          navigate(
                            `/${companyId}/esrs/${esrsAssessmentId}/standard/${standardRef}/disclosure-requirement/metric/${disclosureRequirementRef}/chart/${metric.metric.reference}`
                          )
                        }
                      />
                    </HStack>
                    <GraphCard data={getGraphObject(metric)} height={200} />
                  </VStack>
                </GridItem>
              ))}
            </Grid>
            {qualitativeMetrics.length && !isOverview && (
              <NarrativeMetricsTable
                metrics={qualitativeMetrics}
                selectedQuarter={selectedQuarter}
                esrsAssessmentProjectLeader={esrsAssessment?.projectLeader ?? undefined}
                rowData={rowData}
                setRowData={setRowData}
                isGeneratingAnswers={isGeneratingAnswers}
                companyReportingUnit={companyLevelReportingUnitId}
                withBorder={withBorder}
              />
            )}
          </VStack>
        ) : (
          <VStack
            spacing="40px"
            alignItems="stretch"
            w="100%"
            minH="fit-content"
            mb={isTarget ? '' : '40px'}
          >
            {isOverview ? (
              <>
                {aggregatedMetrics?.map((metric) => (
                  <VStack
                    key={metric.metric.reference}
                    alignItems="start"
                    id={metric.metric.reference}
                    spacing="6px"
                  >
                    <LinkAnchor
                      id={metric.metric.reference}
                      text={`${metric.metric.title} ${(metric.tagType ?? '')?.toLowerCase()}`}
                      marginLeft="-20px"
                    />
                    <AggregatedMetricsTable
                      metrics={[metric]}
                      selectedQuarter={selectedQuarter}
                      setSelectedMetric={setSelectedMetric}
                      onDrawerOpen={onDrawerOpen}
                      isOverview={isOverview}
                      areMetricsYearly={areMetricsYearly}
                      isGroup={isGroup}
                      currency={company?.currency ?? ''}
                      companyAssessmentId={companyAssessmentId}
                    />
                  </VStack>
                ))}
                {!!aggregatedQualitativeMetrics?.length && (
                  <Skeleton isLoaded={isMetricDisclosure ? true : !isLoading}>
                    <VStack alignItems="start" spacing="6px">
                      {!isTarget && (
                        <LinkAnchor
                          id={aggregatedQualitativeMetrics?.[0].reference ?? ''}
                          text="Narrative data points"
                          marginLeft="-20px"
                        />
                      )}
                      <NarrativeMetricsTable
                        metrics={aggregatedQualitativeMetrics ?? []}
                        selectedQuarter={TimePeriodsEnums.year}
                        esrsAssessmentProjectLeader={esrsAssessment?.projectLeader ?? undefined}
                        rowData={rowData}
                        setRowData={setRowData}
                        answersData={aggregatedQualitativeMetricsAnswers}
                        companyReportingUnit={companyLevelReportingUnitId}
                        isReadOnly={true}
                        withBorder={withBorder}
                      />
                    </VStack>
                  </Skeleton>
                )}
              </>
            ) : (
              <>
                {isMetricDisclosure && (
                  <MetricsAISection
                    reportingUnitId={companyLevelReportingUnitId}
                    materialStandardId={companyAssessmentId}
                    disclosureRequirementRef={disclosureRequirementRef ?? ''}
                    allFilteredMetrics={allFilteredMetrics}
                    areMetricsYearly={areMetricsYearly}
                    isCompanyLevel={hasInputSection}
                    isGroup={isGroup}
                    isOverview={true}
                    onDrawerOpen={onDrawerOpen}
                    rowData={rowData}
                    setRowData={setRowData}
                    onOpen={onOpen}
                    selectedQuarter={selectedQuarter}
                    selectedMetric={selectedMetric}
                    setSelectedMetric={setSelectedMetric}
                    esrsAssessmentProjectLeader={esrsAssessment?.projectLeader ?? undefined}
                    aggregatedQualitativeMetricsAnswers={aggregatedQualitativeMetricsAnswers}
                    qualitativeMetrics={qualitativeMetrics}
                    summaryQualitativeMetrics={summaryQualitativeMetrics}
                  />
                )}

                {allFilteredMetrics.map((metric) => {
                  const rowsCount = getTotalRows([metric]);
                  const remainingCount =
                    rowsCount - (numberOfRows[metric.metric?.reference ?? ''] ?? 5) > 10
                      ? 10
                      : rowsCount - (numberOfRows[metric.metric?.reference ?? ''] ?? 5);
                  const showMoreButton = remainingCount > 0;
                  const isTableMetricYearly = metric?.metric.materialMetrics.every(
                    (m) => m.frequency === FrequencyEnums.yearly
                  );
                  const isTableMetricQuarterly = metric?.metric.materialMetrics.every(
                    (m) => m.frequency === FrequencyEnums.quarterly
                  );
                  return (
                    <Skeleton
                      key={metric.metric.reference}
                      isLoaded={isMetricDisclosure ? true : !isLoading}
                    >
                      <VStack alignItems="start" id={metric.metric.reference} spacing="6px">
                        <HStack justifyContent="space-between" w="100%">
                          <LinkAnchor
                            id={metric.metric.reference}
                            text={`${metric.metric.title} ${(metric.tagType ?? '')?.toLowerCase()}`}
                            marginLeft="-20px"
                          />
                          <HStack spacing="4px">
                            <Button
                              variant="ghost"
                              leftIcon={<UploadIcon />}
                              onClick={() => {
                                onImportOpen();
                                setSelectedExcelMetric(metric);
                              }}
                            >
                              Import
                            </Button>
                            <Menu
                              size="md"
                              sections={[
                                {
                                  actions: [
                                    {
                                      id: 'download',
                                      title: 'Download Excel file',
                                      variant: 'ghost',
                                      onClick: () => {
                                        toast({
                                          text: 'Downloading file...',
                                          closable: true,
                                          duration: null,
                                        });
                                        if (metric) {
                                          exportMetrics(
                                            [metric],
                                            disclosureRequirementRef ?? '',
                                            company?.name ?? 'Company',
                                            isTableMetricQuarterly,
                                            isTableMetricYearly,
                                            companyAssessmentId,
                                            companyLevelReportingUnitId
                                          );
                                        }
                                      },
                                      leftElement: <DownloadIcon />,
                                    },
                                  ],
                                },
                              ]}
                            />
                          </HStack>
                        </HStack>
                        <MetricsInputTable
                          metrics={[metric]}
                          selectedQuarter={selectedQuarter}
                          onOpen={onOpen}
                          setSelectedMetric={setSelectedMetric}
                          onDrawerOpen={onDrawerOpen}
                          isOverview={isOverview}
                          companyStandardId={companyAssessmentId}
                          areMetricsYearly={areMetricsYearly}
                          companyReportingUnit={companyLevelReportingUnitId}
                          currency={company?.currency ?? ''}
                          isCompanyLevel={hasInputSection}
                          isGroup={isGroup}
                          esrsAssessmentProjectLeader={esrsAssessment?.projectLeader ?? undefined}
                          rowData={rowData}
                          setRowData={setRowData}
                          pageSize={numberOfRows[metric.metric.reference]}
                          filteredMetrics={allFilteredMetrics}
                          setNumberOfRows={setNumberOfRows}
                          isMetricDr={isMetricDisclosure}
                        />
                        {showMoreButton && (
                          <Button
                            mt="2px"
                            variant="ghost"
                            size="sm"
                            onClick={() => toggleShowMore(metric.metric.reference)}
                          >
                            <HStack spacing="8px">
                              <IconArrowNarrowDown size="16px" />
                              <Typography variant="bodyStrong" size="sm">
                                Load {remainingCount} more
                              </Typography>
                            </HStack>
                          </Button>
                        )}
                      </VStack>
                    </Skeleton>
                  );
                })}

                {qualitativeMetrics?.length !== 0 && (
                  <Skeleton isLoaded={isMetricDisclosure ? true : !isLoading}>
                    <VStack alignItems="start" spacing="6px">
                      {!isTarget && (
                        <LinkAnchor
                          id={qualitativeMetrics?.[0].reference}
                          text="Narrative data points"
                          marginLeft="-20px"
                        />
                      )}
                      <NarrativeMetricsTable
                        metrics={qualitativeMetrics}
                        selectedQuarter={selectedQuarter}
                        esrsAssessmentProjectLeader={esrsAssessment?.projectLeader ?? undefined}
                        rowData={rowData}
                        setRowData={setRowData}
                        isGeneratingAnswers={isGeneratingAnswers}
                        companyReportingUnit={companyLevelReportingUnitId}
                        withBorder={withBorder}
                      />
                    </VStack>
                  </Skeleton>
                )}
                {summaryQualitativeMetrics?.length !== 0 && (
                  <Skeleton isLoaded={isMetricDisclosure ? true : !isLoading}>
                    <VStack alignItems="start" spacing="6px">
                      {!isTarget && (
                        <LinkAnchor
                          id={summaryQualitativeMetrics?.[0].reference ?? ''}
                          text="Summarized narrative data points"
                          marginLeft="-20px"
                        />
                      )}
                      <NarrativeMetricsTable
                        metrics={summaryQualitativeMetrics}
                        selectedQuarter={TimePeriodsEnums.year}
                        esrsAssessmentProjectLeader={esrsAssessment?.projectLeader ?? undefined}
                        rowData={rowData}
                        setRowData={setRowData}
                        answersData={aggregatedQualitativeMetricsAnswers}
                        companyReportingUnit={companyLevelReportingUnitId}
                        withBorder={withBorder}
                        answerGenerationDetails={answerGenerationDetails}
                      />
                    </VStack>
                  </Skeleton>
                )}
              </>
            )}
          </VStack>
        )}

        <LearnMoreDrawer
          header={selectedMetric?.isDisclosure ? selectedMetric.title : undefined}
          isOpen={isDrawerOpen}
          onClose={onDrawerClose}
          description={selectedMetric?.description ?? ''}
          customHeader={
            !selectedMetric?.isDisclosure ? (
              <MetricLearnMoreHeader
                metricRef={selectedMetric?.reference ?? ''}
                tags={selectedMetric?.tags ?? []}
              />
            ) : undefined
          }
        />
        {!isOverview && (
          <OptOutModal
            isOpen={isOpen}
            onClose={onClose}
            assessmentId={esrsAssessmentId}
            reportingUnitId={companyLevelReportingUnitId}
            metricRef={selectedMetric?.reference ?? ''}
            isCompany={false}
          />
        )}
      </VStack>
      {!!rowData && (
        <MetricSidebar
          row={rowData}
          metric={rowData?.metric ?? ({} as MetricsTableData['metric'])}
          materialStandardId={companyAssessmentId}
          companyReportingUnit={companyLevelReportingUnitId}
          selectedMetric={selectedMetric}
          esrsAssessmentProjectLeader={esrsAssessment?.projectLeader ?? undefined}
          setSelectedMetric={setSelectedMetric}
          onMenuOpen={onOpen}
          selectedQuarter={selectedQuarter}
          setRowData={setRowData}
          isGroup={isGroup}
          isMetricDr={isMetricDisclosure}
          setAnswerGenerationDetails={setAnswerGenerationDetails}
          companyCurrency={company?.currency ?? ''}
        />
      )}

      <Suspense>
        {selectedExcelMetric && (
          <MetricsExcelUploadModal
            isOpen={isImportOpen}
            onClose={onImportClose}
            metrics={[selectedExcelMetric]}
            disclosureRequirement={disclosureRequirementRef ?? ''}
            businessUnit={company?.name ?? 'Company'}
            areTableMetricsQuarterly={isSelectedMetricQuarterly}
            areTableMetricsYearly={isSelectedMetricYearly}
            companyStandardId={companyAssessmentId}
            companyReportingUnitId={companyLevelReportingUnitId}
          />
        )}
      </Suspense>
    </HStack>
  );
};
