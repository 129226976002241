import {
  EsrsAssessmentActionsQuery_,
  useEsrsAssessmentQuery,
  useEsrsAssessmentTargetsQuery,
  useGetEsrsCategoriesQuery,
  useGetMaterialStandardsForSubsidiariesQuery,
} from 'models';
import { useMemo } from 'react';
import { MaterialMetric } from '../MaterialityAssessment';
import { Action } from '../DisclosureRequirements/Actions/Actions.hooks';
import { orderCategories } from 'containers/Esrs/utils';

export type MaterialStandard = {
  standardRef: string;
  standardName: string;
  description?: string | null;
  isMaterialForGroup: boolean | undefined | null;
  isMaterialForSubsidiary: boolean;
  isMaterialityMatchingSubsidiaries?: boolean;
  materialMetrics?: MaterialMetric[];
  parentMaterialMetrics?: MaterialMetric[];
};

export type EsrsCategoryMap = {
  categoryName: string;
  categoryRef: string;
  materialStandards: MaterialStandard[];
};

export type StandardsOverviewData = {
  categories: EsrsCategoryMap[];
  loading: boolean;
};

export const useGetGroupStandardsOverview = (filter: string, esrsAssessmentId?: string) => {
  const { data, loading: loadingAssessment } = useEsrsAssessmentQuery({
    variables: { esrsAssessmentId },
    skip: !esrsAssessmentId,
  });

  const {
    data: subsidiaryMaterialityAssessmentData,
    loading: loadingSubsidiaryMaterialityAssessment,
  } = useGetMaterialStandardsForSubsidiariesQuery({
    variables: { groupAssessmentId: esrsAssessmentId },
    skip: !esrsAssessmentId,
  });

  const { data: esrsCategoriesData, loading: loadingCategories } = useGetEsrsCategoriesQuery();

  const categories = useMemo(() => {
    return orderCategories(esrsCategoriesData?.EsrsCategory ?? []);
  }, [esrsCategoriesData]);

  const groupMaterialityMap: EsrsCategoryMap[] = useMemo(() => {
    const groupMaterialityAssessments = data?.esrsAssessment?.materialStandards;
    const subsidiaryMaterialityAssessments =
      subsidiaryMaterialityAssessmentData?.EsrsAssessment_by_pk?.subsidiaryAssessments;

    const allSubsidiaryAssessments =
      subsidiaryMaterialityAssessmentData?.EsrsAssessment_by_pk?.allAssessments;

    if (groupMaterialityAssessments && subsidiaryMaterialityAssessments) {
      return categories.map((category) => {
        return {
          categoryName: category.title,
          categoryRef: category.reference,
          materialStandards:
            category.standards
              ?.map((standard) => {
                return {
                  standardRef: standard.reference,
                  standardName: standard.title,
                  description: standard.description,
                  isMaterialForGroup:
                    groupMaterialityAssessments.find((materialStandard) => {
                      return materialStandard.standardRef === standard.reference;
                    })?.isMaterial === true,
                  isMaterialForSubsidiary: !!subsidiaryMaterialityAssessments?.find(
                    (subsidiaryAssessment) => {
                      return subsidiaryAssessment.materialStandards.find(
                        (materialStandard) => materialStandard.standardRef === standard.reference
                      )?.isMaterial;
                    }
                  ),
                  isMaterialityMatchingSubsidiaries: allSubsidiaryAssessments
                    ?.map((sub) =>
                      sub.materialStandards.find((ma) => ma.standardRef === standard.reference)
                    )
                    .every(
                      (subsidiaryAssessment) =>
                        subsidiaryAssessment?.isMaterial ===
                        groupMaterialityAssessments.find((materialStandard) => {
                          return materialStandard.standardRef === standard.reference;
                        })?.isMaterial
                    ),
                };
              })
              .filter((standard) => {
                if (filter === 'all') {
                  return (
                    standard.isMaterialForGroup == true || standard.isMaterialForSubsidiary == true
                  );
                } else if (filter === 'material') {
                  return standard.isMaterialForGroup == true;
                } else if (filter === 'not-material') {
                  return (
                    standard.isMaterialForSubsidiary == true && standard.isMaterialForGroup == false
                  );
                }
              }) ?? [],
        };
      });
    } else return [];
  }, [data, subsidiaryMaterialityAssessmentData, esrsCategoriesData, filter]);

  const loading = useMemo(() => {
    return loadingAssessment || loadingCategories || loadingSubsidiaryMaterialityAssessment;
  }, [loadingAssessment, loadingCategories, loadingSubsidiaryMaterialityAssessment]);

  const groupStandardsOverviewData: StandardsOverviewData = {
    categories: groupMaterialityMap,
    loading,
  };

  return groupStandardsOverviewData;
};

export const useEsrsTargets = (esrsAssessmentId?: string) => {
  const { data, loading } = useEsrsAssessmentTargetsQuery({
    variables: {
      esrsAssessmentId,
    },
    skip: !esrsAssessmentId,
  });

  const hasParentAssessment = useMemo(
    () => !!data?.esrs_Target[0]?.materialStandard?.esrsAssessment?.parentAssessmentId,
    [data]
  );

  const esrsTargets = useMemo(() => {
    return data?.esrs_Target.map((esrsTarget) => {
      const baseline = esrsTarget.keyResults.find((kr) => kr.reportingUnitId === null);
      return {
        ...esrsTarget,
        category: {
          reference: esrsTarget.disclosureRequirement.group.standard.category.reference,
          title: esrsTarget.disclosureRequirement.group.standard.category.title,
        },
        standard: {
          reference: esrsTarget.disclosureRequirement.group.standard.reference,
          title: esrsTarget.disclosureRequirement.group.standard.title,
        },
        isSubOrParent: !esrsTarget.materialStandard.esrsAssessment.company.isGroupOwner,
        companyName: esrsTarget.materialStandard.esrsAssessment.company.name,
        companyId: esrsTarget.materialStandard.esrsAssessment.company.id,
        baseline: Number(baseline?.baseline),
        baseYear: esrsTarget.keyResults?.[0]?.baseYear,
        actions: esrsTarget.actionTargets.map((action: any) => ({
          title: action.action.title,
          id: action.actionId,
        })),
        numberOfActions: esrsTarget?.actionTargets.length,
        targetId: esrsTarget.id,
        milestones: esrsTarget.milestones.map((ml: any) => ({
          year: ml.year,
          value: ml.milestoneResults[0]?.value,
        })),
        owner: esrsTarget.owner,
        title: esrsTarget.name ?? '',
        materialStandardId: esrsTarget.materialStandard.id,
        parentTargetId: esrsTarget.parentTargetId,
        isRequired: esrsTarget.isRequired,
        isFlagged: baseline?.isFlagged ?? false,
        esrsAssessment: esrsTarget.materialStandard.assessmentId,
        metricRef: esrsTarget.metricRef,
        metricTitle: esrsTarget.metric?.title,
      };
    });
  }, [data]);

  return { esrsTargets, loading, hasParentAssessment };
};

export const useEsrsActions = (actionsData?: EsrsAssessmentActionsQuery_) => {
  const actions = useMemo(() => {
    const mapTargets = (action: Action) => {
      return action.actionTargets.map((target: any) => ({
        title: target.target.name ?? '',
        id: target.target.id,
      }));
    };
    return actionsData?.esrs_Actions.map((esrsAction) => {
      return {
        ...esrsAction,
        category: {
          reference: esrsAction.disclosureRequirement.group.standard.category.reference,
          title: esrsAction.disclosureRequirement.group.standard.category.title,
        },
        standardRef: esrsAction.disclosureRequirement.group.standard.reference,
        isSubOrParent: !esrsAction.materialStandard.esrsAssessment.company.isGroupOwner,
        targets: mapTargets(esrsAction),
        numberOfTargets: esrsAction.actionTargets.length,
        companyName: esrsAction.materialStandard.esrsAssessment.company.name,
        actionId: esrsAction.id,
        owner: esrsAction.owner,
        action: esrsAction,
      };
    });
  }, [actionsData]);
  return actions;
};
