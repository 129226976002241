import { VStack, HStack } from '@chakra-ui/react';
import { PortfolioIllustration, Typography } from 'Tokens';
import { Portfolio, PortfolioFieldsFragment_ } from 'models';
import { TaxonomyScoreChart } from 'Organisms';
import { Link, useNavigate } from 'react-router-dom';
import { scoreSections } from 'utils/financials';
import { useTranslation } from 'utils/translation';
import { useMemo } from 'react';
import {
  getAggregatePortfolioScores,
  aggregatePortfolioProgress,
  getAggregatePortfolioSubstantialContribution,
} from 'utils/scores/portfolio';
import { Menu } from 'Molecules/Menu';
import { EditIcon } from 'Tokens/Icons/Function';
import { ReportPeriods } from 'Features/PortfolioView';

export const PortfolioNoData = ({
  year,
  portfolioName,
}: {
  year: number;
  portfolioName: string;
}) => {
  const { t } = useTranslation(['common']);
  return (
    <HStack
      justifyContent="center"
      alignItems="start"
      pt="40px"
      spacing="24px"
      width="100%"
      height="178px"
    >
      <VStack>
        <PortfolioIllustration height="72px" width="72px" />
      </VStack>
      <VStack alignItems="start" width="400px">
        <Typography variant="bodyStrong">{t('portfolio:noData.title', { year })}</Typography>
        <Typography variant="body">
          {t('portfolio:noData.description', { portfolioName })}
        </Typography>
      </VStack>
    </HStack>
  );
};

export const PortfolioSummary = ({
  portfolioCompanies,
  reportPeriod,
}: {
  portfolioCompanies: Portfolio['portfolioCompanies'];
  reportPeriod: ReportPeriods;
}) => {
  const scoresAggregate = useMemo(() => {
    return getAggregatePortfolioScores(portfolioCompanies, reportPeriod);
  }, [portfolioCompanies]);

  const totalProgress = useMemo(() => {
    return aggregatePortfolioProgress(portfolioCompanies, reportPeriod);
  }, [portfolioCompanies]);

  const scoresSC = useMemo(() => {
    return getAggregatePortfolioSubstantialContribution(portfolioCompanies, reportPeriod);
  }, [portfolioCompanies]);

  return (
    <HStack justifyContent="space-evenly" spacing="12px" width="100%" height="100%">
      {scoreSections.map((section) => {
        return (
          <TaxonomyScoreChart
            title={section}
            size="lg"
            progress={totalProgress}
            score={scoresAggregate[section]}
            key={section}
            withDetails={true}
            scData={scoresSC}
          />
        );
      })}
    </HStack>
  );
};

const PortfolioItem = ({
  portfolio,
  onEdit,
  reportPeriod,
  year,
  isAuditor = false,
}: {
  portfolio: Portfolio;
  onEdit: (p: Portfolio) => void;
  reportPeriod: ReportPeriods;
  year?: number;
  isAuditor?: boolean;
}) => {
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const portfolioUrl = `${portfolio.id}/${year}`;
  const latestPortfolioYear = useMemo(() => {
    const allYears = [...new Set(portfolio.portfolioCompanies.map((c) => c?.year))].sort(
      (a, b) => (a ?? 0) - (b ?? 0)
    );
    return allYears[allYears.length - 1];
  }, [portfolio, year]);

  const portfolioCompaniesForCurrentYear: PortfolioFieldsFragment_['portfolioCompanies'] =
    useMemo(() => {
      const filterYear = year ?? latestPortfolioYear ?? new Date().getFullYear();
      return portfolio.portfolioCompanies.filter((c) => c?.year === filterYear);
    }, [year, latestPortfolioYear, portfolio]);

  return (
    <VStack
      borderRadius="8px"
      _hover={{ bg: 'bg.hover' }}
      _focusWithin={{ bg: 'bg.hover' }}
      borderColor="border.decorative"
      borderWidth="1px"
      borderStyle="solid"
      onClick={() => navigate(portfolioUrl)}
      cursor="pointer"
      alignItems="stretch"
      width="100%"
      p="16px"
      spacing="0px"
    >
      <HStack width="100%" justifyContent="space-between">
        <Link to={portfolioUrl}>
          <Typography variant="h2">{portfolio.name}</Typography>
        </Link>
        <Menu
          sections={[
            {
              actions: [
                {
                  id: 'edit',
                  title: t('common:actions.edit'),
                  onClick: () => onEdit(portfolio),
                  leftElement: <EditIcon color="inherit" />,
                  isDisabled: isAuditor,
                },
              ],
            },
          ]}
        />
      </HStack>
      <HStack>
        <Typography marginBottom="12px" variant="detail" maxW="640px">
          {portfolio.description}
        </Typography>
      </HStack>
      {!!portfolioCompaniesForCurrentYear.length ? (
        <PortfolioSummary
          portfolioCompanies={portfolioCompaniesForCurrentYear}
          reportPeriod={reportPeriod}
        />
      ) : (
        <PortfolioNoData
          year={year ?? latestPortfolioYear ?? new Date().getFullYear()}
          portfolioName={portfolio.name}
        />
      )}
    </VStack>
  );
};

export const PortfoliosList = ({
  portfolios,
  onEdit,
  year,
  isAuditor,
}: {
  portfolios: PortfolioFieldsFragment_[];
  onEdit: (p: Portfolio) => void;
  year: number;
  isAuditor?: boolean;
}) => {
  return (
    <VStack paddingY="16px" spacing="16px" alignItems="stretch" width="100%">
      {portfolios.map((p) => (
        <PortfolioItem
          portfolio={p}
          key={p.id}
          onEdit={onEdit}
          reportPeriod={ReportPeriods.year}
          year={year}
          isAuditor={isAuditor}
        />
      ))}
    </VStack>
  );
};
