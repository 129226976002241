import { Box, HStack, VStack, useDisclosure } from '@chakra-ui/react';
import { Alert, Button, FormField, IconButton } from 'Atoms';
import { AvatarGroup, ContentHeader, ContentLayout, Select } from 'Molecules';
import { Typography } from 'Tokens';
import { AddIcon, DeleteIcon, EditIcon } from 'Tokens/Icons/Function';
import {
  EsrsAssessmentDocument_,
  useDeleteReportingUnitMutation,
  useEsrsAssessmentQuery,
  useRemoveEsrsSubsidiaryMutation,
  useUpdateAssessmentCompanyProjectLeaderMutation,
  useUpdateAssessmentCompanySizeMutation,
} from 'models';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AddSubsidaryModal } from './AddSubsidaryModal';
import { useCurrentCompany } from 'utils/hooks';
import { AddBusinessUnitEsrsModal } from './AddBusinessUnitEsrsModal';
import { GroupIcon } from 'Tokens/Icons/Data';
import { Menu } from 'Molecules/Menu';
import { ProjectLeaderSelect } from './ProjectLeaderSelect';
import { User } from '@nhost/react';
import {
  InviteMembersModal,
  TeamMembersFilterOption,
  useTeamMembers,
} from 'containers/TeamMembers';
import { UserAvatar } from 'Organisms';
import { CompanyFields } from './EsrsConfig.hooks';

export type BusinessUnit = {
  id: string;
  name: string;
  projectLeader?: Partial<User>;
  lineageId: string;
};

const COMPANY_SIZE_LABELS: { [key: string]: string } = {
  small: '0-250',
  medium: '250-750',
  big: '750+',
};

enum CompanySizeOptions {
  small = 'small',
  medium = 'medium',
  big = 'big',
}

export const EsrsAssessmentConfig = ({
  isOnboarding,
  setNextDisabled,
  nextDisabled,
  showAlertMessage,
}: {
  isOnboarding?: boolean;
  setNextDisabled?: (disabled: boolean) => void;
  nextDisabled?: boolean;
  showAlertMessage?: boolean;
}) => {
  const { esrsAssessmentId } = useParams();
  const company = useCurrentCompany();
  const { members } = useTeamMembers(TeamMembersFilterOption.All);
  const [updateCompanySize] = useUpdateAssessmentCompanySizeMutation();
  const [updateCompanyProjectLeader] = useUpdateAssessmentCompanyProjectLeaderMutation();
  const [removeSubsidiary] = useRemoveEsrsSubsidiaryMutation();
  const [deleteReportingUnit] = useDeleteReportingUnitMutation();
  const [selectedBusinessUnit, setSelectedBusinessUnit] = useState<BusinessUnit>();
  const [selectedSubsidiary, setSelectedSubsidiary] = useState<CompanyFields['company']>();
  const [isEdit, setIsEdit] = useState(false);

  const {
    isOpen: isSubsidiaryModalOpen,
    onOpen: onSubsidiaryModalOpen,
    onClose: onSubsidiaryModalClose,
  } = useDisclosure();
  const {
    isOpen: isBusinessUnitEsrsModalOpen,
    onOpen: onBusinessUnitEsrsModalOpen,
    onClose: onBusinessUnitEsrsModalClose,
  } = useDisclosure();
  const {
    isOpen: isInvitationModalOpen,
    onOpen: onInvitationModalOpen,
    onClose: onInvitationModalClose,
  } = useDisclosure();

  const { data } = useEsrsAssessmentQuery({
    variables: {
      esrsAssessmentId: esrsAssessmentId,
    },
  });

  const subsidaries = useMemo(() => {
    return data?.esrsAssessment?.subsidiaryAssessments?.map((sA) => ({
      assessmentId: sA.id,
      name: sA.company?.name,
      id: sA.company?.id,
      projectLeader: sA.projectLeader,
    }));
  }, [data]);
  const reportingUnits = useMemo(() => {
    return data?.esrsAssessment?.reportingUnits.filter((ru) => !ru.isCompanyLevel);
  }, [data]);

  const projectLeader = useMemo(() => {
    return data?.esrsAssessment?.projectLeader ?? undefined;
  }, [data]);

  const officialMembers = useMemo(
    () => members.filter((member) => !!member.name && !member.disabled),
    [members]
  );

  const isGroupCompany = useMemo(() => company.company?.isGroupOwner ?? false, [company]);

  useEffect(() => {
    const disabled = isGroupCompany && subsidaries?.length === 0;
    if (isOnboarding) setNextDisabled?.(disabled);
  }, [reportingUnits, subsidaries]);

  const [companySize, setCompanySize] = useState<string>(CompanySizeOptions.small);

  useEffect(() => {
    setCompanySize(data?.esrsAssessment?.companySize ?? CompanySizeOptions.small);
  }, [data]);

  const removeSub = (id: number) => {
    removeSubsidiary({
      variables: { childAssessmentId: id },
      refetchQueries: [EsrsAssessmentDocument_],
    });
  };

  const removeReportingUnit = (id: number) => {
    deleteReportingUnit({
      variables: { id },
      refetchQueries: [EsrsAssessmentDocument_],
    });
  };

  const handleEditBusinessUnit = (bu: BusinessUnit) => {
    if (!bu) return;
    setSelectedBusinessUnit(bu);
    onBusinessUnitEsrsModalOpen();
  };

  const handleBusinessUnitEsrsModalClose = () => {
    setSelectedBusinessUnit(undefined);
    onBusinessUnitEsrsModalClose();
  };

  return (
    <ContentLayout
      backButton={isOnboarding ? false : true}
      variant={isOnboarding ? 'inline.nopad' : 'narrow'}
      header={<ContentHeader title="Set company structure" />}
      height={isOnboarding ? 'fit-content' : undefined}
    >
      <VStack spacing="24px" alignItems="flex-start" mt="24px">
        <VStack spacing="12px" alignItems="start">
          <Typography variant="h2">Company details</Typography>
          <FormField label="Company size" id={'companySize'}>
            <Box w="200px">
              <Select<{ value: string; label: string }>
                value={{ label: COMPANY_SIZE_LABELS[companySize] ?? '', value: companySize ?? '' }}
                onChange={(value: any) => {
                  updateCompanySize({
                    variables: {
                      id: esrsAssessmentId,
                      companySize: value.value,
                    },
                  });
                  setCompanySize(value.value);
                }}
                options={[
                  { value: 'small', label: COMPANY_SIZE_LABELS.small },
                  { value: 'medium', label: COMPANY_SIZE_LABELS.medium },
                  { value: 'big', label: COMPANY_SIZE_LABELS.big },
                ]}
                size="md"
              />
            </Box>
          </FormField>
          <FormField label="Project leader" id={'projectLeader'}>
            <Box w="200px">
              <ProjectLeaderSelect
                defaultProjectLeader={projectLeader}
                members={officialMembers}
                onChange={(member) => {
                  updateCompanyProjectLeader({
                    variables: {
                      id: esrsAssessmentId,
                      projectLeaderId: member.id,
                    },
                  });
                }}
              />
            </Box>
          </FormField>
        </VStack>

        <VStack alignItems="start" spacing="10px">
          <VStack alignItems="start" spacing="2px">
            <Typography variant="bodyStrong">Invite team members</Typography>
            <Typography variant="body" color="text.muted">
              They will get access to {isGroupCompany ? 'subsidiaries' : company.company?.name}{' '}
              automatically{' '}
            </Typography>
          </VStack>
          <HStack spacing="8px">
            <AvatarGroup
              size="sm"
              namesOrLogos={members.map((member) => ({
                name: member.name ?? member.email,
                logoUrl: member?.user?.avatarUrl,
              }))}
            />
            <IconButton
              aria-label="Invite team members"
              icon={<AddIcon />}
              variant="ghost"
              size="sm"
              borderRadius="50%"
              onClick={onInvitationModalOpen}
            />
          </HStack>
        </VStack>
        <VStack spacing="12px" alignItems="start" w="100%">
          <Typography variant="h2">
            {isGroupCompany ? 'Structure' : 'Structure (optional)'}
          </Typography>
          {showAlertMessage && nextDisabled && (
            <Alert status="critical" closable={false} title="Add at least one subsidiary" />
          )}
          {(isGroupCompany && subsidaries?.length) ||
          (!isGroupCompany && reportingUnits?.length) ? (
            <VStack alignItems="flex-start" spacing="8px" w="100%">
              {isGroupCompany &&
                subsidaries?.map((s) => {
                  const selectedSub = {
                    ...s,
                    projectLeaderId: s.projectLeader?.id,
                  };
                  return (
                    <HStack
                      key={s.name}
                      bg="bg.interactive"
                      borderRadius="8px"
                      padding="8px 8px 8px 16px"
                      width="100%"
                      justifyContent="space-between"
                    >
                      <Typography variant="body">{s.name}</Typography>
                      <HStack spacing="16px">
                        {s.projectLeader && <UserAvatar user={s.projectLeader} size="sm" />}
                        <Menu
                          sections={[
                            {
                              actions: [
                                {
                                  id: 'edit',
                                  title: 'Edit',
                                  onClick: () => {
                                    setSelectedSubsidiary(selectedSub);
                                    setIsEdit(true);
                                    onSubsidiaryModalOpen();
                                  },
                                  leftElement: <EditIcon color="inherit" />,
                                },
                                {
                                  id: 'delete',
                                  title: 'Remove',
                                  variant: 'destructive',
                                  onClick: () => removeSub(s.assessmentId),
                                  leftElement: <DeleteIcon color="inherit" />,
                                },
                              ],
                            },
                          ]}
                        />
                      </HStack>
                    </HStack>
                  );
                })}
              {!isGroupCompany &&
                reportingUnits?.map((ru) => (
                  <HStack
                    key={ru?.name}
                    bg="bg.interactive"
                    borderRadius="8px"
                    padding="8px 8px 8px 16px"
                    width="100%"
                    justifyContent="space-between"
                  >
                    <Typography variant="body">{ru?.name}</Typography>
                    <HStack spacing="16px">
                      {ru.projectLeader && <UserAvatar user={ru.projectLeader} size="sm" />}
                      <Menu
                        sections={[
                          {
                            actions: [
                              {
                                id: 'edit',
                                title: 'Edit',
                                variant: 'ghost',
                                onClick: () =>
                                  handleEditBusinessUnit({
                                    id: ru.id,
                                    name: ru.name,
                                    projectLeader: ru.projectLeader ?? undefined,
                                    lineageId: ru.lineageId,
                                  }),
                                leftElement: <EditIcon color="inherit" />,
                              },
                              {
                                id: 'delete',
                                title: 'Remove',
                                variant: 'destructive',
                                onClick: () => removeReportingUnit(ru.id),
                                leftElement: <DeleteIcon color="inherit" />,
                              },
                            ],
                          },
                        ]}
                      />
                    </HStack>
                  </HStack>
                ))}
              <Button
                variant="ghost"
                leftIcon={<AddIcon />}
                onClick={isGroupCompany ? onSubsidiaryModalOpen : onBusinessUnitEsrsModalOpen}
              >
                {isGroupCompany ? 'Add subsidiary' : 'Add business unit'}
              </Button>
            </VStack>
          ) : (
            <VStack
              p="24px 16px"
              border="1px dashed"
              borderColor="border.default"
              borderRadius="8px"
              w="100%"
              alignItems="center"
            >
              <Box w="40%">
                <VStack spacing="4px">
                  <GroupIcon />
                  <Typography variant="body" color="text.muted" textAlign="center">
                    {isGroupCompany
                      ? 'Add subsidiaries of ' + data?.esrsAssessment?.company.name
                      : 'You can setup your organisational structure if you want to delegate parts of the data collection process to different departments'}
                  </Typography>

                  <Button
                    variant="ghost"
                    leftIcon={<AddIcon />}
                    onClick={isGroupCompany ? onSubsidiaryModalOpen : onBusinessUnitEsrsModalOpen}
                  >
                    {isGroupCompany ? 'Add subsidiary' : 'Add business unit'}
                  </Button>
                </VStack>
              </Box>
            </VStack>
          )}
        </VStack>
        <InviteMembersModal
          isOpen={isInvitationModalOpen}
          onClose={onInvitationModalClose}
          withSubsidiaries={isGroupCompany}
        />
        <AddSubsidaryModal
          subsidiary={selectedSubsidiary}
          isOpen={isSubsidiaryModalOpen}
          onInvitationModalOpen={onInvitationModalOpen}
          isEdit={isEdit}
          onClose={() => {
            onSubsidiaryModalClose();
            setIsEdit(false);
          }}
        />
        <AddBusinessUnitEsrsModal
          reportingUnitsEsrs={reportingUnits}
          isOpen={isBusinessUnitEsrsModalOpen}
          onInvitationModalOpen={onInvitationModalOpen}
          onClose={handleBusinessUnitEsrsModalClose}
          selectedBusinessUnit={selectedBusinessUnit}
        />
      </VStack>
    </ContentLayout>
  );
};
