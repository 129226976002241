import { VStack, HStack, Button } from '@chakra-ui/react';
import { IconArrowNarrowDown } from '@tabler/icons-react';
import { LinkAnchor } from 'Molecules/LinkAnchor';
import { Typography } from 'Tokens';
import { Dispatch, SetStateAction, useState } from 'react';
import { useCurrentCompany } from 'utils/hooks';
import { MetricsTableData } from '..';
import { AggregatedQualitativeAnswers } from '../AggregatedMetrics';
import { MetricsInputTable } from '../InputTable';
import { NarrativeMetricsTable } from '../InputTable/NarrativeMetricsTable';
import { AssessableMetrics } from '../Metrics';
import { GeneratedAnswer } from './MetricsAITypes';
import { SelectedMetric, TimePeriodsEnums, TimePeriods } from 'containers/Esrs';
import { User } from 'models';

export const MetricsAITable = ({
  allFilteredMetrics,
  selectedQuarter,
  setSelectedMetric,
  onOpen,
  onDrawerOpen,
  isOverview,
  materialStandardId,
  areMetricsYearly,
  isCompanyLevel,
  isGroup,
  companyLevelReportingUnitId,
  rowData,
  setRowData,
  esrsAssessmentProjectLeader,
  selectedNumericMetrics,
  setSelectedNumericMetrics,
  selectedNarrativeMetrics,
  setSelectedNarrativeMetrics,
  qualitativeMetrics,
  summaryQualitativeMetrics,
  aggregatedQualitativeMetricsAnswers,
  generatedAnswers,
  setGeneratedAnswers,
  populateQuantitativeAnswers,
  populateNarrativeAnswers,
}: {
  allFilteredMetrics: MetricsTableData[];
  selectedQuarter: TimePeriods;
  setSelectedMetric: (param: SelectedMetric) => void;
  onOpen: () => void;
  onDrawerOpen: () => void;
  isOverview: boolean;
  materialStandardId: string;
  areMetricsYearly: boolean;
  isCompanyLevel: boolean;
  isGroup: boolean;
  companyLevelReportingUnitId?: string;
  rowData?: MetricsTableData;
  setRowData: (data: MetricsTableData | undefined) => void;
  esrsAssessmentProjectLeader?: Partial<User>;
  selectedNumericMetrics?: MetricsTableData[];
  setSelectedNumericMetrics?: Dispatch<SetStateAction<MetricsTableData[]>>;
  selectedNarrativeMetrics?: AssessableMetrics;
  setSelectedNarrativeMetrics?: Dispatch<SetStateAction<AssessableMetrics>>;
  qualitativeMetrics: AssessableMetrics;
  summaryQualitativeMetrics: AssessableMetrics;
  aggregatedQualitativeMetricsAnswers: AggregatedQualitativeAnswers;
  generatedAnswers: GeneratedAnswer[];
  setGeneratedAnswers: Dispatch<SetStateAction<GeneratedAnswer[]>>;
  populateQuantitativeAnswers: (
    generatedAnswers: GeneratedAnswer[],
    numericMetrics: MetricsTableData[]
  ) => void;
  populateNarrativeAnswers: (
    generatedAnswers: GeneratedAnswer[],
    numericMetrics: AssessableMetrics
  ) => void;
}) => {
  const { company } = useCurrentCompany();

  const [numberOfRows, setNumberOfRows] = useState<Record<string, number>>({});

  const toggleShowMore = (category: string) => {
    setNumberOfRows((prevStates) => ({
      ...prevStates,
      [category]: (prevStates[category] || 5) + 10,
    }));
  };

  const getTotalRows = (data: MetricsTableData[]): number => {
    let totalRows = data.length;
    for (const row of data) {
      if (row.subRows && row.subRows.length > 0) {
        totalRows += getTotalRows(row.subRows);
      }
    }
    return totalRows;
  };

  return (
    <VStack w="100%" spacing="32px" alignItems="start">
      {allFilteredMetrics.map((metric) => {
        const rowsCount = getTotalRows([metric]);
        const remainingCount =
          rowsCount - (numberOfRows[metric.metric?.reference ?? ''] ?? 5) > 10
            ? 10
            : rowsCount - (numberOfRows[metric.metric?.reference ?? ''] ?? 5);
        const showMoreButton = remainingCount > 0;

        return (
          <VStack
            key={metric.metric.reference}
            w="100%"
            alignItems="start"
            id={metric.metric.reference}
            spacing="6px"
          >
            <HStack justifyContent="space-between" w="100%">
              <LinkAnchor
                id={metric.metric.reference}
                text={`${metric.metric.title} ${(metric.tagType ?? '')?.toLowerCase()}`}
                marginLeft="-20px"
              />
            </HStack>
            <MetricsInputTable
              metrics={[metric]}
              selectedQuarter={selectedQuarter}
              onOpen={onOpen}
              setSelectedMetric={setSelectedMetric}
              onDrawerOpen={onDrawerOpen}
              isOverview={isOverview}
              companyStandardId={materialStandardId}
              areMetricsYearly={areMetricsYearly}
              companyReportingUnit={companyLevelReportingUnitId}
              currency={company?.currency ?? ''}
              isCompanyLevel={isCompanyLevel}
              isGroup={isGroup}
              esrsAssessmentProjectLeader={esrsAssessmentProjectLeader}
              rowData={rowData}
              setRowData={setRowData}
              pageSize={numberOfRows[metric.metric.reference]}
              filteredMetrics={allFilteredMetrics}
              setNumberOfRows={setNumberOfRows}
              isMetricDr={true}
              selectedRows={selectedNumericMetrics}
              setSelectedRows={setSelectedNumericMetrics}
              isAI={true}
              generatedAnswers={generatedAnswers}
              setGeneratedAnswers={setGeneratedAnswers}
              populateQuantitativeAnswers={populateQuantitativeAnswers}
            />
            {showMoreButton && (
              <Button
                mt="2px"
                variant="ghost"
                size="sm"
                onClick={() => toggleShowMore(metric.metric.reference)}
              >
                <HStack spacing="8px">
                  <IconArrowNarrowDown size="16px" />
                  <Typography variant="bodyStrong" size="sm">
                    Load {remainingCount} more
                  </Typography>
                </HStack>
              </Button>
            )}
          </VStack>
        );
      })}

      {qualitativeMetrics?.length !== 0 && (
        <VStack w="100%" alignItems="start" spacing="6px">
          <LinkAnchor
            id={qualitativeMetrics?.[0].reference}
            text="Narrative data points"
            marginLeft="-20px"
          />
          <NarrativeMetricsTable
            metrics={qualitativeMetrics}
            selectedQuarter={selectedQuarter}
            esrsAssessmentProjectLeader={esrsAssessmentProjectLeader ?? undefined}
            rowData={rowData}
            setRowData={setRowData}
            isGeneratingAnswers={false}
            companyReportingUnit={companyLevelReportingUnitId}
            isAI={true}
            selectedRows={selectedNarrativeMetrics}
            setSelectedRows={setSelectedNarrativeMetrics}
            populateNarrativeAnswers={populateNarrativeAnswers}
            generatedAnswers={generatedAnswers}
            setGeneratedAnswers={setGeneratedAnswers}
            isReadOnly={true}
          />
        </VStack>
      )}

      {summaryQualitativeMetrics?.length !== 0 && (
        <VStack w="100%" alignItems="start" spacing="6px">
          <LinkAnchor
            id={summaryQualitativeMetrics?.[0].reference ?? ''}
            text="Summarized narrative data points"
            marginLeft="-20px"
          />
          <NarrativeMetricsTable
            metrics={summaryQualitativeMetrics}
            selectedQuarter={TimePeriodsEnums.year}
            esrsAssessmentProjectLeader={esrsAssessmentProjectLeader ?? undefined}
            rowData={rowData}
            setRowData={setRowData}
            answersData={aggregatedQualitativeMetricsAnswers}
            companyReportingUnit={companyLevelReportingUnitId}
            isAI={true}
            selectedRows={selectedNarrativeMetrics}
            setSelectedRows={setSelectedNarrativeMetrics}
            populateNarrativeAnswers={populateNarrativeAnswers}
            generatedAnswers={generatedAnswers}
            setGeneratedAnswers={setGeneratedAnswers}
            isReadOnly={true}
          />
        </VStack>
      )}
    </VStack>
  );
};
