import {
  useAssessmentReportingUnits,
  useMaterialStandardId,
} from 'containers/Esrs/EsrsAssessment.hooks';
import { DataCollectionLevel } from 'containers/Esrs/pieces/DataCollection';
import { uniqBy } from 'lodash';
import {
  GetAnswersForMetricsOnCompanyLevelQuery_,
  GetAnswersForMetricsOnGroupLevelQuery_,
  GetMetricsDrQuery_,
  MaterialMetricsFieldsFragment_,
  QuestionType_Enum_,
  useCompanyLevelMetricsPerDisclosureQuery,
  useEsrsAssessmentQuery,
  useGetAnswersForMetricsOnCompanyLevelQuery,
  useGetAnswersForMetricsOnGroupLevelQuery,
  useGetEsrsAssessmentMetadataQuery,
  useGetMetricsDrQuery,
  useGroupLevelMetricsPerDisclosureQuery,
} from 'models';
import { TableData } from 'Molecules/NestedTable';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useCompanyType } from 'utils/hooks';
import {
  FrequencyEnums,
  QuartersObject,
  QUARTERS_FIELDS,
  TimePeriodsEnums,
  YearObject,
} from '../../Requirement';
import { getNestedRows, getUniqueDataPoints } from '../MetricAnswers.hooks';
import {
  filterChildrenInFirstLevelMetrics,
  separateQualitativeMetricsFromQuantitativeParents,
} from '../Metrics.utils';
import {
  combinedFiltersForNoTags,
  combinedFiltersForTags,
  resolveMetricCalculation,
} from './calculations';
import { AssessableMetrics } from '../Metrics';

export type MetricType = NonNullable<
  GetMetricsDrQuery_['DisclosureRequirement_by_pk']
>['metrics'][number];

type MaterialMetric = MetricType['materialMetrics'][number];

export type AggregatedMetricsExtraData = {
  metric: MetricType;
  tags?: {
    tagType: string;
    tagValue: string;
  }[];
  tagName?: string;
  tagType?: string;
  isChild?: boolean;
  result?: YearObject;
  percentage?: string;
  reportingUnitId?: string;
};

export type AggregatedMetricsTableData = TableData<AggregatedMetricsExtraData>;

type AnswerMetricsQueryType = NonNullable<
  GetAnswersForMetricsOnGroupLevelQuery_['EsrsAssessment_by_pk']
>;
export type SubsidiaryAssessmentsType = AnswerMetricsQueryType['subsidiaryAssessments'][number];
type ReportingUnitType =
  AnswerMetricsQueryType['subsidiaryAssessments'][number]['reportingUnits'][number];
export type MetricAnswer =
  AnswerMetricsQueryType['subsidiaryAssessments'][number]['reportingUnits'][number]['answers'][number] & {
    frequency?: string;
    hasTags?: boolean;
  };
export type DataPoint = MetricAnswer['datapoints'][number];
export type AggregatedQualitativeAnswers = {
  metricRef?: string;
  answer: MetricAnswer | undefined;
  reportingUnits?: {
    reportingUnit: ReportingUnitType;
    answer: MetricAnswer | undefined;
  }[];
  subsidiaries?: {
    subsidiary: SubsidiaryAssessmentsType;
    answer: MetricAnswer | undefined;
  }[];
}[];
type TagType = {
  isOptional: boolean;
  tagType: string;
  valueOptions: {
    tagValue: string;
  }[];
};

const addQuarters = (answers: QuartersObject) => {
  const sum = Object.values(answers)?.reduce((a, b) => Number(a) + Number(b), 0);
  return sum;
};

const mapMetricsWithAnswers = ({
  metrics,
  isGroupCompany,
  companyAnswersData,
  groupAnswersData,
  companyStandardId,
}: {
  metrics: AssessableMetrics;
  isGroupCompany: boolean;
  companyAnswersData: NonNullable<
    GetAnswersForMetricsOnCompanyLevelQuery_['EsrsAssessment_by_pk']
  >['reportingUnits'];
  groupAnswersData: NonNullable<
    GetAnswersForMetricsOnGroupLevelQuery_['EsrsAssessment_by_pk']
  >['subsidiaryAssessments'];
  companyStandardId: string;
}) => {
  return metrics.map((metric) => {
    if (isGroupCompany) {
      const isGroupLevel = metric.materialMetrics[0].dataCollection === DataCollectionLevel.group;

      if (isGroupLevel) {
        return {
          metricRef: metric.reference,
          answer: companyAnswersData
            ?.find((ru) => ru.isCompanyLevel)
            ?.answers?.find((answer) => answer.metricRef === metric.reference),
        };
      } else
        return {
          metricRef: metric.reference,
          answer: companyAnswersData
            ?.find((ru) => ru.isCompanyLevel)
            ?.answers?.find((answer) => answer.metricRef === metric.reference),
          subsidiaries: groupAnswersData.flatMap((subsidiary) => {
            return {
              subsidiary: subsidiary,
              answer: subsidiary.reportingUnits
                .find((ru) => ru.isCompanyLevel)
                ?.answers?.find((answer) => answer.metricRef === metric.reference),
            };
          }),
        };
    } else {
      const isCompanyLevel =
        metric.materialMetrics.find((mm) => mm.materialStandardId === companyStandardId)
          ?.dataCollection === DataCollectionLevel.company;

      if (isCompanyLevel) {
        return {
          metricRef: metric.reference,
          answer: companyAnswersData
            ?.find((ru) => ru.isCompanyLevel)
            ?.answers?.find((answer) => answer.metricRef === metric.reference),
        };
      } else
        return {
          metricRef: metric.reference,
          answer: companyAnswersData
            ?.find((ru) => ru.isCompanyLevel)
            ?.answers?.find((answer) => answer.metricRef === metric.reference),
          reportingUnits: companyAnswersData
            .filter((ru) => !ru.isCompanyLevel)
            .flatMap((ru) => {
              return {
                reportingUnit: ru,
                answer: ru.answers?.find((answer) => answer.metricRef === metric.reference),
              };
            }),
        };
    }
  });
};

export const useGetAggregatedMetricsData = (withAssociation = false) => {
  const { esrsAssessmentId = '', standardRef = '', disclosureRequirementRef = '' } = useParams();
  const { companyType, loading: loadingType } = useCompanyType();
  const isGroup = useMemo(() => companyType === 'group-company', [companyType]);
  const {
    companyAssessmentId,
    parentAssessmentId,
    loading: materialLoading,
  } = useMaterialStandardId(standardRef, esrsAssessmentId);

  const { data: assessmentData, loading: assessmentLoading } = useEsrsAssessmentQuery({
    variables: { esrsAssessmentId },
    skip: !esrsAssessmentId,
  });
  const esrsAssessment = useMemo(() => assessmentData?.esrsAssessment, [assessmentData]);

  const { data: metricsDisclosureData, loading: metricsLoading } = useGetMetricsDrQuery({
    variables: {
      reference: disclosureRequirementRef,
      materialStandardId: companyAssessmentId,
      parentStandardId: parentAssessmentId || companyAssessmentId,
    },
    skip: !disclosureRequirementRef || !companyAssessmentId,
  });

  const [metricDR, metrics] = useMemo(
    () => [
      metricsDisclosureData?.DisclosureRequirement_by_pk,
      metricsDisclosureData?.DisclosureRequirement_by_pk?.metrics.filter((metric) =>
        withAssociation ? metric.hasAssociation : !metric.hasAssociation
      ),
    ],
    [metricsDisclosureData, withAssociation]
  );

  const { reportingUnitAssessments } = useAssessmentReportingUnits(esrsAssessmentId);
  const companyLevelReportingUnitId = useMemo(
    () => reportingUnitAssessments.find((ru) => ru.isCompanyLevel)?.id ?? '',
    [reportingUnitAssessments]
  );

  const { data: companyData, loading: companyLoading } = useCompanyLevelMetricsPerDisclosureQuery({
    variables: {
      disclosureRequirementRef,
      companyAssessmentId,
      parentAssessmentId: parentAssessmentId || companyAssessmentId,
    },
    skip: !disclosureRequirementRef || !companyAssessmentId || isGroup,
  });

  const { data: groupData, loading: groupLoading } = useGroupLevelMetricsPerDisclosureQuery({
    variables: {
      disclosureRequirementRef,
      materialStandardId: companyAssessmentId,
    },
    skip: !disclosureRequirementRef || !companyAssessmentId || !isGroup,
  });

  const filteredMetrics = useMemo(() => {
    const filteredChildrenInFirstLevelMetrics = isGroup
      ? filterChildrenInFirstLevelMetrics(
          groupData?.assessableMetrics.filter((metric) =>
            withAssociation ? metric.hasAssociation : !metric.hasAssociation
          ) ?? [],
          companyAssessmentId
        )
      : filterChildrenInFirstLevelMetrics(
          companyData?.assessableMetrics.filter((metric) =>
            withAssociation ? metric.hasAssociation : !metric.hasAssociation
          ) ?? [],
          companyAssessmentId
        ) ?? [];
    return separateQualitativeMetricsFromQuantitativeParents(filteredChildrenInFirstLevelMetrics);
  }, [companyData, groupData, isGroup, companyAssessmentId, withAssociation]);

  const hasInputSection = useMemo(() => !!filteredMetrics.length, [filteredMetrics]);

  const { data: metaData, loading: metaDataLoading } = useGetEsrsAssessmentMetadataQuery({
    variables: {
      id: esrsAssessmentId,
    },
    skip: !esrsAssessmentId,
  });
  const metadata = useMemo(() => metaData?.EsrsAssessment_by_pk?.metadata, [metaData]);
  return {
    metricDR,
    metrics,
    filteredMetrics,
    companyLevelReportingUnitId,
    metaData,
    metadata,
    hasInputSection,
    companyAssessmentId,
    parentAssessmentId,
    esrsAssessment,
    metaDataLoading,
    isGroup,
    dataLoading:
      metricsLoading ||
      materialLoading ||
      companyLoading ||
      metaDataLoading ||
      groupLoading ||
      assessmentLoading ||
      loadingType,
  };
};

export const getMaterialMetricsTags = (
  groupAnswersData: AnswerMetricsQueryType['subsidiaryAssessments'],
  standardRef: string,
  metricRef: string
) => {
  const materialMetrics = groupAnswersData.flatMap((company) =>
    company.materialStandards
      .find((ms) => ms.standard.reference === standardRef)
      ?.materialMetrics.find((mm) => mm?.metric.reference === metricRef)
  );
  const materialMetricTagsArray =
    (materialMetrics
      ?.flatMap((mm) => mm?.materialMetricTags)
      .filter((mm) => mm !== undefined) as TagType[]) ?? [];

  const uniqueTagTypes = [...new Set(materialMetricTagsArray?.flatMap((tag) => tag.tagType))];

  const combinedMaterialMetricValues = uniqueTagTypes.map((type) => ({
    ...(materialMetricTagsArray?.find((obj) => obj.tagType === type) ?? ({} as TagType)),
    valueOptions: uniqBy(
      materialMetricTagsArray
        ?.filter((obj) => obj.tagType === type)
        .flatMap((obj) => obj.valueOptions),
      'tagValue'
    ),
  }));
  return combinedMaterialMetricValues;
};

export const addMaterialMetricTags = (
  metric: MetricType,
  groupAnswersData: SubsidiaryAssessmentsType[],
  standardRef: string
) => {
  let updatedMetric = {
    ...metric,
    materialMetrics: metric.materialMetrics.map((materialMetric) => ({
      ...materialMetric,
      materialMetricTags: getMaterialMetricsTags(groupAnswersData, standardRef, metric.reference),
    })),
  };

  if (!!metric.childrenMetrics?.length) {
    const updatedChildren = metric.childrenMetrics.map(({ childMetric }) => ({
      childMetric: childMetric
        ? addMaterialMetricTags(childMetric as MetricType, groupAnswersData, standardRef)
        : childMetric,
    }));
    updatedMetric = {
      ...updatedMetric,
      childrenMetrics: updatedChildren as AssessableMetrics[number]['childrenMetrics'],
    };
  }

  return updatedMetric;
};

export const getFullMetricWithChildren = (metric: MetricType) => {
  const childrenMetricRefs = [metric];

  const recurseChildren = (childrenMetrics: MetricType['childrenMetrics']) => {
    if (!!childrenMetrics?.length) {
      childrenMetrics.forEach((child) => {
        if (!!child.childMetric) {
          childrenMetricRefs.push(child.childMetric as MetricType);
          recurseChildren(child?.childMetric.childrenMetrics as MetricType['childrenMetrics']);
        }
      });
    }
  };

  if (metric?.childrenMetrics) {
    recurseChildren(metric?.childrenMetrics);
  }
  return childrenMetricRefs.filter((m) => m !== undefined) ?? [];
};

const shouldIncludeMetricAnswer = (
  mAnswers: MetricAnswer,
  isGroup: boolean,
  materialMetric?: MaterialMetricsFieldsFragment_,
  subsidiary?: SubsidiaryAssessmentsType,
  standardRef?: string,
  childMaterialMetric?: MaterialMetricsFieldsFragment_
) => {
  const subsidiaryMaterialityAssessment = subsidiary?.materialStandards.find(
    (assessment) => assessment.standard.reference === standardRef
  );

  const subsidiaryMaterialMetric = isGroup
    ? childMaterialMetric ??
      subsidiaryMaterialityAssessment?.materialMetrics.find(
        (mm) => mm.metric.reference === materialMetric?.metric?.reference
      )
    : childMaterialMetric ?? materialMetric;

  const materialDataCollection = subsidiaryMaterialMetric?.dataCollection;

  const shouldInclude =
    materialDataCollection === DataCollectionLevel.company ||
    materialDataCollection === DataCollectionLevel.group
      ? mAnswers.reportingUnit.isCompanyLevel
      : !mAnswers.reportingUnit.isCompanyLevel;

  const frequency = subsidiaryMaterialMetric?.frequency;
  const hasTags = !!subsidiaryMaterialMetric?.materialMetricTags?.length;
  return { shouldInclude, frequency, hasTags };
};

const getMetricAnswers = (data: {
  reportingUnit: ReportingUnitType;
  isGroup: boolean;
  allMetrics: MetricType[];
  materialMetric?: MaterialMetricsFieldsFragment_;
  allMaterialMetrics?: MaterialMetricsFieldsFragment_[];
  subsidiary?: SubsidiaryAssessmentsType;
  standardRef?: string;
}) => {
  const allAnswersWithFrequency = data.allMetrics?.flatMap((metric) => {
    const childMaterialMetric = data?.allMaterialMetrics?.find(
      (mm) => mm?.metric.reference === metric.reference
    );
    return data.reportingUnit?.answers?.map((answer) => {
      const { shouldInclude, frequency, hasTags } = shouldIncludeMetricAnswer(
        answer,
        data.isGroup,
        data?.materialMetric,
        data?.subsidiary,
        data?.standardRef,
        childMaterialMetric
      );
      if (answer.metricRef === metric.reference && shouldInclude) {
        return { ...answer, frequency: frequency, hasTags: hasTags };
      }
      return undefined;
    });
  });

  return (allAnswersWithFrequency?.filter((a) => a !== undefined) ?? []) as MetricAnswer[];
};

const handleParentLevelGroup = (
  reportingUnit: ReportingUnitType,
  allMetrics: MetricType[],
  materialMetric?: MaterialMetricsFieldsFragment_,
  allMaterialMetrics?: MaterialMetricsFieldsFragment_[]
) => {
  return getMetricAnswers({
    reportingUnit,
    isGroup: false,
    allMetrics,
    materialMetric: materialMetric,
    allMaterialMetrics,
  });
};

const handleNormalCompany = (
  reportingUnits: ReportingUnitType[],
  allMetrics: MetricType[],
  materialMetric?: MaterialMetricsFieldsFragment_,
  allMaterialMetrics?: MaterialMetricsFieldsFragment_[]
) => {
  return reportingUnits.flatMap((reportingUnit) =>
    getMetricAnswers({
      reportingUnit,
      isGroup: false,
      allMetrics,
      materialMetric,
      allMaterialMetrics,
    })
  );
};

const extractMaterialMetrics = (
  subsidiary: SubsidiaryAssessmentsType,
  requiredMetricsRefs: string[],
  standardRef?: string
) => {
  const materialStandard = subsidiary.materialStandards.find(
    (standard) => standard.standard.reference === standardRef
  );
  return materialStandard?.materialMetrics.filter((m) =>
    requiredMetricsRefs.includes(m.metric.reference)
  );
};

const handleNonParentLevelGroup = (data: {
  subsidiaries: SubsidiaryAssessmentsType[];
  requiredMetrics: MetricType[];
  requiredMetricsRefs: string[];
  standardRef?: string;
  materialMetric?: MaterialMetricsFieldsFragment_;
}) => {
  const { subsidiaries, requiredMetrics, requiredMetricsRefs, standardRef, materialMetric } = data;

  return subsidiaries.flatMap((subsidiary) => {
    const allMaterialMetrics = extractMaterialMetrics(subsidiary, requiredMetricsRefs, standardRef);

    return subsidiary.reportingUnits?.flatMap((reportingUnit) =>
      getMetricAnswers({
        reportingUnit,
        isGroup: true,
        allMetrics: requiredMetrics,
        materialMetric,
        allMaterialMetrics,
        subsidiary,
        standardRef,
      })
    );
  });
};

const getAnswers = (data: {
  isGroup: boolean;
  isParentLevel: boolean;
  reportingUnits: ReportingUnitType[];
  subsidiaries: SubsidiaryAssessmentsType[];
  requiredMetrics: MetricType[];
  materialMetric?: MaterialMetricsFieldsFragment_;
  requiredMaterialMetrics: MaterialMetricsFieldsFragment_[];
  requiredMetricsRefs: string[];
  standardRef?: string;
}) => {
  const {
    isGroup,
    isParentLevel,
    reportingUnits,
    subsidiaries,
    requiredMetrics,
    materialMetric,
    requiredMaterialMetrics,
    requiredMetricsRefs,
    standardRef,
  } = data;

  if (isGroup) {
    if (isParentLevel) {
      return handleParentLevelGroup(
        reportingUnits?.[0],
        requiredMetrics,
        materialMetric,
        requiredMaterialMetrics
      );
    } else {
      return handleNonParentLevelGroup({
        subsidiaries,
        requiredMetrics,
        requiredMetricsRefs,
        materialMetric,
        standardRef,
      });
    }
  } else {
    return handleNormalCompany(
      reportingUnits,
      requiredMetrics,
      materialMetric,
      requiredMaterialMetrics
    );
  }
};

export const getFullMetricAnswers = (data: {
  metric: MetricType;
  reportingUnits: ReportingUnitType[];
  subsidiaries: SubsidiaryAssessmentsType[];
  isGroup: boolean;
  standardRef?: string;
  materialStandardId: string;
}) => {
  const { metric, reportingUnits, subsidiaries, isGroup, standardRef, materialStandardId } = data;

  const requiredMetrics = getFullMetricWithChildren(metric);
  const requiredMetricsRefs = requiredMetrics.map((m) => m.reference);
  const requiredMaterialMetrics =
    requiredMetrics
      .map((m) => m.materialMetrics?.find((mm) => mm.materialStandardId === materialStandardId))
      ?.filter((mm): mm is MaterialMetric => mm !== undefined) ?? [];

  const isParentLevel = metric.materialMetrics?.[0].dataCollection === DataCollectionLevel.group;
  const materialMetric = metric.materialMetrics?.find(
    (mm) => mm.materialStandardId === materialStandardId
  );

  const answers = getAnswers({
    isGroup,
    isParentLevel,
    reportingUnits,
    subsidiaries,
    requiredMetrics,
    materialMetric,
    requiredMaterialMetrics,
    requiredMetricsRefs,
    standardRef,
  });

  return answers;
};

const getRequiredAnswers = (
  metric: AggregatedMetricsTableData['metric'],
  answers: MetricAnswer[],
  isChild = false,
  tags?: {
    tagType: string;
    tagValue: string;
  }[][],
  childrenMetrics?: string[]
) => {
  const isParent = !!metric.childrenMetrics?.length;
  const isParentAndChild = !!metric.childrenMetrics?.length && !!metric.parentMetrics?.length;
  const hasTags = tags?.length ?? false;
  const directChildrenRefs = metric.childrenMetrics?.map((child) => child?.childMetric?.reference);

  if (isParentAndChild || (isParent && !hasTags)) {
    return answers.filter((answer) => childrenMetrics?.includes(answer.metricRef));
  }
  if (isParent && hasTags) {
    return answers
      .filter((answer) => directChildrenRefs?.includes(answer.metricRef))
      .filter((x) => x.metricRef !== metric.reference);
  }
  if (isChild || hasTags) {
    return answers.filter((answer) => answer.metricRef === metric.reference);
  }
  return answers;
};

export const getAggregatedDatapoints = ({
  metric,
  answers,
  isChild = false,
  tags,
  childrenMetrics,
  filterAnswers = true,
}: {
  metric: AggregatedMetricsTableData['metric'];
  answers: MetricAnswer[];
  isChild?: boolean;
  tags?: {
    tagType: string;
    tagValue: string;
  }[][];
  childrenMetrics?: string[];
  filterAnswers?: boolean;
}): YearObject => {
  if (answers) {
    const chosenAnswers = filterAnswers
      ? getRequiredAnswers(metric, answers, isChild, tags, childrenMetrics)
      : answers;

    const datapoints = chosenAnswers?.flatMap((answer) => {
      const isAnswerYearly = answer?.frequency === FrequencyEnums.yearly;
      if (answer?.hasTags)
        return answer?.datapoints
          ?.filter((datapoint) => combinedFiltersForTags(datapoint, isAnswerYearly, tags ?? []))
          .map((dp) => ({
            ...dp,
            metricRef: answer?.metricRef ?? '',
            tagValues: dp.datapointTags.map((tag) => tag.tagValue),
            answerId: answer.id,
          }));

      return answer?.datapoints
        ?.filter(
          (datapoint) =>
            combinedFiltersForNoTags(datapoint, isAnswerYearly) &&
            !answer.metric.childrenMetrics?.length
        )
        .map((dp) => ({
          ...dp,
          metricRef: answer?.metricRef ?? '',
          tagValues: dp.datapointTags.map((tag) => tag.tagValue),
          answerId: answer.id,
        }));
    });

    const uniqueDPs = getUniqueDataPoints(datapoints);

    const isMetricYearly = metric.materialMetrics?.[0]?.frequency === FrequencyEnums.yearly;

    if (isMetricYearly) {
      const datapointsPerYear = uniqueDPs.map((dp) => ({
        value: Number(dp.value ?? 0),
        metricRef: dp.metricRef,
        tagValues: dp.datapointTags.map(
          (tag: { tagValue: string; tagType: string }) => tag.tagValue
        ),
      }));
      const total = resolveMetricCalculation(datapointsPerYear, metric.calculation);

      return { Q1: 0, Q2: 0, Q3: 0, Q4: 0, Year: total };
    }

    // Quarterly case
    const totalPerTimeframe = QUARTERS_FIELDS.map((field) => {
      const datapointsPerTimeframe = uniqueDPs
        ?.filter(
          (datapoint) =>
            datapoint?.timeframe === field || datapoint?.timeframe === TimePeriodsEnums.year
        )
        .map((dp) => ({
          value: Number(dp.value ?? 0),
          metricRef: dp.metricRef,
          tagValues: dp.datapointTags.map(
            (tag: { tagValue: string; tagType: string }) => tag.tagValue
          ),
        }));
      const total = resolveMetricCalculation(datapointsPerTimeframe, metric.calculation);

      return { [field]: total };
    });

    const quarters: QuartersObject = Object.assign({}, ...totalPerTimeframe);
    return { Year: addQuarters(quarters), ...quarters };
  }
  // No datapoints case
  return { Q1: 0, Q2: 0, Q3: 0, Q4: 0, Year: 0 };
};

const getParentAnswerTags = (metricRow: AggregatedMetricsTableData) => {
  const hasMultipleTagTypes = metricRow?.subRows?.some((subRow) =>
    subRow.subRows?.some((row) => row.tags?.length)
  );
  const hasSingleTag = metricRow?.subRows?.some((subRow) => subRow.tags?.length);

  if (hasMultipleTagTypes)
    return metricRow?.subRows?.flatMap((row) => row.subRows?.map((r) => r.tags ?? []) ?? []);

  if (hasSingleTag) return metricRow.subRows?.map((row) => row?.tags ?? []);
  return undefined;
};

const getNestedData = (
  metricRow: AggregatedMetricsTableData,
  answers: MetricAnswer[]
): AggregatedMetricsTableData => {
  if (!!metricRow?.subRows?.length) {
    return {
      ...metricRow,
      isChild: metricRow.metric.parentMetrics?.length > 0,
      subRows: metricRow.subRows.map((subRow) => {
        if (subRow.tags?.length) {
          return {
            ...subRow,
            result: getAggregatedDatapoints({
              metric: subRow.metric,
              answers,
              isChild: subRow?.isChild,
              tags: [subRow.tags],
            }),
          };
        } else if (!!subRow?.tagType) {
          return getNestedData(
            {
              ...subRow,
              result: getAggregatedDatapoints({
                metric: subRow.metric,
                answers,
                isChild: subRow?.isChild,
                tags: subRow.subRows?.map((row) => row?.tags ?? []),
              }),
            },
            answers
          );
        } else {
          return getNestedData(
            {
              ...subRow,
              result: getAggregatedDatapoints({
                metric: subRow.metric,
                answers,
                isChild: true,
                tags: subRow?.subRows?.map((row) => row?.tags ?? []),
                childrenMetrics: subRow.metric?.childrenMetrics?.length
                  ? getFullMetricWithChildren(subRow.metric).map((m) => m.reference)
                  : [],
              }),
            },
            answers
          );
        }
      }),
      result:
        metricRow?.result ??
        getAggregatedDatapoints({
          metric: metricRow.metric,
          answers,
          isChild: metricRow.metric.parentMetrics?.length > 0,
          childrenMetrics: metricRow.metric?.childrenMetrics?.length
            ? getFullMetricWithChildren(metricRow.metric).map((m) => m.reference)
            : [],
          tags: getParentAnswerTags(metricRow),
        }),
    };
  } else {
    return {
      ...metricRow,
      result:
        metricRow.result ??
        getAggregatedDatapoints({
          metric: metricRow.metric,
          answers,
          isChild: metricRow.isChild,
          tags: metricRow?.tags?.length ? [metricRow?.tags] : undefined,
        }),
    };
  }
};
export const getAggregatedMetricAnswers = (
  metric: AggregatedMetricsTableData,
  answers?: MetricAnswer[]
) => {
  if (answers) {
    return getNestedData(metric, answers);
  }
  return metric;
};

export const useGetAggregatedMetrics = (
  esrsAssessmentId: string,
  metrics: NonNullable<GetMetricsDrQuery_['DisclosureRequirement_by_pk']>['metrics'],
  standardRef: string,
  companyStandardId: string
) => {
  const { companyType, loading: loadingType } = useCompanyType();
  const isGroupCompany = useMemo(() => companyType === 'group-company', [companyType]);

  const { data: companyMetricAnswersData, loading: loadingCompany } =
    useGetAnswersForMetricsOnCompanyLevelQuery({
      variables: { esrsAssessmentId },
    });
  const { data: groupMetricAnswersData, loading: loadingGroup } =
    useGetAnswersForMetricsOnGroupLevelQuery({
      variables: { esrsAssessmentId },
      skip: !isGroupCompany,
    });

  const companyAnswersData = useMemo(
    () => companyMetricAnswersData?.EsrsAssessment_by_pk?.reportingUnits ?? [],
    [companyMetricAnswersData]
  );

  const groupAnswersData = useMemo(
    () => groupMetricAnswersData?.EsrsAssessment_by_pk?.subsidiaryAssessments ?? [],
    [groupMetricAnswersData]
  );

  const filteredMetrics = useMemo(
    () =>
      separateQualitativeMetricsFromQuantitativeParents(
        filterChildrenInFirstLevelMetrics(metrics, companyStandardId)
      ),
    [metrics, companyStandardId]
  );

  const quantitativeMetrics = useMemo(
    () =>
      filteredMetrics.filter((metric) => metric.metricType === QuestionType_Enum_.Decimal_) ?? [],
    [filteredMetrics]
  );

  const qualitativeMetrics = useMemo(
    () =>
      filteredMetrics.filter(
        (metric) =>
          metric.metricType === QuestionType_Enum_.LongText_ ||
          metric.metricType === QuestionType_Enum_.YesNo_ ||
          metric.metricType === QuestionType_Enum_.SingleChoice_ ||
          metric.metricType === QuestionType_Enum_.MultipleChoice_
      ) ?? [],
    [filteredMetrics]
  );

  const quantitativeMetricsWithTags = useMemo(
    () =>
      quantitativeMetrics.map((metric) =>
        metric.materialMetrics[0].dataCollection === DataCollectionLevel.group
          ? metric
          : addMaterialMetricTags(metric, groupAnswersData, standardRef)
      ),
    [quantitativeMetrics, groupAnswersData, standardRef]
  );

  const nestedMetrics = useMemo(
    () =>
      quantitativeMetrics.map((metric) => {
        return getNestedRows(metric, companyStandardId);
      }),
    [quantitativeMetrics]
  );

  const aggregatedMetrics = useMemo(() => {
    if (isGroupCompany) {
      const groupNestedMetrics = quantitativeMetricsWithTags.map((metric) => {
        return getNestedRows(metric as AssessableMetrics[number], companyStandardId);
      });
      const groupMetricAnswers = groupNestedMetrics?.map(({ metric }) => {
        const requiredMetrics = getFullMetricWithChildren(metric);
        const requiredMetricsRefs = requiredMetrics.map((m) => m.reference);

        if (metric.materialMetrics[0].dataCollection === DataCollectionLevel.group) {
          return {
            [metric.reference]: getMetricAnswers({
              reportingUnit: companyAnswersData[0],
              isGroup: false,
              allMetrics: requiredMetrics,
              materialMetric: metric.materialMetrics[0],
              allMaterialMetrics: requiredMetrics.map((m) => m.materialMetrics[0]),
            }),
          };
        } else
          return {
            [metric.reference]: groupAnswersData.flatMap((subsidiary) => {
              const currentStandard = subsidiary.materialStandards.find(
                (standard) => standard.standard.reference === standardRef
              );
              const allMaterialMetrics = currentStandard?.materialMetrics.filter((m) =>
                requiredMetricsRefs.includes(m.metric.reference)
              );

              return subsidiary.reportingUnits?.flatMap((reportingUnit) => {
                return getMetricAnswers({
                  reportingUnit,
                  isGroup: true,
                  allMetrics: requiredMetrics,
                  materialMetric: metric.materialMetrics.find(
                    (mm) => mm.materialStandardId === companyStandardId
                  ),
                  allMaterialMetrics,
                  subsidiary,
                  standardRef,
                });
              });
            }),
          };
      });

      const results = groupNestedMetrics.map((m) =>
        getAggregatedMetricAnswers(
          m,
          groupMetricAnswers.find((answer) => !!answer?.[m.metric.reference])?.[m.metric.reference]
        )
      );
      return results;
    } else {
      const companyMetricAnswers = nestedMetrics?.map(({ metric }) => {
        const isCompanyLevel =
          metric.materialMetrics.find((mm) => mm.materialStandardId === companyStandardId)
            ?.dataCollection === DataCollectionLevel.company;
        const reportingUnits =
          companyAnswersData.filter((ru) =>
            isCompanyLevel ? ru.isCompanyLevel : !ru.isCompanyLevel
          ) ?? [];

        const requiredMetrics = getFullMetricWithChildren(metric);
        const allMaterialMetrics = requiredMetrics
          .map((m) => m.materialMetrics.find((mm) => mm.materialStandardId === companyStandardId))
          .filter((mm): mm is MaterialMetricsFieldsFragment_ => mm !== undefined);
        return {
          [metric.reference]: reportingUnits.flatMap((reportingUnit) =>
            getMetricAnswers({
              reportingUnit,
              isGroup: false,
              allMetrics: requiredMetrics,
              materialMetric: metric.materialMetrics.find(
                (mm) => mm.materialStandardId === companyStandardId
              ),
              allMaterialMetrics,
            })
          ),
        };
      });

      const results = nestedMetrics.map((m) =>
        getAggregatedMetricAnswers(
          m,
          companyMetricAnswers.find((answer) => !!answer?.[m.metric.reference])?.[
            m.metric.reference
          ]
        )
      );
      return results;
    }
  }, [companyMetricAnswersData, groupMetricAnswersData, isGroupCompany, nestedMetrics]);

  const qualitativeMetricsWithChildren: AssessableMetrics = useMemo(() => {
    const metricsWithChildren = [
      ...qualitativeMetrics,
      ...qualitativeMetrics.flatMap((metric) =>
        metric?.childrenMetrics.flatMap(({ childMetric }) => ({
          ...childMetric,
          materialMetrics: metric.materialMetrics,
        }))
      ),
    ];
    return metricsWithChildren.filter((m) => m !== undefined) as AssessableMetrics;
  }, [qualitativeMetrics]);

  const aggregatedQualitativeMetricsAnswers: AggregatedQualitativeAnswers | undefined = useMemo(
    () =>
      mapMetricsWithAnswers({
        metrics: qualitativeMetricsWithChildren,
        isGroupCompany,
        companyAnswersData,
        groupAnswersData,
        companyStandardId,
      }),
    [
      qualitativeMetricsWithChildren,
      groupAnswersData,
      companyAnswersData,
      isGroupCompany,
      companyStandardId,
    ]
  );

  return {
    aggregatedMetrics,
    qualitativeMetrics,
    aggregatedQualitativeMetricsAnswers,
    aggregationQuantitativeMetrics: quantitativeMetrics,
    loading: loadingCompany || loadingGroup || loadingType,
  };
};

export const getGraphObject = (object: AggregatedMetricsTableData) => {
  const isYearly = object.metric.materialMetrics[0]?.frequency === FrequencyEnums.yearly;
  if (isYearly) {
    return object.result ?? ({} as QuartersObject);
  } else {
    const result: QuartersObject & { Year?: number } = object.result ?? ({} as QuartersObject);
    delete result.Year;
    return result;
  }
};

export const getNestedMetrics = (metrics: AggregatedMetricsTableData[]) => {
  const allMetrics: AggregatedMetricsTableData[] = [...metrics];

  const recurseChildren = (metric: AggregatedMetricsTableData) => {
    metric?.subRows?.forEach((subRow) => {
      if (!subRow.tagName && !subRow.tags) {
        allMetrics.push(subRow);
        recurseChildren(subRow);
      }
    });
  };

  allMetrics.forEach((metric) => recurseChildren(metric));

  return allMetrics;
};
