import {
  EsrsAssessmentsDocument_,
  UpsertEsrsAssessmentMutation_,
  useEsrsAssessmentProjectLeaderQuery,
  useEsrsAssessmentsQuery,
} from 'models';
import { useCompanyType, useCurrentCompanyId, useToast } from 'utils/hooks';
import { useMemo } from 'react';
import { nhost } from 'utils/nhost';
import { EsrsAssessmentDuplicationRequest } from '../../../../../backend/functions/duplicate-esrs-assessment';
import { useApolloClient } from '@apollo/client';

export const useGetEsrsAssessmentList = () => {
  const { companyId } = useCurrentCompanyId();
  const { companyType } = useCompanyType();
  const isGroup = useMemo(() => companyType === 'group-company', [companyType]);

  const { data, loading } = useEsrsAssessmentsQuery({
    variables: { companyId },
    skip: !companyId,
  });
  const esrsAssessments = useMemo(() => data?.esrsAssessments ?? [], [data]);

  return {
    esrsAssessments,
    isGroup,
    loading: loading,
  };
};

export const useEsrsAssessmentProjectLeader = (esrsAssessmentId: string) => {
  const { data } = useEsrsAssessmentProjectLeaderQuery({
    variables: {
      esrsAssessmentId,
    },
    skip: !esrsAssessmentId,
  });

  const esrsAssessmentProjectLeaderId = useMemo(() => {
    return data?.esrsAssessment?.projectLeaderId;
  }, [data]);

  return { esrsAssessmentProjectLeaderId };
};

export type GroupMetricMaterialityResult = {
  groupOnly: string[];
  subsidiariesOnly: string[];
};

export type CompanyMetricMaterialityResult = {
  companyOnly: string[];
  parentCompanyOnly: string[];
};

export type EsrsAssessmentDuplicationOptions =
  EsrsAssessmentDuplicationRequest['duplicationOptions'];

export const defaultEsrsDuplicationOptions: EsrsAssessmentDuplicationOptions = {
  withMateriality: true,
  withComments: true,
  withDatapoints: true,
  withDocumentation: true,
  withReportingUnits: true,
  withTargetsAndActions: true,
};

export const useDuplicateEsrsAssessment = () => {
  const client = useApolloClient();
  const toast = useToast();

  const duplicateAssessment = async (
    assessmentId: string,
    newReportingYear: number,
    duplicationOptions: EsrsAssessmentDuplicationOptions
  ) => {
    try {
      toast({ text: 'Duplicating ESRS assessment...' });
      const res = await nhost.functions.call<
        { duplicatedAssessment: UpsertEsrsAssessmentMutation_['insert_EsrsAssessment_one'] },
        EsrsAssessmentDuplicationRequest
      >('duplicate-esrs-assessment', {
        assessmentId: assessmentId,
        newReportingYear: newReportingYear,
        duplicationOptions: duplicationOptions || defaultEsrsDuplicationOptions,
      });

      if (res.error) {
        console.error(res.error);
        throw new Error(res.error.message);
      }

      client.refetchQueries({ include: [EsrsAssessmentsDocument_] });
      toast({
        text: `Assessment duplicated successfully for year ${res?.res?.data.duplicatedAssessment?.reportingYear}`,
      });
    } catch (error) {
      console.error(error);
      toast({
        text: 'Failed to duplicate assessment',
        variant: 'danger',
      });
    }
  };

  return duplicateAssessment;
};
