import { format, getYear, parse } from 'date-fns';
import { UseTranslationResponse } from 'react-i18next';
import { Namespace } from 'i18next';

export function formatDateTime(date: Date | string | number) {
  return format(new Date(date), 'Pp');
}

export const formatDate = (date: Date | string) => format(new Date(date), 'P');

export const formatDateToDDMMYYYY = (dateString: string) => {
  const [month, day, year] = dateString.split('/');
  return `${day}/${month}/${year}`;
};

export const dateToString = (d?: Date) => {
  if (!d) return new Date().toISOString().slice(0, 10);
  return d.toISOString().slice(0, 10);
};

export const dateToYYYYMMDD = (date: Date) => {
  const userTimezoneOffset = date.getTimezoneOffset() * 60000;
  const stringDate = new Date(date.getTime() - userTimezoneOffset);
  return format(stringDate, 'yyyy-MM-dd');
};

export const stringToDate = (s = '', formatString = 'yyyy-mm-dd') => {
  try {
    return parse(s, formatString, new Date());
  } catch (e) {
    return new Date();
  }
};

export const stringToYear = (s: string) => getYear(stringToDate(s));

export const timeSinceDate = (
  date: Date,
  translations: UseTranslationResponse<Namespace<string>, string>
) => {
  const currentDate = new Date();
  const diffTime = Math.abs(date.valueOf() - currentDate.valueOf());
  const days = diffTime / (24 * 60 * 60 * 1000);
  const hours = (days % 1) * 24;
  const minutes = (hours % 1) * 60;
  const secs = (minutes % 1) * 60;
  const weeks = days / 7;
  const { t } = translations;

  if (weeks >= 1) {
    return date.toLocaleDateString(undefined, { month: 'short', day: '2-digit', year: 'numeric' });
  }
  if (days > 1) {
    return Math.floor(days) + t('timeSinceDate.days');
  }
  if (hours > 1) {
    return Math.floor(hours) + t('timeSinceDate.hours');
  }
  if (minutes > 1) {
    return Math.floor(minutes) + t('timeSinceDate.minutes');
  }
  if (secs < 1) {
    return t('timeSinceDate.justNow');
  }
  return Math.floor(secs) + t('timeSinceDate.seconds');
};

export const timeUntil = (date: Date) => {
  const distance = date.valueOf() - new Date().valueOf();
  const days = Math.floor(distance / (1000 * 60 * 60 * 24));
  return days < 0 ? `${Math.abs(days)} days overdue` : `${days} days left`;
};

export enum Month {
  Jan = 0,
  Feb,
  Mar,
  Apr,
  May,
  Jun,
  Jul,
  Aug,
  Sep,
  Oct,
  Nov,
  Dec,
}
