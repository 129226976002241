import { Box, HStack, useDisclosure, VStack } from '@chakra-ui/react';
import { Button, EmptyState, Infobox, Tag } from 'Atoms';
import { useEffect, useMemo, useState } from 'react';
import { DisclosureRequirementTitle } from '../DisclosureRequirementsUtilsComponents';
import { useCompanyType } from 'utils/hooks';
import { LearnMoreDrawer, Loader, SearchInput } from 'Molecules';
import { useNavigate, useParams } from 'react-router-dom';
import { ActionsTable } from './ActionsTable';
import { ListIcon } from 'Tokens/Icons/Data';
import { useGetActionsData } from './Actions.hooks';
import { AddIcon } from 'Tokens/Icons/Function';
import { Typography } from 'Tokens';
import { ActionDisclosureRequirement } from './ActionDisclosureRequirement';
import { ActionFieldsFragment_, useGetOnboardingEsrsAssessmentQuery } from 'models';
import { AddActionDrawer } from './AddAction';
import {
  useAssessmentReportingUnits,
  useMaterialStandardId,
} from 'containers/Esrs/EsrsAssessment.hooks';
import { ACTIONS_TABS_LABEL, ActionViewTabs, TAB_INFO } from './ActionsUtils';

export const Actions = () => {
  const { standardRef = '', esrsAssessmentId = '', disclosureRequirementRef = '' } = useParams();
  const { onOpen: onDrawerOpen, isOpen: isDrawerOpen, onClose: onDrawerClose } = useDisclosure();
  const { reportingUnitAssessments: reportingUnits } =
    useAssessmentReportingUnits(esrsAssessmentId);
  const { onOpen: onActionOpen, isOpen: isActionOpen, onClose: onActionClose } = useDisclosure();
  const { companyAssessmentId } = useMaterialStandardId(standardRef, esrsAssessmentId);
  const { data: esrsAssessment } = useGetOnboardingEsrsAssessmentQuery({
    variables: {
      esrsAssessmentId,
    },
  });
  const [selectedAction, setSelectedAction] = useState<ActionFieldsFragment_>();
  const [searchValue, setSearchValue] = useState('');
  const { companyType, loading: companyLoading } = useCompanyType();
  const isGroup = useMemo(() => companyType === 'group-company', [companyType]);
  const [selectedView, setSelectedView] = useState<ActionViewTabs>(
    ActionViewTabs.currentCompanySub
  );

  useEffect(() => {
    setSelectedView(
      isGroup ? ActionViewTabs.currentCompanyParent : ActionViewTabs.currentCompanySub
    );
  }, [isGroup]);

  const navigate = useNavigate();

  const hasParent = useMemo(() => {
    return esrsAssessment?.esrsAssessment?.parentAssessment !== null;
  }, [esrsAssessment]);

  const [showAll, setShowAll] = useState(false);

  const { actionDR, combinedData, loading } = useGetActionsData();

  const { title = '', description = '' } = actionDR || {};

  const [filteredData, shouldShowButton] = useMemo(() => {
    const actionsData = combinedData
      .filter((data) => {
        if (
          selectedView === ActionViewTabs.currentCompanyParent ||
          selectedView === ActionViewTabs.currentCompanySub
        )
          return !data.isSubOrParent;
        return data.isSubOrParent;
      })
      .filter((data) =>
        searchValue ? data.title.toLocaleLowerCase().search(searchValue) !== -1 : true
      );
    const shouldShow = (actionsData?.length ?? 0) > 3;
    return [showAll ? actionsData : actionsData.slice(0, 3), shouldShow];
  }, [searchValue, selectedView, combinedData, showAll]);

  const infoBoxText = useMemo(() => {
    return TAB_INFO[selectedView]
      .replace('CompanyName', esrsAssessment?.esrsAssessment?.company.name ?? '')
      .replace('ParentName', esrsAssessment?.esrsAssessment?.parentAssessment?.company.name ?? '');
  }, [selectedView]);

  if (loading || companyLoading) {
    return <Loader />;
  }

  return (
    <VStack spacing="20px" alignItems="start" w="100%">
      <DisclosureRequirementTitle
        title="Action plan"
        reference={actionDR?.reference}
        onOpen={onDrawerOpen}
      />
      <VStack spacing="40px" alignItems="start" w="100%">
        <VStack spacing="6px" alignItems="start" w="100%">
          <Typography
            variant="h3"
            borderBottom="1px solid"
            w="100%"
            borderColor="border.decorative"
            paddingBottom="8px"
            marginBottom="12px"
          >
            Manage action plan
          </Typography>
          {!combinedData.length ? (
            <EmptyState
              description="Transform your ambitions into tangible results by creating an action plan with defined owners, and impact on your targets"
              icon={<ListIcon boxSize="20px" color="text.hint" />}
              variant="white"
              component={true}
              callToAction={{
                text: 'Add action',
                variant: 'ghost',
                leftIcon: <AddIcon />,
                onClick: () => {
                  navigate('action-form');
                },
              }}
            />
          ) : (
            <VStack alignItems="start" spacing="16px" w="100%">
              <VStack alignItems="start" spacing="12px" w="100%">
                <HStack justifyContent="space-between" w="100%">
                  <HStack>
                    <Box>
                      <SearchInput
                        search={searchValue}
                        setSearch={setSearchValue}
                        placeholder="Filter"
                        withLeftIcon={false}
                        minW="200px"
                        height="28px"
                      />
                    </Box>

                    {(hasParent || isGroup) &&
                      Object.values(ActionViewTabs)
                        .filter((tab) =>
                          isGroup
                            ? tab !== ActionViewTabs.parentCompany &&
                              tab !== ActionViewTabs.currentCompanySub
                            : tab !== ActionViewTabs.subsidiaries &&
                              tab !== ActionViewTabs.currentCompanyParent
                        )
                        .map((tab) => (
                          <Tag
                            isSelected={selectedView === tab ? true : false}
                            size="sm"
                            title={ACTIONS_TABS_LABEL[tab]}
                            clickable={true}
                            onClick={() => setSelectedView(tab)}
                          />
                        ))}
                  </HStack>
                  <Button
                    variant="primary"
                    size="sm"
                    onClick={() => {
                      navigate('action-form');
                    }}
                  >
                    Add action
                  </Button>
                </HStack>
                <Box w="100%">
                  <Infobox
                    status="neutral"
                    withIcon={false}
                    description={infoBoxText}
                    closable={false}
                  />
                </Box>
              </VStack>
              <VStack alignItems="start" w="100%">
                <ActionsTable
                  data={filteredData}
                  currentTab={selectedView}
                  setSelectedAction={setSelectedAction}
                  onActionOpen={onActionOpen}
                  isSearch={!!searchValue}
                />
                {shouldShowButton && (
                  <Button variant="ghost" onClick={() => setShowAll(!showAll)}>
                    {showAll ? 'Hide' : 'Show all...'}
                  </Button>
                )}
              </VStack>
            </VStack>
          )}
        </VStack>
        <VStack alignItems="start" width="100%">
          <ActionDisclosureRequirement />
        </VStack>
      </VStack>
      <AddActionDrawer
        isOpen={isActionOpen}
        onClose={onActionClose}
        actionToEdit={selectedAction}
        disclosureRequirementRef={disclosureRequirementRef}
        reportingUnits={reportingUnits}
        isReadOnly
        isParentOrSub
        isGroup={isGroup}
        materialityAssessmentId={companyAssessmentId}
      />
      <LearnMoreDrawer
        header={title}
        isOpen={isDrawerOpen}
        onClose={onDrawerClose}
        description={description ?? ''}
      />
    </VStack>
  );
};
