import { Box, Grid, VStack } from '@chakra-ui/react';
import { EmptyState } from 'Atoms';
import { Loader, Select } from 'Molecules';
import { NothingFoundIllustration, Typography } from 'Tokens';
import { RefreshIcon } from 'Tokens/Icons/Function';
import { useMemo, useState } from 'react';
import { useTranslation } from 'utils/translation';
import { MATERIAL_TOPIC_FILTER_OPTIONS } from '../Assessment/EsrsOverview';
import { GroupStandardProgress } from '../Assessment/StandardProgress/StandardProgress';
import { useGetGroupStandardsOverview } from './GroupAssessmentOverview.hooks';

export const GroupTopicsOverview = ({ esrsAssessmentId }: { esrsAssessmentId: string }) => {
  const { t } = useTranslation();
  const [filter, setFilter] = useState('all');

  const { categories, loading } = useGetGroupStandardsOverview(filter, esrsAssessmentId);
  const { categories: allCategories } = useGetGroupStandardsOverview('all', esrsAssessmentId);

  const isMaterialityMatchingForAllSubsidiaries = useMemo(() => {
    const allStandards = allCategories.flatMap((category) => category.materialStandards);
    return allStandards.every(
      (standard) => standard.isMaterialForSubsidiary && standard.isMaterialForGroup
    );
  }, [allCategories]);

  const isEmptyFilter = useMemo(
    () => categories.filter((c) => c.materialStandards.length).length === 0,
    [categories]
  );

  if (loading) return <Loader />;

  return (
    <VStack alignItems="start" spacing="40px" mt="24px" flexGrow="1">
      {!isMaterialityMatchingForAllSubsidiaries && (
        <Box width="280px">
          <Select<{ value: string; label: string }>
            value={{
              label: MATERIAL_TOPIC_FILTER_OPTIONS.find((v) => v.value === filter)?.label ?? '',
              value: filter,
            }}
            onChange={(value) => setFilter(value?.value ? value.value : 'all')}
            options={MATERIAL_TOPIC_FILTER_OPTIONS.map((currOption) => {
              return {
                label: currOption?.label ?? '',
                value: currOption.value,
              };
            })}
            size="md"
          />
        </Box>
      )}

      {isEmptyFilter && (
        <Box w="100%" flexGrow="1">
          <EmptyState
            title={t('common:search.filter.emptyTitle')}
            description={t('common:search.filter.emptyDescription')}
            icon={<NothingFoundIllustration boxSize="120px" />}
            callToAction={{
              text: t('common:search.filter.emptyBtn'),
              variant: 'secondary',
              onClick: () => {
                setFilter('all');
              },
              leftIcon: <RefreshIcon color="inherit" />,
            }}
            component={true}
          />
        </Box>
      )}

      {categories.map((category, categoryIndex) => (
        <>
          {category.materialStandards.length > 0 && (
            <VStack key={category.categoryRef} alignItems="start" width="100%">
              <Typography variant="overline">{category.categoryName}</Typography>
              <Grid templateColumns="repeat(3, 1fr)" overflow="visible" gap="12px" w="full">
                {category.materialStandards.map((standard, standardIndex) => (
                  <GroupStandardProgress
                    standard={standard}
                    standardIndex={standardIndex}
                    categoryIndex={categoryIndex}
                  />
                ))}
              </Grid>
            </VStack>
          )}
        </>
      ))}
    </VStack>
  );
};
