import { Box, HStack } from '@chakra-ui/react';
import { Tag } from 'Atoms';
import { AvatarGroup } from 'Molecules/AvatarGroup';
import { Typography } from 'Tokens';
import { ChevronDownIcon } from 'Tokens/Icons/Direction';
import React from 'react';
import { useTranslation } from 'utils/translation';

type SharedWithCompanies = {
  name: string;
  hasPaiAccess?: boolean;
  logoUrl?: string | null | undefined;
}[];

export const ShareButton = ({
  isShared,
  onClick,
  companiesSharedWith,
  size = 'sm',
}: {
  isShared: boolean;
  onClick?: () => void;
  companiesSharedWith: SharedWithCompanies;
  size?: string;
}) => {
  const { t } = useTranslation(['common']);

  return (
    <Tag
      rightIcon={<ChevronDownIcon />}
      size={size}
      backgroundColor={isShared ? 'bg.interactive' : 'bg.default'}
      onClick={(e) => {
        if (onClick) onClick();
        e.stopPropagation();
      }}
      clickable={onClick ? true : false}
      paddingInline="8px"
    >
      <HStack spacing="6px" alignItems="center">
        {isShared ? (
          <HStack>
            <Typography variant="bodyStrong">{t('common:assessmentTable.shared')}</Typography>
            <Box h="20px">
              <AvatarGroup
                isRounded={false}
                size="xs"
                key="investors-avatars"
                namesOrLogos={companiesSharedWith.map((company) => ({
                  name: company.name,
                  logoUrl: company.logoUrl ?? undefined,
                }))}
              />
            </Box>
          </HStack>
        ) : (
          <Typography variant="bodyStrong">{t('common:assessmentTable.notShared')}</Typography>
        )}
      </HStack>
    </Tag>
  );
};
