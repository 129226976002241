import { Box, HStack } from '@chakra-ui/react';
import { ColumnDefResolved } from '@tanstack/react-table';
import { Avatar, Button, IconButton, TruncatableText } from 'Atoms';
import { HelpTooltip } from 'Molecules';
import { NestedTable, TableData } from 'Molecules/NestedTable';
import {
  ArrowCornerDownRight,
  ArrowNarrowRightIcon,
  ChevronDownIcon,
  ChevronRightIcon,
} from 'Tokens/Icons/Direction';
import { SelectedMetric } from 'containers/Esrs';
import { mapUnitToCompanyCurrency } from 'containers/Esrs/utils';
import { isEqual } from 'lodash';
import { User } from 'models';
import { Dispatch, SetStateAction, useMemo } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { QuartersEnums, TimePeriods, TimePeriodsEnums } from '../../Requirement';
import {
  MetricsTableData,
  areArraysOfObjectsEqual,
  isFrequencyYearly,
} from '../MetricAnswers.hooks';
import {
  AnswerApprovalCell,
  MetricRowTitle,
  getLastEditedData,
  searchForParentMetric,
} from './MetricsUtils';
import { MetricMdrmStatus } from './MetricMDRMStatus';
import { MetricOwnerAvatar } from './MetricOwnerSelect';
import { MetricProgress } from './MetricProgress';
import { MetricYear } from './MetricYear';
import { QuarterInput } from './QuarterInput';
import { GeneratedAnswer } from '../MetricAI/MetricsAITypes';
import { Typography } from 'Tokens';
import { AIIcon } from 'Tokens/Icons/Function';
import { LastEditedBy } from './LastEditedBy';
import { MetricsAttachmentsLabel } from './MetricAttachmentsLabel';
import { MetricCheckboxSelector } from './MetricCheckboxSelector';

export const MetricsInputTable = ({
  metrics,
  selectedQuarter,
  onDrawerOpen,
  onOpen,
  setSelectedMetric,
  companyStandardId,
  isOverview,
  areMetricsYearly,
  currency,
  companyReportingUnit,
  isGroup,
  esrsAssessmentProjectLeader,
  rowData,
  setRowData,
  pageSize = 5,
  filteredMetrics,
  setNumberOfRows,
  isMetricDr,
  selectedRows,
  setSelectedRows,
  isAI = false,
  generatedAnswers,
  setGeneratedAnswers,
  populateQuantitativeAnswers,
}: {
  metrics: MetricsTableData[];
  selectedQuarter: TimePeriods;
  onDrawerOpen: () => void;
  onOpen: () => void;
  setSelectedMetric: (param: SelectedMetric) => void;
  companyStandardId: string;
  isOverview: boolean;
  areMetricsYearly: boolean;
  currency: string;
  companyReportingUnit?: string;
  isCompanyLevel?: boolean;
  isGroup?: boolean;
  esrsAssessmentProjectLeader?: Partial<User>;
  rowData?: MetricsTableData;
  setRowData: (param: MetricsTableData | undefined) => void;
  pageSize?: number;
  filteredMetrics?: MetricsTableData[];
  setNumberOfRows: React.Dispatch<React.SetStateAction<Record<string, number>>>;
  isMetricDr: boolean;
  selectedRows?: MetricsTableData[];
  setSelectedRows?: Dispatch<SetStateAction<MetricsTableData[]>>;
  isAI?: boolean;
  generatedAnswers?: GeneratedAnswer[];
  setGeneratedAnswers?: Dispatch<SetStateAction<GeneratedAnswer[]>>;
  populateQuantitativeAnswers?: (
    generatedAnswers: GeneratedAnswer[],
    numericMetrics: MetricsTableData[]
  ) => void;
}) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const urlDatapointId = useMemo(() => searchParams.get('datapointId'), [searchParams]);
  const openDrawer = new URLSearchParams(location.search).get('openDrawer');

  const isYearly = useMemo(
    () => isFrequencyYearly(metrics[0], companyStandardId),
    [metrics, companyStandardId]
  );

  const nestedColumns: ColumnDefResolved<TableData<MetricsTableData>, any>[] | undefined =
    useMemo(() => {
      const goToSource = (ref: string) => {
        const refElement = document.getElementById(ref);
        if (refElement) {
          setTimeout(() => {
            refElement.scrollIntoView({
              behavior: 'smooth',
              inline: 'center',
            });
          });
        } else {
          const mainParent = searchForParentMetric(filteredMetrics ?? [], ref);
          const element = document.getElementById(mainParent?.metric.reference ?? '');
          if (element) {
            setTimeout(() => {
              element.scrollIntoView({
                behavior: 'smooth',
                inline: 'center',
              });
            });
          }
          // expand main parent
          setNumberOfRows((prevStates) => ({
            ...prevStates,
            [mainParent?.metric.reference ?? '']:
              (prevStates[mainParent?.metric.reference ?? ''] || 5) + 100,
          }));
        }
      };

      const handleRowClick = (row: MetricsTableData) => {
        if (!row.referenceToSource) {
          if (openDrawer && !!urlDatapointId) navigate(pathname.split('?')[0]);
          if (isEqual(rowData, row)) {
            setRowData(undefined);
          } else setRowData(row);
        }
      };

      const columns: ColumnDefResolved<TableData<MetricsTableData>, any>[] = [];

      if (isAI && selectedRows && setSelectedRows) {
        columns.push({
          header: '',
          meta: {
            width: '32px',
            padding: '16px 8px',
            verticalAlign: 'top',
          },
          accessorKey: 'selectedRows',
          cell: ({ row }) => (
            <MetricCheckboxSelector
              row={row.original}
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
            />
          ),
        });
      }

      columns.push(
        {
          id: 'expander',
          meta: {
            width: '36px',
            padding: '6px 0px',
            verticalAlign: 'top',
          },
          cell: ({ row }) =>
            row.getCanExpand() &&
            !row.original.referenceToSource && (
              <Box onClick={(e) => e.stopPropagation()}>
                <IconButton
                  variant={'ghost'}
                  size="md"
                  onClick={row.getToggleExpandedHandler()}
                  aria-label="expand"
                  icon={row.getIsExpanded() ? <ChevronDownIcon /> : <ChevronRightIcon />}
                />
              </Box>
            ),
        },
        {
          header: 'Metric',
          meta: {
            width: isAI ? '30%' : '40%',
            padding: '4px 8px',
            verticalAlign: 'top',
          },
          accessorKey: 'metric',
          cell: ({ row }) => {
            return row.original.referenceToSource ? (
              <HStack pl={`${row.depth * 24}px`} spacing="4px">
                <ArrowCornerDownRight color="text.default" />
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={() => goToSource(row.original.referenceToSource ?? '')}
                >
                  Go to sub-metrics
                </Button>
                <HelpTooltip
                  placement="bottom"
                  label={
                    'This metric is calculated using several sub-metrics that have been previously reported. For details, go to sub-metrics'
                  }
                />
              </HStack>
            ) : (
              <HStack
                spacing="8px"
                id={
                  !row.subRows.some((subrow) => subrow.original.referenceToSource)
                    ? row.original.metric.reference
                    : undefined
                }
              >
                <MetricRowTitle
                  companyStandardId={companyStandardId}
                  rowData={rowData}
                  row={row}
                  onClick={() => handleRowClick(row.original)}
                />
              </HStack>
            );
          },
        }
      );

      if (!isAI) {
        columns.push({
          header: 'Unit',
          accessorKey: 'unit',
          meta: {
            padding: '14px 8px',
            verticalAlign: 'top',
          },
          cell: ({ row }) =>
            !row.original.referenceToSource && (
              <TruncatableText
                variant="body"
                text={mapUnitToCompanyCurrency(row.original.metric?.unitOfMeasurement, currency)}
              />
            ),
        });
      }

      columns.push(
        {
          header: QuartersEnums.q1,
          accessorKey: 'quarter1',
          meta: {
            width: '18%',
            padding: '6px 8px',
            verticalAlign: 'top',
          },
          cell: ({ row }) =>
            !row.original.referenceToSource &&
            row.index < pageSize && (
              <QuarterInput
                row={row.original}
                isOverview={isOverview}
                selectedQuarter={QuartersEnums.q1}
                companyStandardId={companyStandardId}
                companyReportingUnit={companyReportingUnit}
                assessmentProjectLeaderId={esrsAssessmentProjectLeader?.id}
                nestedMetrics={metrics}
              />
            ),
        },
        {
          header: QuartersEnums.q2,
          accessorKey: 'quarter2',
          meta: {
            width: '18%',
            padding: '6px 8px',
            verticalAlign: 'top',
          },
          cell: ({ row }) =>
            !row.original.referenceToSource &&
            row.index < pageSize && (
              <QuarterInput
                row={row.original}
                isOverview={isOverview}
                selectedQuarter={QuartersEnums.q2}
                companyStandardId={companyStandardId}
                companyReportingUnit={companyReportingUnit}
                assessmentProjectLeaderId={esrsAssessmentProjectLeader?.id}
                nestedMetrics={metrics}
              />
            ),
        },
        {
          header: QuartersEnums.q3,
          accessorKey: 'quarter3',
          meta: {
            width: '18%',
            padding: '6px 8px',
            verticalAlign: 'top',
          },
          cell: ({ row }) =>
            !row.original.referenceToSource &&
            row.index < pageSize && (
              <QuarterInput
                row={row.original}
                isOverview={isOverview}
                selectedQuarter={QuartersEnums.q3}
                companyStandardId={companyStandardId}
                companyReportingUnit={companyReportingUnit}
                assessmentProjectLeaderId={esrsAssessmentProjectLeader?.id}
                nestedMetrics={metrics}
              />
            ),
        },
        {
          header: QuartersEnums.q4,
          accessorKey: 'quarter4',
          meta: {
            width: '18%',
            padding: '6px 8px',
            verticalAlign: 'top',
          },
          cell: ({ row }) =>
            !row.original.referenceToSource &&
            row.index < pageSize && (
              <QuarterInput
                row={row.original}
                isOverview={isOverview}
                selectedQuarter={QuartersEnums.q4}
                companyStandardId={companyStandardId}
                companyReportingUnit={companyReportingUnit}
                assessmentProjectLeaderId={esrsAssessmentProjectLeader?.id}
                nestedMetrics={metrics}
              />
            ),
        },
        {
          header: selectedQuarter !== TimePeriodsEnums.year ? selectedQuarter : 'Total',
          accessorKey: 'quarter', // Used as "YEAR" when selectedQuarter is year on input view!
          meta: {
            width: '18%',
            padding: '6px 8px',
            verticalAlign: 'top',
          },
          cell: ({ row }) =>
            !row.original.referenceToSource &&
            row.index < pageSize && (
              <QuarterInput
                row={row.original}
                isOverview={isOverview}
                selectedQuarter={selectedQuarter}
                companyStandardId={companyStandardId}
                companyReportingUnit={companyReportingUnit}
                assessmentProjectLeaderId={esrsAssessmentProjectLeader?.id}
                setRowData={setRowData}
                nestedMetrics={metrics}
              />
            ),
        }
      );

      if (selectedQuarter === TimePeriodsEnums.year || isOverview || isGroup || isAI) {
        columns.push({
          header: isAI ? 'Answer' : 'Total',
          accessorKey: 'yearly',
          meta: {
            padding: '14px 8px',
            verticalAlign: 'top',
          },
          cell: ({ row }) => {
            const generatedAnswer = generatedAnswers?.find(
              (a) =>
                a.metricRef === row.original.metric.reference &&
                areArraysOfObjectsEqual(a.tags, row.original.tags)
            );

            return (
              !row.original.referenceToSource && (
                <MetricYear
                  row={row.original}
                  companyStandardId={companyStandardId}
                  companyReportingUnit={companyReportingUnit}
                  nestedMetrics={metrics}
                  generatedAnswer={generatedAnswer}
                  isAI={isAI}
                />
              )
            );
          },
        });
      }

      if (isOverview && !isYearly && !isAI) {
        columns.push({
          header: 'Collected',
          meta: {
            width: '8%',
            padding: '14px 8px',
            verticalAlign: 'top',
          },
          accessorKey: 'collected',
          cell: ({ row }) =>
            !row.original.referenceToSource && (
              <MetricProgress
                row={row.original}
                companyStandardId={companyStandardId}
                companyReportingUnit={companyReportingUnit}
              />
            ),
        });
      }

      if (isMetricDr && !isAI) {
        columns.push({
          header: 'MDR-M',
          meta: {
            width: '10%',
            padding: '14px 8px',
            verticalAlign: 'top',
          },
          accessorKey: 'mdr-m',
          cell: ({ row }) =>
            !row.original.referenceToSource && (
              <MetricMdrmStatus
                row={row.original}
                companyReportingUnit={companyReportingUnit}
                selectedQuarter={selectedQuarter}
                materialStandardId={companyStandardId}
              />
            ),
        });
      }

      if (isAI) {
        columns.push({
          header: 'Source',
          meta: {
            width: '10%',
            padding: '14px 8px',
            verticalAlign: 'top',
          },
          accessorKey: 'source',
          cell: ({ row }) => {
            return (
              <MetricsAttachmentsLabel
                row={row.original}
                companyReportingUnitId={companyReportingUnit}
              />
            );
          },
        });
      }

      if (isAI) {
        columns.push({
          header: 'Last edited',
          accessorKey: 'lastEdited',
          meta: {
            width: '10%',
            padding: '14px 8px',
            verticalAlign: 'top',
          },
          cell: ({ row }) => {
            const generatedAnswer = generatedAnswers?.find(
              (a) =>
                a.metricRef === row.original.metric.reference &&
                areArraysOfObjectsEqual(a.tags, row.original.tags)
            );
            const isAwaitingApproval = generatedAnswer?.answer && generatedAnswer?.status === null;

            return (
              !row.original.referenceToSource &&
              (isAwaitingApproval ? (
                <HStack spacing="4px">
                  <Avatar name="AI" size="xs" />
                  <Typography variant="body">{getLastEditedData(new Date()).date}</Typography>
                </HStack>
              ) : (
                <LastEditedBy
                  row={row.original}
                  companyStandardId={companyStandardId}
                  selectedQuarter={selectedQuarter}
                  reportingUnitId={companyReportingUnit ? companyReportingUnit : undefined}
                />
              ))
            );
          },
        });
      }

      columns.push(
        {
          header: 'Owner',
          meta: {
            width: '10%',
            padding: '8px 8px',
            verticalAlign: 'top',
          },
          accessorKey: 'ownerId',
          cell: ({ row }) =>
            !row.original.referenceToSource && (
              <MetricOwnerAvatar
                row={row.original}
                selectedQuarter={selectedQuarter}
                companyReportingUnit={companyReportingUnit}
                assessmentProjectLeader={esrsAssessmentProjectLeader}
              />
            ),
        },
        {
          header: '',
          meta: {
            width: '5%',
            padding: '6px 8px',
            verticalAlign: 'top',
          },
          accessorKey: 'actions',
          cell: ({ row }) => {
            return (
              !row.original.referenceToSource && (
                <IconButton
                  className="metricArrow"
                  aria-label="side-bar"
                  variant="ghost"
                  icon={<ArrowNarrowRightIcon />}
                  onClick={() => handleRowClick(row.original)}
                />
              )
            );
          },
        }
      );

      // AI Approval
      if (isAI && !!generatedAnswers?.length) {
        columns.push({
          // @ts-ignore
          header: () => {
            return (
              <HStack spacing="8px">
                <AIIcon />
                <Typography variant="bodyStrong">Approve</Typography>
              </HStack>
            );
          },
          meta: {
            padding: '10px 8px',
            verticalAlign: 'top',
          },
          accessorKey: 'ai-approval',
          cell: ({ row }) => {
            const generatedAnswer = generatedAnswers?.find(
              (a) =>
                a.metricRef === row.original.metric.reference &&
                areArraysOfObjectsEqual(a.tags, row.original.tags)
            );

            const isGeneratedAnswerValid =
              generatedAnswer?.answer && generatedAnswer.answer !== 'NA';

            return (
              !row.original.referenceToSource &&
              isGeneratedAnswerValid && (
                <AnswerApprovalCell
                  row={row.original}
                  populateQuantitativeAnswers={populateQuantitativeAnswers}
                  generatedAnswers={generatedAnswers ?? []}
                  setGeneratedAnswers={setGeneratedAnswers}
                  companyReportingUnitId={companyReportingUnit}
                  assessmentProjectLeaderId={esrsAssessmentProjectLeader?.id}
                />
              )
            );
          },
        });
      }

      return columns;
    }, [
      selectedQuarter,
      onDrawerOpen,
      onOpen,
      setSelectedMetric,
      companyStandardId,
      isOverview,
      areMetricsYearly,
      isMetricDr,
      pageSize,
      selectedRows,
      generatedAnswers,
    ]);

  const filteredColumns = useMemo(() => {
    const quarterFields = ['quarter1', 'quarter2', 'quarter3', 'quarter4'];
    if (isOverview) {
      if (areMetricsYearly) {
        return nestedColumns.filter(
          (col) => col.accessorKey !== 'quarter' && !quarterFields.includes(col.accessorKey ?? '')
        );
      }
      return nestedColumns.filter((col) => col.accessorKey !== 'quarter');
    }
    if (selectedQuarter === TimePeriodsEnums.year)
      return nestedColumns.filter(
        (column) =>
          !quarterFields.includes(column.accessorKey ?? '') && column.accessorKey !== 'yearly'
      );
    return nestedColumns.filter((col) => !quarterFields.includes(col.accessorKey ?? ''));
  }, [nestedColumns, selectedQuarter]);

  return (
    <NestedTable<MetricsTableData>
      columns={filteredColumns}
      data={metrics}
      expanded={true}
      pageSize={pageSize}
      rowProps={(row) => {
        const generatedAnswer = generatedAnswers?.find(
          (a) => a.metricRef === row?.metric.reference && areArraysOfObjectsEqual(a.tags, row?.tags)
        )?.answer;

        const getBackgroundColor = () => {
          if (isEqual(row, rowData)) {
            return 'bg.hover';
          }
          if (generatedAnswer) {
            if (generatedAnswer === 'NA') {
              return 'bg.warning';
            }
            return 'bg.selected';
          }
          return '';
        };

        return {
          bg: getBackgroundColor(),
          transition: '0.1s',
          _hover: {
            transition: '0.15s ease-in-out',
            bg: 'bg.hover',
            '&:hover .metricTitle': {
              textDecoration: 'underline',
              textDecorationColor: 'text.hint',
              textUnderlineOffset: '2px',
            },
            '&:hover .metricArrow': {
              bg: 'bg.hover',
            },
          },
        };
      }}
    />
  );
};
