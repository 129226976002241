import { ActivityReport, COMPANY_LEVEL, TableSpecificDataType } from './History.hooks';
import { formatTimestamp } from 'Molecules/HistoryItem/HistoryHelpers';
import { AuditLogEntryFieldsFragment_, CompanyAssessmentContextQuery_ } from 'models';
import { isArray } from 'lodash';
import { getNameExtension } from 'utils/files';

export const generalAuditLogsCases = ({
  contextData,
  auditLog,
}: {
  contextData: CompanyAssessmentContextQuery_;
  auditLog: AuditLogEntryFieldsFragment_;
}): TableSpecificDataType => {
  const { table, newData, timestamp, oldData } = auditLog;
  const context = contextData?.company;
  let contextualInfo = '';
  let metaInfo: { [key: string]: string } = {};
  let dataChanges = null;
  const operation =
    auditLog?.operation === 'UPDATE' && oldData?.deletedAt === null && newData?.deletedAt !== null
      ? 'DELETE'
      : auditLog?.operation;

  switch (table) {
    case 'Note': {
      dataChanges = [];
      //screening notes
      const noteContextScreening = context?.bAssesssments?.find((bAssessment) =>
        bAssessment?.activityReports?.some((activityReport) =>
          activityReport?.answers?.some((a) =>
            a?.noteHistory?.notes.some((note) => note.id === newData.id)
          )
        )
      );
      const noteContextScreeningActivity = context?.bAssesssments?.find((bAssessment) =>
        bAssessment?.activityReports?.some((activityReport) =>
          activityReport?.noteHistory?.notes.some((note) => note?.id === newData.id)
        )
      );
      const activityReport =
        noteContextScreening?.activityReports?.find((ar) =>
          ar?.answers?.some((a) => a?.noteHistory?.notes.some((note) => note.id === newData.id))
        ) ??
        noteContextScreeningActivity?.activityReports.find((ar) =>
          ar?.noteHistory?.notes.some((note) => note?.id === newData.id)
        ) ??
        ({} as ActivityReport);

      //financials notes
      const noteContextFinancials = context?.bAssesssments?.find((bAssessment) =>
        bAssessment?.activityReports?.some((ar) =>
          ar?.financials?.noteHistory?.notes.some((note) => note?.id === newData.id)
        )
      );
      const noteContextTotalFinancials = context?.totalFinancials?.noteHistory?.notes.find(
        (note) => note?.id === newData.id
      );

      const answer = noteContextScreening?.activityReports
        ?.flatMap((ar) => ar.answers || [])
        .find((a) => a?.noteHistory?.notes.some((note) => note.id === newData.id));
      contextualInfo = answer?.question?.title ?? '';

      if (isArray(oldData?.body) || isArray(newData?.body)) {
        dataChanges.push({
          oldData: oldData?.body.join(', '),
          newData: newData?.body.join(', '),
        });
      } else {
        dataChanges.push({
          oldData: oldData?.body,
          newData: newData?.body,
        });
      }
      metaInfo = {
        timestamp: formatTimestamp(timestamp),
        location:
          noteContextScreening || noteContextScreeningActivity
            ? 'Screening'
            : noteContextFinancials || noteContextTotalFinancials
              ? 'Financials'
              : '',
        level:
          (noteContextScreening ?? noteContextFinancials ?? noteContextScreeningActivity)
            ?.businessUnit?.name ?? COMPANY_LEVEL,
        businessUnit: noteContextScreening
          ? activityReport?.activity?.name
          : noteContextScreeningActivity
            ? activityReport?.activity?.name
            : noteContextTotalFinancials
              ? 'Total Financials'
              : '',
      };
      break;
    }
    case 'Comment': {
      dataChanges = [];
      const commentContext = context?.bAssesssments?.find((bAssessment) =>
        bAssessment?.activityReports?.some((activityReport) =>
          activityReport?.answers?.some((a) =>
            a?.thread?.comments.some((comment) => comment.id === newData.id)
          )
        )
      );

      const activityReport =
        commentContext?.activityReports?.find((ar) =>
          ar?.answers?.some((a) => a?.thread?.comments.some((comment) => comment.id === newData.id))
        ) ?? ({} as ActivityReport);

      const answer = commentContext?.activityReports
        ?.flatMap((ar) => ar.answers || [])
        .find((a) => a?.thread?.comments.some((comment) => comment.id === newData.id));
      contextualInfo = answer?.question?.title ?? '';

      if (isArray(oldData?.data) || isArray(newData?.data)) {
        dataChanges.push({
          oldData: oldData?.data.join(', '),
          newData: newData?.data.join(', '),
        });
      } else {
        dataChanges.push({
          oldData: oldData?.data,
          newData: newData?.data,
        });
      }

      metaInfo = {
        timestamp: formatTimestamp(timestamp),
        location: 'Screening',
        level: commentContext?.businessUnit?.name ?? COMPANY_LEVEL,
        businessUnit: activityReport?.activity?.name,
      };
      break;
    }

    case 'Attachment': {
      dataChanges = [];
      const companyFiles = context?.company?.files;

      //screening attachments
      const attachmentContextScreening = context?.bAssesssments?.find((bAssessment) =>
        bAssessment?.activityReports?.some((activityReport) =>
          activityReport?.answers?.some((a) =>
            a?.attachmentBox?.attachments.some((attachment) => attachment.id === newData.id)
          )
        )
      );

      const attachmentContextScreeningActivity = context?.bAssesssments?.find((bAssessment) =>
        bAssessment?.activityReports?.some((activityReport) =>
          activityReport?.attachmentBox?.attachments.some(
            (attachment) => attachment?.id === newData.id
          )
        )
      );

      //financials attachments
      const attachmentContextFinancials = context?.bAssesssments?.find((bAssessment) =>
        bAssessment?.activityReports?.some((ar) =>
          ar?.financials?.attachmentBox?.attachments.some(
            (attachment) => attachment?.id === newData.id
          )
        )
      );
      const attachmentContextTotalFinancials =
        context?.totalFinancials?.attachmentBox?.attachments.find(
          (attachment) => attachment?.id === newData.id
        );

      const activityReport =
        attachmentContextScreening?.activityReports?.find((ar) =>
          ar?.answers?.some((a) =>
            a?.attachmentBox?.attachments.some((attachment) => attachment.id === newData.id)
          )
        ) ??
        attachmentContextScreeningActivity?.activityReports.find((ar) =>
          ar?.attachmentBox?.attachments.some((attachment) => attachment?.id === newData.id)
        ) ??
        ({} as ActivityReport);

      const answer = attachmentContextScreening?.activityReports
        ?.flatMap((ar) => ar.answers || [])
        .find((a) =>
          a?.attachmentBox?.attachments.some((attachment) => attachment.id === newData.id)
        );
      contextualInfo = answer?.question?.title ?? '';

      const oldFile = companyFiles?.find((companyFile) => companyFile.id === oldData?.fileId);
      const newFile = companyFiles?.find((companyFile) => companyFile.id === newData?.fileId);

      dataChanges.push({
        oldData: oldFile?.title ?? '',
        newData: newFile?.title ?? '',
        oldFileExtension: getNameExtension(oldFile?.storageFile?.name ?? '').extension,
        newFileExtension: getNameExtension(newFile?.storageFile?.name ?? '').extension,
      });

      metaInfo = {
        timestamp: formatTimestamp(timestamp),
        location:
          attachmentContextScreening || attachmentContextScreeningActivity
            ? 'Screening'
            : attachmentContextFinancials || attachmentContextTotalFinancials
              ? 'Financials'
              : '',
        level:
          (
            attachmentContextScreening ??
            attachmentContextFinancials ??
            attachmentContextScreeningActivity
          )?.businessUnit?.name ?? COMPANY_LEVEL,
        businessUnit: attachmentContextScreening
          ? activityReport?.activity?.name
          : attachmentContextScreeningActivity
            ? activityReport?.activity?.name
            : attachmentContextTotalFinancials
              ? 'Total Financials'
              : '',
      };
      break;
    }
  }
  return { contextualInfo, metaInfo, dataChanges, operation };
};
