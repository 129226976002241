import {
  Box,
  VStack,
  HStack,
  StackProps,
  SkeletonCircle,
  SkeletonText,
  Stack,
} from '@chakra-ui/react';
import React, { useState, useCallback, useMemo } from 'react';
import dayjs from 'dayjs';
import { TextareaWithMentions, TextWithMentions } from 'Organisms';
import { Comment, DatapointFieldsFragment_, useCompanyUsersQuery, User } from 'models';
import { useTranslation } from 'utils/translation';
import { useCurrentCompanyId } from 'utils/hooks';
import { Typography } from 'Tokens';
import { formatDateTime } from 'utils/date';
import { useUserData } from '@nhost/react';
import { DeleteIcon } from 'Tokens/Icons/Function';
import { useMetricsCommentThread } from './MetricSidebar.hooks';
import { Avatar, Button } from 'Atoms';
import { Menu } from 'Molecules/Menu';
import { TimePeriods, TimePeriodsEnums } from '../../Requirement';
import { MetricsTableData } from '../MetricAnswers.hooks';

type ThreadManagerType = ReturnType<typeof useMetricsCommentThread>;
type ThreadPopoverContentPropsType = Pick<ThreadManagerType, 'addComment' | 'deleteComment'> &
  NonNullable<Pick<NonNullable<ThreadManagerType['thread']>, 'comments'>>;

export const CommentItem = React.memo(function CommentItem({
  comment,
  onDelete,
}: {
  comment: Comment;
  onDelete?: () => void;
} & StackProps) {
  return (
    <VStack align="start" width="100%" padding="16px">
      <HStack spacing="10px" width="100%" align="start">
        <Avatar name={comment.author.displayName} size="sm" />
        <HStack justifyContent="space-between" align="start" w="100%">
          <VStack align="start">
            <VStack align="start" spacing="2px">
              <Typography variant="bodyStrong">{comment.author.displayName}</Typography>
              <Typography variant="detail" color="text.hint">
                {formatDateTime(comment.createdAt)}
              </Typography>
            </VStack>
            <TextWithMentions value={comment.data} />
          </VStack>
          {!!onDelete && (
            <Menu
              sections={[
                {
                  actions: [
                    {
                      id: 'delete',
                      title: 'Remove',
                      variant: 'destructive',
                      onClick: () => onDelete(),
                      leftElement: <DeleteIcon color="inherit" />,
                    },
                  ],
                },
              ]}
            />
          )}
        </HStack>
      </HStack>
    </VStack>
  );
});

export function ThreadBody({ addComment, deleteComment, comments }: ThreadPopoverContentPropsType) {
  const [comment, setComment] = useState<string>('');
  const { companyId } = useCurrentCompanyId();
  const user = useUserData();
  const { data } = useCompanyUsersQuery({
    variables: {
      id: companyId,
    },
    skip: !companyId,
  });
  const users = useMemo(() => data?.users ?? [], [data]);
  const { t } = useTranslation('common');

  const resetData = () => {
    setComment('');
  };

  const handleAddComment = () => {
    if (comment) {
      addComment(comment).then(resetData);
    }
  };
  return (
    <VStack w="100%" alignItems="start" spacing="0px">
      <VStack spacing="8px" align="start" px="20px" py="16px" w="100%">
        <TextareaWithMentions
          value={comment}
          users={users}
          placeholder={t('common:comments.placeholder')}
          onChange={(_, newValue) => {
            setComment(newValue);
          }}
        />
        <Button variant="primary" onClick={handleAddComment} isDisabled={!comment}>
          Comment
        </Button>
      </VStack>
      {comments.length > 0 && (
        <VStack overflowY="auto" maxHeight="50vh" align="start" spacing="0px" w="100%">
          {comments?.map((c) => (
            <CommentItem
              key={c.id}
              comment={c}
              onDelete={c.authorId === user?.id ? () => deleteComment(c.id) : undefined}
            />
          ))}
        </VStack>
      )}
    </VStack>
  );
}

export const MetricComments = ({
  datapoint,
  selectedQuarter,
  isYearly,
  assessmentProjectLeaderId,
  row,
  companyReportingUnit,
}: {
  datapoint: DatapointFieldsFragment_ | undefined;
  selectedQuarter: TimePeriods;
  isYearly: boolean;
  assessmentProjectLeaderId?: Partial<User>;
  row: MetricsTableData;
  companyReportingUnit?: string;
}) => {
  const count = datapoint?.thread?.comments_aggregate.aggregate?.count ?? 0;
  const skeletons = Array(count + 1).fill(0);
  const { addComment, deleteComment, thread, loading } = useMetricsCommentThread({
    id: datapoint?.thread?.id,
    esrsDatapointId: datapoint?.id,
    timeframe: isYearly ? TimePeriodsEnums.year : selectedQuarter,
    assessmentProjectLeaderId,
    row,
    companyReportingUnit,
  });
  const handleAddComment = useCallback(
    (comment: string) => {
      if (comment) {
        addComment(comment);
      }
      return Promise.resolve({});
    },
    [addComment]
  );
  const comments = useMemo(
    () => thread?.comments?.slice().sort((a, b) => dayjs(b.createdAt).diff(a.createdAt)) || [],
    [thread]
  );

  if (loading) {
    return (
      <Stack spacing="20px" p="16px 8px" flexGrow="1">
        {skeletons?.map((_, i) => (
          <HStack
            key={i}
            w="100%"
            padding="8px"
            borderRadius="4px"
            boxShadow="sm"
            spacing="10px"
            h="fit-content"
          >
            <SkeletonCircle size="8" />
            <Box w="80%" h="100%">
              <SkeletonText noOfLines={3} spacing="4" />
            </Box>
          </HStack>
        ))}
      </Stack>
    );
  }
  return (
    <ThreadBody addComment={handleAddComment} deleteComment={deleteComment} comments={comments} />
  );
};
