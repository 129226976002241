import { RefetchQueriesInclude } from '@apollo/client';
import { HStack, Button, IconButton, Box } from '@chakra-ui/react';
import { useRemoveAttachment } from 'Molecules/InputCard/InputCardDocumentation.hooks';
import { Typography } from 'Tokens';
import { AddIcon, RemoveIcon } from 'Tokens/Icons/Function';
import { Attachment, AttachmentBox } from 'models';
import { useCallback, useMemo } from 'react';
import { FileIcon, defaultStyles } from 'react-file-icon';
import { getNameExtension } from 'utils/files';

export const DisclosureRequirementAttachment = ({
  attachments,
  onAttachmentDrawerOpen,
  refetchQueries,
}: {
  attachments: AttachmentBox['attachments'];
  onAttachmentDrawerOpen: () => void;
  refetchQueries: RefetchQueriesInclude;
}) => {
  const unassignAttachment = useRemoveAttachment();

  const removeAttachment = useCallback(
    (attachmentId: string) => {
      return unassignAttachment(attachmentId, refetchQueries);
    },
    [unassignAttachment]
  );

  // Take only the first attachment since we only allow selecting one file through the drawer
  const attachment: Attachment = useMemo(() => attachments?.[0], [attachments]);
  const file = useMemo(() => attachment?.file, [attachment]);
  const { extension } = useMemo(() => {
    return getNameExtension(file?.storageFile?.name ?? '');
  }, [file]);

  return (
    <>
      {!attachment ? (
        <Button
          px={0}
          variant="ghost"
          size="sm"
          color="text.selected"
          _hover={{ bg: 'bg.hover', color: 'text.selected' }}
          _active={{ bg: 'bg.pressed' }}
          leftIcon={<AddIcon boxSize="16px" color="inherit" />}
          onClick={onAttachmentDrawerOpen}
        >
          Add document
        </Button>
      ) : (
        <HStack
          h="28px"
          bg="bg.default"
          justifyContent="center"
          alignItems="center"
          spacing="6px"
          p="4px"
          border="1px solid"
          borderColor="border.decorative"
          borderRadius="5px"
        >
          <Box w="16.67px">
            <FileIcon
              extension={extension}
              {...defaultStyles[extension as keyof typeof defaultStyles]}
              labelUppercase
            />
          </Box>

          <Typography variant="bodyStrong">{file.title}</Typography>

          <IconButton
            variant="ghost"
            aria-label="CloseButton"
            size="xs"
            icon={<RemoveIcon color="inherit" />}
            onClick={() => removeAttachment(attachment.id)}
          />
        </HStack>
      )}
    </>
  );
};
