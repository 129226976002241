import { useMemo } from 'react';
import { Typography } from 'Tokens';
import {
  findParentRow,
  isFrequencyYearly,
  MetricsTableData,
  useAggregatedRowValue,
  usePercentageValues,
} from '../MetricAnswers.hooks';
import { useGetDatapointValues } from './QuarterInput';
import { TimePeriodsEnums } from '../../Requirement';
import { Tooltip } from 'Atoms';
import { HStack, Skeleton, VStack } from '@chakra-ui/react';
import { GeneratedAnswer } from '../MetricAI';
import { WarningIcon } from 'Tokens/Icons/Status';

export const PercentageValueView = ({ value, row }: { value: string; row: MetricsTableData }) => {
  const tags = useMemo(() => {
    return row?.tags?.map((x) => x) ?? [];
  }, [row]);

  if (value)
    return (
      <Tooltip
        label={`${row.metric.title} ${tags.length ? ' by ' : ''} ${tags.map(
          (x) => x.tagValue
        )}: ${value.replace('(', '').replace(')', '')}`}
        cursor="pointer"
      >
        <span style={{ cursor: 'pointer', fontSize: '14px', marginLeft: '4px' }}> {value}</span>
      </Tooltip>
    );

  return null;
};

export const MetricYear = ({
  row,
  companyStandardId,
  companyReportingUnit,
  nestedMetrics,
  generatedAnswer,
  isAI,
}: {
  row: MetricsTableData;
  companyStandardId: string;
  companyReportingUnit?: string;
  nestedMetrics: MetricsTableData[];
  generatedAnswer?: GeneratedAnswer;
  isAI?: boolean;
}) => {
  const { dataPointsPerQuarter, dataPointPerYear, isAggregated } = useGetDatapointValues(
    row,
    companyReportingUnit
  );

  const isYearly = isFrequencyYearly(row, companyStandardId);

  const { result: aggregatedValues, isLoading } = useAggregatedRowValue(
    row,
    isYearly,
    companyReportingUnit
  );

  const showAsPercentage = useMemo(() => {
    if (isAI) return false;
    return row.metric.showAsPercentage ?? false;
  }, [row]);

  const quartersTotal = useMemo(() => {
    const quartersValues = dataPointsPerQuarter.map((dp) => dp.value?.value);
    return quartersValues.reduce((a, b) => Number(a ?? 0) + Number(b ?? 0), 0);
  }, [dataPointsPerQuarter]);

  const nestedRows =
    nestedMetrics?.find((m) => m.metric.reference === row.metric.reference) ?? nestedMetrics[0];

  const percentageValue = usePercentageValues(
    findParentRow(nestedRows, row),
    row,
    TimePeriodsEnums.year,
    isYearly,
    companyStandardId,
    isAggregated ? aggregatedValues.Year : isYearly ? dataPointPerYear?.value ?? 0 : quartersTotal
  );

  const aggregatedValue = useMemo(
    () => (isNaN(aggregatedValues.Year) ? '—' : Number(aggregatedValues.Year.toFixed(2))),
    [aggregatedValues.Year]
  );

  if (isLoading) return <Skeleton height="20px" width="100px" />;

  if (generatedAnswer) {
    return (
      <VStack alignItems="start" w="100%">
        <Typography variant="body" width="100px">
          {generatedAnswer?.answer !== 'NA' ? generatedAnswer?.answer : '-'}
        </Typography>
        {generatedAnswer.answer === 'NA' && (
          <HStack spacing="4px" alignItems="flex-start" justifyContent="start">
            <WarningIcon color="text.warning" boxSize="18px"></WarningIcon>
            <Typography variant="body" color="text.warning">
              AI couldn't generate answer
            </Typography>
          </HStack>
        )}
      </VStack>
    );
  }

  if (isAggregated)
    return (
      <Typography variant="body" width="100px">
        {aggregatedValue}{' '}
        {showAsPercentage ? <PercentageValueView row={row} value={percentageValue} /> : ''}
      </Typography>
    );

  return (
    <Typography
      color={isAI && !dataPointPerYear?.value ? 'text.hint' : ''}
      variant="body"
      width="100px"
    >
      {isYearly ? dataPointPerYear?.value ?? 0 : quartersTotal}{' '}
      {showAsPercentage ? <PercentageValueView row={row} value={percentageValue} /> : ''}
    </Typography>
  );
};
