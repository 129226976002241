import { CloseIcon } from '@chakra-ui/icons';
import { HStack, Box } from '@chakra-ui/react';
import { ExternalLink, IconButton } from 'Atoms';
import { Typography } from 'Tokens';
import { IdeaIcon } from 'Tokens/Icons/Status';
import { useUserSetting } from 'containers/Navigation';
import { differenceInDays } from 'date-fns';
import { useMemo } from 'react';

const privacyPolicyUpdate = {
  effectiveDate: '2024-05-01',
  newLink: 'https://www.celsia.io/privacy-policy',
  oldLink: 'https://www.celsia.io/privacy-policy-pre-1may2024',
  summary:
    'The updated privacy policy offers clearer details on how we use your data and with whom we share it, enhancing transparency and compliance with data protection laws.',
};

export const privacyPolicyNotificationDismissedKey = `updated-privacy-policy-${privacyPolicyUpdate.effectiveDate}`;

export const PrivacyPolicyNotification = () => {
  const [isClosed, setIsClosed] = useUserSetting(privacyPolicyNotificationDismissedKey, false);

  const isTwoWeeksOld = useMemo(
    () => differenceInDays(new Date(), new Date(privacyPolicyUpdate.effectiveDate)) > 7, // Hide automatically after 7 days
    []
  );

  if (isTwoWeeksOld || isClosed) return null;

  return (
    <HStack
      align="start"
      width="100%"
      p="16px 16px"
      borderTop="1px solid"
      borderColor="border.decorative"
      justifyContent="space-between"
      spacing="2px"
    >
      <HStack align="start">
        <IdeaIcon color="primary.main" />
        <Box>
          <Typography variant="bodyStrong">
            We have updated our privacy policy, effective {privacyPolicyUpdate.effectiveDate}.
          </Typography>
          <Typography variant="body" as="span">
            The updated version can be found{' '}
          </Typography>
          <Typography variant="body" as="span">
            <ExternalLink
              alignSelf="start"
              target="_blank"
              rel="noopener noreferrer"
              href={privacyPolicyUpdate.newLink}
            >
              here
            </ExternalLink>
          </Typography>
          <Typography variant="body" as="span">
            , while the previous version can be found{' '}
          </Typography>
          <Typography variant="body" as="span">
            <ExternalLink
              alignSelf="start"
              target="_blank"
              rel="noopener noreferrer"
              href={privacyPolicyUpdate.oldLink}
            >
              here
            </ExternalLink>
          </Typography>
          <Typography variant="body" as="span">
            .
          </Typography>
          <Typography variant="body">{privacyPolicyUpdate.summary}</Typography>
        </Box>
      </HStack>
      <IconButton
        onClick={() => setIsClosed(true)}
        size="xs"
        icon={<CloseIcon boxSize="8px" color="inherit" />}
        aria-label="Dismiss privacy policy update notification"
      />
    </HStack>
  );
};
