import { Skeleton, VStack } from '@chakra-ui/react';
import { BooleanSelector } from 'Molecules';
import { useEffect, useMemo, useState } from 'react';
import { LongTextMetricInput } from './LongTextMetricInput';
import { AggregatedQualitativeAnswers, MetricsTableData } from '.';
import { useGetDatapointValues } from './InputTable/QuarterInput';
import { TimePeriodsEnums } from '..';
import { booleanToText, textToBoolean } from 'utils/strings';
import { Typography } from 'Tokens';

type AnswerObject =
  | {
      [key: string]: boolean | null;
    }
  | undefined;

type BooleanMetricInputProps = {
  metric: MetricsTableData['metric'];
  companyReportingUnitId?: string;
  setRowData: (
    param: (MetricsTableData & { sourceData?: AggregatedQualitativeAnswers[number] }) | undefined
  ) => void;
  booleanMetricAnswers: AnswerObject;
  setBooleanMetricAnswers: (param: AnswerObject) => void;
  isReadOnly?: boolean;
};

export const BooleanMetricInput = ({
  metric,
  companyReportingUnitId,
  setRowData,
  booleanMetricAnswers,
  setBooleanMetricAnswers,
  isReadOnly = false,
}: BooleanMetricInputProps) => {
  const { onDatapointChange, dataPointPerYear, answer, loading } = useGetDatapointValues(
    {
      metric,
    },
    companyReportingUnitId
  );

  const [booleanAnswer, setBooleanAnswer] = useState<boolean | undefined>();

  const [hasManuallyChanged, setHasManuallyChanged] = useState<boolean>(false);

  const handleChangeAnswer = (asnwer: boolean | undefined) => {
    return onDatapointChange({
      value: dataPointPerYear?.value ?? '',
      factValue: booleanToText(asnwer),
      hasOptedOut: answer?.hasOptedOut ?? false,
      optOutReason: answer?.optOutReason ?? '',
      dp: dataPointPerYear ?? { timeframe: TimePeriodsEnums.year },
    });
  };

  const dpBooleanAnswer = useMemo(
    () => textToBoolean(dataPointPerYear?.factValue ?? ''),
    [dataPointPerYear]
  );

  useEffect(() => {
    setBooleanAnswer(dpBooleanAnswer);
  }, [dataPointPerYear]);

  useEffect(() => {
    if (hasManuallyChanged) {
      handleChangeAnswer(booleanAnswer);
      if (metric.unlockCondition !== null)
        setBooleanMetricAnswers({
          ...(booleanMetricAnswers ?? {}),
          [metric.reference]: booleanAnswer ?? null,
        });
      setHasManuallyChanged(false);
    }
  }, [booleanAnswer]);

  const handleManualChange = (newAnswer: boolean | undefined) => {
    setHasManuallyChanged(true);
    setBooleanAnswer(newAnswer);
  };

  if (loading) {
    return <Skeleton height="20px" width="100%" />;
  }

  return (
    <>
      {isReadOnly ? (
        <VStack p="8px" alignItems="start">
          {dataPointPerYear?.factValue && (
            <Typography variant="body">{dataPointPerYear?.factValue ?? 'N/A'}</Typography>
          )}
          <Typography variant="body">{dataPointPerYear?.value ?? 'N/A'}</Typography>
        </VStack>
      ) : (
        <VStack alignItems="start" w="100%">
          <BooleanSelector answer={booleanAnswer} handleChangeAnswer={handleManualChange} />
          <LongTextMetricInput
            metric={metric}
            companyReportingUnit={companyReportingUnitId}
            setRowData={setRowData}
            hasRef={false}
          />
        </VStack>
      )}
    </>
  );
};
