import {
  AttachmentBox_Constraint_,
  AttachmentBox_Update_Column_,
  Esrs_Answer_Constraint_,
  Esrs_Answer_Update_Column_,
  GetEsrsMetricAnswerDocument_,
  GetEsrsMetricsDatapointsDocument_,
  GetSingleEsrsMetricAnswerDocument_,
  EsrsAssessmentAnswersAggregateDocument_,
  useUpsertDatapointMutation,
  ShortUser,
} from 'models';
import { TimePeriodsEnums } from '../../../Requirement';
import { useCallback, useMemo } from 'react';
import { AggregatedQualitativeAnswers } from '../../AggregatedMetrics/AggregatedMetrics.hooks';
import { useUserData } from '@nhost/react';
import { useParams } from 'react-router-dom';
import { useEsrsAssessmentProjectLeader } from 'containers/Esrs';

export const usePopulateMetricSummary = ({
  answersData,
}: {
  answersData: AggregatedQualitativeAnswers[number];
}) => {
  const { esrsAssessmentId = '' } = useParams();
  const [upsertDatapoint] = useUpsertDatapointMutation();
  const user: ShortUser | null = useUserData();
  const { esrsAssessmentProjectLeaderId } = useEsrsAssessmentProjectLeader(esrsAssessmentId);

  const [currentAnswer, datapoint] = useMemo(() => {
    return [
      answersData.answer,
      answersData.answer?.datapoints.find((dp) => dp.timeframe === TimePeriodsEnums.year),
    ];
  }, [answersData]);

  const populateAnswer = useCallback(
    (generatedAnswer: string, companyReportingUnitId: string) => {
      upsertDatapoint({
        variables: {
          objects: {
            id: datapoint?.id ?? undefined,
            value: generatedAnswer ?? '',
            timeframe: TimePeriodsEnums.year,
            authorId: user?.id,
            ownerId: datapoint?.ownerId ?? esrsAssessmentProjectLeaderId,
            isAIGenerated: true,
            answer: {
              data: {
                metricRef: currentAnswer?.metricRef,
                hasOptedOut: currentAnswer?.hasOptedOut ?? false,
                optOutReason: currentAnswer?.optOutReason ?? '',
                reportingUnitId: companyReportingUnitId,
                assessmentId: esrsAssessmentId,
                attachmentBox: {
                  data: {},
                  on_conflict: {
                    constraint: AttachmentBox_Constraint_.AttachmentBoxMetricAnswerIdKey1_,
                    update_columns: [AttachmentBox_Update_Column_.MetricAnswerId_],
                  },
                },
                optOutAuthorId: user?.id,
              },
              on_conflict: {
                constraint: Esrs_Answer_Constraint_.AnswerMetricRefAssessmentIdReportingUnitIdKey_,
                update_columns: [
                  Esrs_Answer_Update_Column_.HasOptedOut_,
                  Esrs_Answer_Update_Column_.OptOutReason_,
                  Esrs_Answer_Update_Column_.OptOutAuthorId_,
                ],
              },
            },
          },
        },
        refetchQueries: [
          GetEsrsMetricAnswerDocument_,
          GetEsrsMetricsDatapointsDocument_,
          GetSingleEsrsMetricAnswerDocument_,
          EsrsAssessmentAnswersAggregateDocument_,
        ],
      });
    },
    [upsertDatapoint]
  );

  return { populateAnswer, datapoint };
};
