import { ShortUser } from 'models';
import { DRQAnswersType } from '.';

export const KEY_RESULTS = [
  {
    key: 'baseline',
    header: 'Baseline',
    subHeader: 'If available',
  },
  {
    key: 'shortTerm',
    header: 'Short term',
    subHeader: '1 year',
  },
  {
    key: 'mediumTerm',
    header: 'Medium term',
    subHeader: '5 year',
  },
  {
    key: 'longTerm',
    header: 'Long term',
    subHeader: '10 year',
  },
] as const;

export type MilestoneFields = {
  id?: string;
  year: number;
  isRelative: boolean;
  value: number | undefined;
  isRequired: boolean;
  subsidiaries: { [key: string]: any };
  reportingUnits: { [key: string]: any };
};

export type TargetFields = {
  [key: string]: any;
  name: string;
  direction: string | undefined | null;
  metricRef: string | undefined | null;
  owner: ShortUser | undefined;
  baseYear: Date | null;
  unit: string;
  baseline: number;
  isBottomUp: boolean;
  isFlagged: boolean;
  description: string | undefined | null;
  actions: { actionId: string; estimate: number | null }[];
  milestones: MilestoneFields[];
  answers: DRQAnswersType;
  relatedIRO: string | null;
  relatedPolicy: string | null;
  parentTargetId: string | null;
  period: string | null;
  periodExplanation: string | null;
  scope: string | null;
  methodologies: string | null;
  isEvidenceScientific: boolean | null;
  isEvidenceScientificExplanation: string | null;
  withStakeholders: boolean | null;
  withStakeholdersExplanation: string | null;
  withStakeholdersDescription: string | null;
  targetChangeDescription: string | null;
  performanceText: string | null;
  performanceNumber: number | null;
  isProgressAligned: boolean | null;
  isProgressAlignedExplanation: string | null;
};

export enum TargetFieldsEnum {
  name = 'name',
  direction = 'direction',
  metricRef = 'metricRef',
  ownerId = 'ownerId',
  baseYear = 'baseYear',
  unit = 'unit',
  baseline = 'baseline',
  isBottomUp = 'isBottomUp',
  isFlagged = 'isFlagged',
  description = 'description',
  actions = 'actions',
  milestones = 'milestones',
  answers = 'answers',
  relatedIRO = 'relatedIRO',
  relatedPolicy = 'relatedPolicy',
  period = 'period',
  periodExplanation = 'periodExplanation',
  scope = 'scope',
  methodologies = 'methodologies',
  isEvidenceScientific = 'isEvidenceScientific',
  isEvidenceScientificExplanation = 'isEvidenceScientificExplanation',
  withStakeholders = 'withStakeholders',
  withStakeholdersExplanation = 'withStakeholdersExplanation',
  withStakeholdersDescription = 'withStakeholdersDescription',
  targetChangeDescription = 'targetChangeDescription',
  performanceText = 'performanceText',
  performanceNumber = 'performanceNumber',
  isProgressAligned = 'isProgressAligned',
  isProgressAlignedExplanation = 'isProgressAlignedExplanation',
}

export enum KeyResultEnums {
  baseYear = 'baseYear',
  baseline = 'baseline',
}

export const SELECTED_MILESTONES_ID = 'selectedMilestoneId';

export enum Direction {
  reduce = 'reduce',
  maintain = 'maintain',
  increase = 'increase',
}

export enum TargetUnit {
  relative = 'relative',
  absolute = 'absolute',
}

export const METRICS_HEADER_FIELDS = ['Q1', 'Q2', 'Q3', 'Q4', 'Year', '', ''] as const;

export const QUARTERS_FIELDS = ['Q1', 'Q2', 'Q3', 'Q4'] as const;

export const QUARTERS_ARRAY = ['Q1', 'Q2', 'Q3', 'Q4'];

export const TIME_FRAMES_ARRAY: TimePeriods[] = [...QUARTERS_FIELDS, 'Year'];

export enum FrequencyEnums {
  yearly = 'Yearly',
  quarterly = 'Quarterly',
}
export const TOTAL = 'Total';

export enum QuartersEnums {
  q1 = 'Q1',
  q2 = 'Q2',
  q3 = 'Q3',
  q4 = 'Q4',
}

export enum TimePeriodsEnums {
  q1 = 'Q1',
  q2 = 'Q2',
  q3 = 'Q3',
  q4 = 'Q4',
  year = 'Year',
}
export type QuartersType = 'Q1' | 'Q2' | 'Q3' | 'Q4';
export type TimePeriods = 'Q1' | 'Q2' | 'Q3' | 'Q4' | 'Year';

export const relativeOptions = [
  { value: TargetUnit.relative, label: 'Use relative values' },
  { value: TargetUnit.absolute, label: 'Use absolute values' },
];

export type QuartersObject = {
  Q1: number;
  Q2: number;
  Q3: number;
  Q4: number;
};

export type YearObject = {
  Q1: number;
  Q2: number;
  Q3: number;
  Q4: number;
  Year: number;
};

export type DRTypes = 'policy' | 'target' | 'metric' | 'action';

export enum DREnums {
  policy = 'policy',
  target = 'target',
  metric = 'metric',
  action = 'action',
}

export const UNKONWN_ROUTE = 'first';

export enum MetricViewEnums {
  overview = 'overview',
  dataInput = 'data-input',
}

export enum SidebarModes {
  closed = 'closed',
  overlay = 'overlay',
  open = 'open',
}

export enum PopoverTypes {
  closed = 'closed',
  open = 'open',
}

export type ActionFields = {
  title: string;
  dueDate: Date;
  startDate: Date;
  ownerId: string;
  timeHorizon: string | null;
  capex?: string;
  noteReference?: string | null;
  reportingUnits: { value: string; label: string }[];
  subsidiaries: { value: string; label: string }[];
  targets: { value: string; label: string }[];
  estimates?: { [key: string]: string };
  estimate?: number;
  documentation?: string;
  answers: DRQAnswersType;
  relatedIRO?: string | null;
  relatedPolicy?: string | null;
  scope?: string | null;
  expectedOutcome?: string | null;
  provisionOfRemedy?: boolean | null;
  provisionOfRemedyExplanation?: string | null;
  progressInfo?: string | null;
  investmentsAndResources?: string | null;
  hasPreconditions?: boolean | null;
  hasPreconditionsExplanation?: string | null;
  opex?: any | null;
  opexShortTerm?: any | null;
  opexMediumTerm?: any | null;
  opexLongTerm?: any | null;
  capexShortTerm?: any | null;
  capexMediumTerm?: any | null;
  capexLongTerm?: any | null;
};

export enum ActionFieldsEnums {
  title = 'title',
  dueDate = 'dueDate',
  startDate = 'startDate',
  ownerId = 'ownerId',
  timeHorizon = 'timeHorizon',
  capex = 'capex',
  noteReference = 'noteReference',
  reportingUnits = 'reportingUnits',
  subsidiaries = 'subsidiaries',
  targets = 'targets',
  estimates = 'estimates',
  estimate = 'estimate',
  documentation = 'documentation',
  answers = 'answers',
  relatedIRO = 'relatedIRO',
  relatedPolicy = 'relatedPolicy',
  scope = 'scope',
  expectedOutcome = 'expectedOutcome',
  provisionOfRemedy = 'provisionOfRemedy',
  provisionOfRemedyExplanation = 'provisionOfRemedyExplanation',
  progressInfo = 'progressInfo',
  investmentsAndResources = 'investmentsAndResources',
  hasPreconditions = 'hasPreconditions',
  hasPreconditionsExplanation = 'hasPreconditionsExplanation',
  opex = 'opex',
  opexShortTerm = 'opexShortTerm',
  opexMediumTerm = 'opexMediumTerm',
  opexLongTerm = 'opexLongTerm',
  capexShortTerm = 'capexShortTerm',
  capexMediumTerm = 'capexMediumTerm',
  capexLongTerm = 'capexLongTerm',
}
