import { Box, Center, Divider, HStack, VStack } from '@chakra-ui/react';
import { Button } from 'Atoms';
import { ReportYearSelector } from 'Features/PortfolioView/ReportYearSelector';
import { Typography } from 'Tokens';
import { TodoIcon } from 'Tokens/Icons/Custom';
import { Dispatch, SetStateAction, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'utils/translation';
import usePortfolios, { useUpdateDefaultReportingPeriod } from './Portfolios.hooks';

const PortfolioDefault = ({
  setIsCreating,
  years,
  reportingYear,
  currentTab,
}: {
  setIsCreating: Dispatch<SetStateAction<boolean>>;
  years: number[];
  reportingYear: number;
  currentTab: string;
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation(['portfolio']);
  const { getPortfolio } = usePortfolios();
  const { portfolioId, companyId = '' } = useParams();
  const { updateDefaultReportingPeriod } = useUpdateDefaultReportingPeriod(companyId);

  const portfolio = getPortfolio(portfolioId ?? '', null);

  const addedCompanies = useMemo(
    () => portfolio?.portfolioCompanies?.filter((c) => c?.year === null || !c.year) ?? [],
    [portfolio]
  );

  return (
    <VStack flexGrow="1">
      <HStack bg="bg.muted" borderRadius="8px" px="16px" py="8px" justifyContent="start" w="100%">
        <HStack spacing="8px">
          <Typography variant="h4">PERIOD:</Typography>
          <ReportYearSelector
            reportingYears={years}
            selectedYear={reportingYear}
            setSelectedYear={(newYear) => {
              updateDefaultReportingPeriod(newYear);
              navigate(`${newYear}/${currentTab ?? 'companies'}`);
            }}
          />
        </HStack>
      </HStack>
      <Box w="100%" flexGrow="1" display="flex" bg="bg.muted" borderRadius="12px">
        <Center width="100%" py="40px" flexGrow="1">
          <VStack width="564px" spacing="32px">
            <VStack alignItems="start">
              <Typography variant="h2">{t('portfolio:gettingStarted.title')}</Typography>
              <Typography variant="bodyLarge">
                {t('portfolio:gettingStarted.description')}
              </Typography>
            </VStack>

            <VStack>
              <HStack alignItems="start" spacing="18px">
                <VStack spacing="0px">
                  <TodoIcon boxSize="32px" color="border.default" />
                  <Divider
                    variant="dashed"
                    orientation="vertical"
                    height={addedCompanies?.length > 0 ? '168px' : '110px'}
                    border="1px"
                    borderColor="border.hover"
                    marginY="2px"
                  />
                </VStack>
                <VStack alignItems="start" spacing="16px" pt="2px">
                  <VStack alignItems="start" spacing="4px">
                    <Typography variant="h3">
                      {t('portfolio:gettingStarted.inviteCompaniesTitle')}
                    </Typography>
                    <Typography variant="body" color="text.muted">
                      {t('portfolio:gettingStarted.inviteCompaniesDesc')}
                    </Typography>
                  </VStack>
                  {addedCompanies?.length > 0 && (
                    <HStack
                      padding="8px 4px 8px 8px"
                      border="1px"
                      borderColor="bg.unknown"
                      bg="bg.unknown.muted"
                      width="100%"
                      borderRadius="6px"
                    >
                      <Typography variant="body" color="text.muted">
                        Companies added:
                      </Typography>
                      <Typography variant="body" color="text.muted">
                        {addedCompanies.map((company, index) => (
                          <span key={index}>
                            {company.name}
                            {index < addedCompanies.length - 1 && ', '}
                          </span>
                        ))}
                      </Typography>
                    </HStack>
                  )}
                  <Button size="sm" variant="secondary" onClick={() => setIsCreating(true)}>
                    {t('portfolio:gettingStarted.add')}
                  </Button>
                </VStack>
              </HStack>
            </VStack>
            <VStack>
              <HStack alignItems="start" spacing="18px" mt="-34px">
                <VStack spacing="0px">
                  <TodoIcon boxSize="32px" color="border.default" />
                </VStack>
                <VStack alignItems="start" spacing="16px" pt="2px">
                  <VStack alignItems="start" spacing="4px">
                    <Typography variant="h3">{t('portfolio:gettingStarted.setupTitle')}</Typography>
                    <Typography variant="body" color="text.muted">
                      {t('portfolio:gettingStarted.setupDescription')}
                    </Typography>
                  </VStack>
                  <Button
                    size="sm"
                    variant="secondary"
                    onClick={() => {
                      navigate(`new-portfolio/create`);
                    }}
                  >
                    {t('portfolio:gettingStarted.settings')}
                  </Button>
                </VStack>
              </HStack>
            </VStack>
          </VStack>
        </Center>
      </Box>
    </VStack>
  );
};

export default PortfolioDefault;
