import axios from 'axios';
import { axiosRequestWithRetries } from 'utils/axios';
import { nhost } from 'utils/nhost';

type PdfResponseType = {
  content: {
    page: number;
    text: string;
  }[];
};

const AI_BACKEND_URL = import.meta.env.REACT_APP_AI_BACKEND_URL;

export const extractTextFromPdf = async (
  fileUrl: string,
  fileName: string,
  requestSignal: AbortSignal
): Promise<PdfResponseType> => {
  return axiosRequestWithRetries(
    () =>
      axios.post(
        `${AI_BACKEND_URL}/docs/extract`,
        { fileUrl: fileUrl, name: fileName },
        {
          headers: {
            authorization: `Bearer ${nhost.auth.getAccessToken()}`,
          },
          signal: requestSignal,
        }
      ),
    3,
    500
  );
};

export const extractNumericAnswer = (
  metricTitleWithTags: string,
  unit: string,
  context: string,
  requestSignal: AbortSignal
): Promise<any> => {
  return axios.post(
    `${AI_BACKEND_URL}/metrics/extractNumericAnswer`,
    {
      metricTitleWithTags,
      context,
      unit,
    },
    {
      headers: {
        authorization: `Bearer ${nhost.auth.getAccessToken()}`,
      },
      signal: requestSignal,
    }
  );
};

export const extractNarrativeAnswer = (
  metricTitle: string,
  context: string,
  requestSignal: AbortSignal
): Promise<any> => {
  return axios.post(
    `${AI_BACKEND_URL}/metrics/extractNarrativeAnswer`,
    {
      metricTitle,
      context,
    },
    {
      headers: {
        authorization: `Bearer ${nhost.auth.getAccessToken()}`,
      },
      signal: requestSignal,
    }
  );
};

export const summarizeNarrativeAnswers = (
  metricTitle: string,
  metricDescription: string,
  answers: string,
  subsidiaries: string,
  requestSignal?: AbortSignal
): Promise<any> => {
  return axios.post(
    `${AI_BACKEND_URL}/metrics/summarizeNarrativeAnswers`,
    {
      metricTitle,
      metricDescription,
      answers,
      subsidiaries,
    },
    {
      headers: {
        authorization: `Bearer ${nhost.auth.getAccessToken()}`,
      },
      signal: requestSignal,
    }
  );
};
