import { HStack, useDisclosure } from '@chakra-ui/react';
import { AssessableMetrics } from '../Metrics';
import { Typography } from 'Tokens';
import { User } from 'models';
import { AggregatedQualitativeAnswers, MetricsTableData } from '..';
import { Button, Tooltip } from 'Atoms';
import { AIIcon } from 'Tokens/Icons/Function';
import { MetricsAIModal } from './MetricsAIModal';
import { SelectedMetric } from 'containers/Esrs/EsrsAssessment.hooks';
import { TimePeriods } from '../../Requirement';
import { useTranslation } from 'utils/translation';
import { useFeatureFlags } from 'containers/Navigation';
import { useCurrentCompany } from 'utils/hooks';

export const MetricsAISection = ({
  materialStandardId,
  reportingUnitId,
  disclosureRequirementRef,
  allFilteredMetrics,
  selectedQuarter,
  selectedMetric,
  setSelectedMetric,
  onOpen,
  onDrawerOpen,
  isOverview,
  areMetricsYearly,
  isCompanyLevel,
  isGroup,
  rowData,
  setRowData,
  esrsAssessmentProjectLeader,
  qualitativeMetrics,
  summaryQualitativeMetrics,
  aggregatedQualitativeMetricsAnswers,
}: {
  materialStandardId: string;
  disclosureRequirementRef: string;
  reportingUnitId?: string;
  //
  allFilteredMetrics: MetricsTableData[];
  selectedQuarter: TimePeriods;
  selectedMetric?: SelectedMetric;
  setSelectedMetric: (param: SelectedMetric) => void;
  onOpen: () => void;
  onDrawerOpen: () => void;
  isOverview: boolean;
  areMetricsYearly: boolean;
  isCompanyLevel: boolean;
  isGroup: boolean;
  rowData?: MetricsTableData;
  setRowData: (data: MetricsTableData | undefined) => void;
  esrsAssessmentProjectLeader?: Partial<User>;
  qualitativeMetrics: AssessableMetrics;
  summaryQualitativeMetrics: AssessableMetrics;
  aggregatedQualitativeMetricsAnswers: AggregatedQualitativeAnswers;
}) => {
  const { isOpen: isAIModalOpen, onClose: onAIModalClose, onOpen: onAIModalOpen } = useDisclosure();
  const { t } = useTranslation(['common, ai']);
  const { company } = useCurrentCompany();
  const { hasAiAccess } = useFeatureFlags({ company });

  return (
    <>
      <HStack
        w="100%"
        p="4px 4px 4px 8px"
        border="1px solid"
        borderColor="border.selected.accent"
        borderRadius="6px"
      >
        <HStack spacing="8px" w="100%" justifyContent="space-between">
          <HStack spacing="8px">
            <AIIcon color="text.info" />
            <Typography variant="bodyStrong">Generate answers with AI</Typography>
          </HStack>

          <Tooltip label={t('common:aiConsent')} isDisabled={hasAiAccess}>
            <Button size="sm" variant="primary" onClick={onAIModalOpen} isDisabled={!hasAiAccess}>
              Start
            </Button>
          </Tooltip>
        </HStack>
      </HStack>

      <MetricsAIModal
        isOpen={isAIModalOpen}
        onClose={onAIModalClose}
        disclosureRequirementRef={disclosureRequirementRef}
        materialStandardId={materialStandardId}
        reportingUnitId={reportingUnitId}
        allFilteredMetrics={allFilteredMetrics}
        selectedQuarter={selectedQuarter}
        selectedMetric={selectedMetric}
        setSelectedMetric={setSelectedMetric}
        onOpen={onOpen}
        onDrawerOpen={onDrawerOpen}
        isOverview={isOverview}
        areMetricsYearly={areMetricsYearly}
        isCompanyLevel={isCompanyLevel}
        isGroup={isGroup}
        rowData={rowData}
        setRowData={setRowData}
        esrsAssessmentProjectLeader={esrsAssessmentProjectLeader}
        qualitativeMetrics={qualitativeMetrics}
        summaryQualitativeMetrics={summaryQualitativeMetrics}
        aggregatedQualitativeMetricsAnswers={aggregatedQualitativeMetricsAnswers}
      />
    </>
  );
};
