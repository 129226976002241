import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Divider,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import { useUserData } from '@nhost/react';
import { IconUserPlus } from '@tabler/icons-react';
import { SearchInput } from 'Molecules';
import { Typography } from 'Tokens';
import { TeamMember } from 'containers/TeamMembers';
import { ShortUser, User } from 'models';
import { useMemo, useState } from 'react';
import { AvatarSize } from 'Atoms';
import { AddUserIcon } from 'Tokens/Icons/Function';
import { UserAvatar } from 'Organisms';

export const ProjectLeaderSelect = ({
  defaultProjectLeader,
  isDisabled = false,
  members,
  closeModal,
  onChange,
  rounded,
  onInvitationModalOpen,
  avatarText,
  small,
  isBorderless = false,
  size,
}: {
  defaultProjectLeader?: Partial<User>;
  isDisabled?: boolean;
  onChange: (member: TeamMember) => void;
  rounded?: boolean;
  members?: TeamMember[];
  avatarText?: string;
  onInvitationModalOpen?: () => void;
  closeModal?: () => void;
  noText?: boolean;
  small?: boolean;
  isBorderless?: boolean;
  size?: AvatarSize;
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [search, setSearch] = useState<string>('');
  const user = useUserData();
  const [selectedProjectLeader, setSelectedProjectLeader] = useState<TeamMember>();

  const selectedValue = useMemo(() => {
    if (selectedProjectLeader) {
      return `${selectedProjectLeader?.name ?? selectedProjectLeader?.email} ${
        selectedProjectLeader?.email === user?.email ? ' (You)' : ''
      }`;
    }

    if (defaultProjectLeader) {
      if (small && defaultProjectLeader?.displayName) {
        const name = defaultProjectLeader?.displayName?.split(' ');
        return `${name[0]} ${name[1][0]}.`;
      }
      const memberName = members?.find((member) => member.id === defaultProjectLeader.id)?.name;
      return `${defaultProjectLeader?.displayName ?? memberName ?? defaultProjectLeader?.email} ${
        defaultProjectLeader?.email === user?.email ? ' (You)' : ''
      }`;
    }
    if (members) {
      return `${members[0]?.name ?? members[0]?.email} ${
        user?.email === members[0]?.email ? '(You)' : ''
      }`;
    }
    return '';
  }, [selectedProjectLeader, defaultProjectLeader]);

  const handleSearchMembers = (member: TeamMember) => {
    return (
      member.name?.toLowerCase().includes(search.toLowerCase()) ||
      member.email?.toLowerCase().includes(search.toLowerCase())
    );
  };

  const handleSelectProjectLeader = (member: TeamMember) => {
    setSelectedProjectLeader(member);
    setIsMenuOpen(false);
    onChange(member);
  };

  const handleInvite = () => {
    setIsMenuOpen(false);
    if (closeModal) closeModal();
    if (onInvitationModalOpen) onInvitationModalOpen();
  };

  return (
    <Menu
      size={members?.some((member) => member.pending) ? 'xl' : 'lg'}
      gutter={2}
      isOpen={isMenuOpen}
      onOpen={() => setIsMenuOpen(true)}
      onClose={() => setIsMenuOpen(false)}
      closeOnSelect
    >
      <MenuButton
        isDisabled={isDisabled}
        as={Button}
        width={small ? 'fit-content' : '100%'}
        h={isBorderless ? 'fit-content' : '38px'}
        bg="bg.default"
        borderWidth={isBorderless ? '0px' : '1px'}
        borderColor="border.default"
        _hover={{ borderColor: 'border.hover', svg: { color: 'border.hover' } }}
        _focus={{
          borderColor: 'border.selected.accent',
          boxShadow: 'none',
          svg: { color: 'inherit' },
        }}
        _active={{
          bg: 'bg.default',
        }}
        borderRadius={rounded ? '32px' : '8px'}
        p={isBorderless ? '0px' : small ? '4px' : '8px'}
        rightIcon={<ChevronDownIcon fontSize={20} color="border.default" />}
        textAlign="left"
        overflow="hidden"
        color="text.default"
      >
        <HStack spacing={0}>
          {avatarText && (
            <UserAvatar
              user={defaultProjectLeader as ShortUser}
              color={isDisabled ? 'text.default' : 'text.onAccent'}
              backgroundColor={isDisabled ? 'bg.disabled.accent' : undefined}
              size={size ?? 'sm'}
            />
          )}
          {!avatarText && small && (
            <Box bg="bg.muted" boxSize="20px" borderRadius="50%" padding="2px">
              <AddUserIcon color="text.default" />
            </Box>
          )}
          {!small && (
            <Typography color={selectedValue ? 'inherit' : 'text.hint'} variant="body">
              {selectedValue ? selectedValue : 'Select or invite a user'}
            </Typography>
          )}
        </HStack>
      </MenuButton>
      <MenuList p="8px" width="100%">
        <SearchInput
          width="100%"
          placeholder="Search"
          search={search}
          setSearch={setSearch}
          marginBottom="4px"
        />
        <Box maxH="200px" overflowY="auto">
          {members?.filter(handleSearchMembers).map((member) => (
            <MenuItem
              key={member.id}
              p="8px"
              m="0px"
              height="20px"
              w="100%"
              isDisabled={member.pending}
              closeOnSelect={false}
              onClick={() => handleSelectProjectLeader(member)}
            >
              <HStack w="100%" spacing="8px">
                <UserAvatar user={member.user} size="sm" />
                <Typography variant="bodyStrong" noOfLines={1}>
                  {`${member.name ?? member.email} ${user?.email === member.email ? '(You)' : ''}`}
                  {member.pending && '(Pending)'}
                </Typography>
              </HStack>
            </MenuItem>
          ))}
        </Box>
        <Divider color="border.decorative" my="8px" ml="-8px" pr="16px" />
        <Box w="100%">
          <Button
            variant="ghost"
            w="100%"
            leftIcon={<IconUserPlus size={'16px'} />}
            onClick={handleInvite}
          >
            <Typography variant="bodyStrong">Invite new user</Typography>
          </Button>
        </Box>
      </MenuList>
    </Menu>
  );
};
