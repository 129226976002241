import { CheckIcon, ChevronDownIcon } from '@chakra-ui/icons';
import { HStack, useColorMode } from '@chakra-ui/react';
import { IconButton } from 'Atoms';
import React, { useMemo } from 'react';
import ReactSelect, { GroupBase, Props, components } from 'react-select';
import ReactCreatableSelect, { CreatableProps } from 'react-select/creatable';
import { colors, Typography } from 'Tokens';
import { RemoveIcon } from 'Tokens/Icons/Function';

export const Select = React.forwardRef(
  <
    OptionType,
    IsMulti extends boolean = false,
    GroupType extends GroupBase<OptionType> = GroupBase<OptionType>,
  >(
    {
      size = 'md',
      width = '100%',
      isInvalid = false,
      deselect,
      ghostVariant = false,
      ...props
    }: Props<OptionType, IsMulti, GroupType> & {
      size?: 'md' | 'sm';
      width?: string;
      isInvalid?: boolean;
      deselect?: () => void;
      ghostVariant?: boolean;
    },
    ref: React.Ref<any>
  ) => {
    const { colorMode } = useColorMode();
    const isDarkMode = useMemo(() => colorMode === 'dark', [colorMode]);
    const color = useMemo(() => (isDarkMode ? '_dark' : 'default'), [isDarkMode]);

    const backgroundColor = useMemo(() => colors['bg.default'][color], [color]);
    return (
      <ReactSelect<OptionType, IsMulti, GroupType>
        {...props}
        components={{
          IndicatorSeparator: () => null,
          DropdownIndicator: (dropdownIndicatorProps) => {
            return (
              <components.DropdownIndicator {...dropdownIndicatorProps}>
                <IconButton
                  icon={
                    <ChevronDownIcon
                      boxSize="16px"
                      color={props.isDisabled ? 'text.disabled' : 'text.muted'}
                    />
                  }
                  isDisabled={props.isDisabled}
                  aria-label="open"
                  variant="ghost"
                  padding="6px"
                />
              </components.DropdownIndicator>
            );
          },
          ClearIndicator: (clearIndicatorProps) => {
            return (
              <components.ClearIndicator {...clearIndicatorProps}>
                <IconButton
                  icon={<RemoveIcon boxSize="16px" color="text.hint" />}
                  aria-label="remove"
                  variant="ghost"
                  height=""
                  minW=""
                  padding="6px"
                />
              </components.ClearIndicator>
            );
          },
          Option: ({ isSelected, children, isDisabled, isMulti, ...optionProps }) => {
            return (
              <components.Option {...optionProps} isSelected isMulti isDisabled>
                <HStack
                  justifyContent={'space-between'}
                  padding="8px"
                  borderRadius="8px"
                  _hover={{
                    backgroundColor: 'bg.hover',
                    cursor: isDisabled ? 'not-allowed' : 'pointer',
                  }}
                  onClick={isSelected ? deselect : undefined}
                >
                  <HStack>
                    <Typography
                      variant="bodyStrong"
                      color={
                        isDisabled ? 'text.disabled' : isSelected ? 'text.selected' : 'text.muted'
                      }
                    >
                      {optionProps.label}
                    </Typography>
                  </HStack>
                  {isSelected && !isMulti && (
                    <CheckIcon boxSize="11px" strokeWidth="1px" color="text.selected" />
                  )}
                </HStack>
              </components.Option>
            );
          },
        }}
        ref={ref}
        styles={{
          dropdownIndicator: (base) => ({
            ...base,
            padding: '0px',
          }),
          clearIndicator: (base) => ({
            ...base,
            padding: '0px',
          }),
          container: (provided) => ({
            ...provided,
            width: width,
            fontSize: '14px',
          }),
          menuPortal: (base) => ({
            ...base,
            background: backgroundColor,
            zIndex: 9999,
          }),
          menuList: (base, { isMulti }) => ({
            ...base,
            maxHeight: isMulti ? '275px' : '300px',
            padding: '0px',
            border: 'none',
          }),
          menu: (base) => ({
            ...base,
            padding: '8px',
            margin: '0px',
            boxShadow:
              '0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 0px 15px -3px rgba(0, 0, 0, 0.10)',
            borderRadius: '10px',
            background: backgroundColor,
            zIndex: 999,
          }),
          valueContainer: (base) => ({
            ...base,
            padding: '3px',
          }),
          singleValue: (base, { isDisabled }) => ({
            ...base,
            color: isDisabled ? colors['text.disabled'][color] : colors['text.muted'][color],
          }),
          option: (base, { isDisabled }) => ({
            ...base,
            background: backgroundColor,
            color: colors[isDisabled ? 'text.disabled' : 'text.default'][color],
            padding: '0px',
            width: '100%',
            '&:active': {
              backgroundColor: backgroundColor,
            },
          }),

          control: (provided, { isFocused, isDisabled }) => {
            const borderColor =
              isFocused && !isInvalid
                ? 'border.selected.accent'
                : isInvalid
                  ? 'border.critical.accent'
                  : 'border.default';
            return {
              ...provided,
              width: '100%',
              borderRadius: '8px',
              fontSize: ghostVariant ? '14px' : 'sm',
              fontWeight: ghostVariant ? 500 : undefined,
              background: isDisabled
                ? ghostVariant
                  ? 'none'
                  : colors?.['bg.disabled']?.[color]
                : backgroundColor,
              minHeight: size === 'md' ? '36px' : '28px',
              padding: '0px 3px',
              borderWidth: isDisabled ? '0px' : isFocused ? '2px' : '1px',
              borderColor: colors[borderColor][color],
              boxShadow: isFocused || isDisabled || ghostVariant ? 'none' : undefined,
              cursor: isDisabled ? 'not-allowed' : 'pointer',
              pointerEvents: 'auto',
              border: ghostVariant ? 'none' : undefined,
              '&:hover': {
                background: ghostVariant ? colors['bg.hover'][color] : undefined,
              },
              '&:focus': {
                background: ghostVariant ? colors['bg.pressed'][color] : undefined,
              },
            };
          },
        }}
      />
    );
  }
) as <
  OptionType,
  IsMulti extends boolean = false,
  GroupType extends GroupBase<OptionType> = GroupBase<OptionType>,
>(
  props: Props<OptionType, IsMulti, GroupType> & {
    size?: 'md' | 'sm';
    isInvalid?: boolean;
    width?: string;
    deselect?: () => void;
    ghostVariant?: boolean;
    ref?: React.Ref<any>;
  }
) => JSX.Element;

export const CreatableSelect = React.forwardRef(
  <
    OptionType,
    IsMulti extends boolean = false,
    GroupType extends GroupBase<OptionType> = GroupBase<OptionType>,
  >(
    {
      size = 'md',
      isInvalid = false,
      ...props
    }: CreatableProps<OptionType, IsMulti, GroupType> & {
      size?: 'md' | 'sm';
      isInvalid?: boolean;
    },
    ref: React.Ref<any>
  ) => {
    const { colorMode } = useColorMode();
    const isDarkMode = useMemo(() => colorMode === 'dark', [colorMode]);
    const color = useMemo(() => (isDarkMode ? '_dark' : 'default'), [isDarkMode]);

    const backgroundColor = useMemo(() => colors['bg.default'][color], [color]);

    return (
      <ReactCreatableSelect<OptionType, IsMulti, GroupType>
        {...props}
        ref={ref}
        styles={{
          container: (provided) => ({
            ...provided,
            borderColor: backgroundColor,
            border: 'none',
            width: '100%',
          }),
          menuList: (base, { isMulti }) => ({
            ...base,
            maxHeight: isMulti ? '275px' : '300px',
            zIndex: 9999,
          }),
          menuPortal: (base) => ({
            ...base,
            borderColor: backgroundColor,
            background: backgroundColor,
            border: 'none',
            zIndex: 9999,
          }),
          menu: (base) => ({
            ...base,
            background: backgroundColor,
          }),
          dropdownIndicator: (base) => ({ ...base, color: colors?.['text.disabled']?.[color] }),
          indicatorSeparator: (base) => ({
            ...base,
            backgroundColor: colors?.['text.disabled']?.[color],
          }),
          input: (base) => ({ ...base, color: colors?.['text.default']?.[color] }),
          option: (base, { isFocused, isSelected }) => {
            const background = isSelected
              ? 'bg.selected.accent'
              : isFocused
                ? 'bg.selected'
                : 'bg.default';
            const textColor = isSelected ? 'text.onAccent' : 'text.default';
            return {
              ...base,
              background: colors?.[background]?.[color],
              color: colors?.[textColor]?.[color],
              width: '100%',
            };
          },
          control: (provided, { isFocused, isDisabled }) => {
            const borderColor =
              isFocused && !isInvalid
                ? 'border.selected.accent'
                : isInvalid
                  ? 'border.critical.accent'
                  : 'border.default';
            return {
              ...provided,
              width: '100%',
              borderRadius: '8px',
              fontSize: 'sm',
              background: isDisabled ? colors?.['bg.disabled']?.[color] : backgroundColor,
              minHeight: size === 'md' ? '36px' : '28px',
              padding: '0px 3px',
              borderWidth: isDisabled ? '0px' : isFocused ? '2px' : '1px',
              borderColor: colors[borderColor][color],
              boxShadow: isFocused || isDisabled ? 'none' : undefined,
              cursor: isDisabled ? 'not-allowed' : 'pointer',
              pointerEvents: 'auto',
              _hover: {
                borderColor: 'border.hover',
              },
            };
          },
        }}
      />
    );
  }
) as <
  OptionType,
  IsMulti extends boolean = false,
  GroupType extends GroupBase<OptionType> = GroupBase<OptionType>,
>(
  props: CreatableProps<OptionType, IsMulti, GroupType> & {
    size?: 'md' | 'sm';
    isInvalid?: boolean;
    ref?: React.Ref<any>;
  }
) => JSX.Element;
