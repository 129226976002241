import {
  Box,
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
  VStack,
} from '@chakra-ui/react';
import { Button, DateInput, Input } from 'Atoms';
import { CompanyAssessment, useCompanyAssessmentQuery } from 'models';
import { ContentHeader, ContentLayout } from 'Molecules';
import { Controller, useForm, useFormState } from 'react-hook-form';
import { useTranslation } from 'utils/translation';
import { dateToString } from 'utils/date';
import { BusinessUnitsSelector } from './BusinessUnitsSelector';
import { UserAssigner } from 'Organisms';
import React, { useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFlagAndUnlockAssessment, useUpsertCompanyAssessment } from '../Assessments.hooks';
import { useToast, useCurrentCompanyId } from 'utils/hooks';

type AssessmentFields = {
  businessUnits: Array<string>;
  startDate: string;
  name: string;
  contactPersonId: string;
};

export const FormItem = ({
  isRequired,
  title,
  children,
  description,
  error,
  isInvalid,
  ...rest
}: {
  isRequired: boolean;
  title: string;
  description: string;
  children: React.ReactNode;
  error?: string;
} & FormControlProps) => {
  return (
    <FormControl isRequired={isRequired} error={error} isInvalid={isInvalid} {...rest}>
      <VStack alignItems="flex-start" justifyContent="flex-start">
        <VStack spacing="0px" alignItems="flex-start">
          <FormLabel m="0px" color="text.default">
            {title}
          </FormLabel>
          <FormHelperText mt="2px" color="text.muted">
            {description}
          </FormHelperText>
        </VStack>
        {children}
        {isInvalid && error && (
          <Box height={error ? '20px' : '0'}>
            <FormErrorMessage mt="0">{error}</FormErrorMessage>
          </Box>
        )}
      </VStack>
    </FormControl>
  );
};

export const AssessmentEditor = ({ cAssessment }: { cAssessment?: CompanyAssessment }) => {
  const toast = useToast();
  const navigate = useNavigate();
  const { companyId } = useCurrentCompanyId();
  const [flagAndUnlockAssessment] = useFlagAndUnlockAssessment();
  const { t } = useTranslation(['assessment', 'common']);
  const { handleSubmit, control, reset } = useForm<AssessmentFields>({
    mode: 'all',
    reValidateMode: 'onBlur',
    criteriaMode: 'all',
    defaultValues: {
      businessUnits: cAssessment?.bAssessments.map((ba) => ba.businessUnit?.id) || [],
      startDate: cAssessment?.startDate || new Date(),
      name: cAssessment?.aggregate.title ?? '',
      contactPersonId: cAssessment?.aggregate.contactPerson?.id,
    },
  });

  const { errors, isValid, isDirty } = useFormState({ control });

  useEffect(() => {
    if (cAssessment) {
      reset({
        businessUnits: cAssessment?.bAssessments.map((ba) => ba.businessUnit?.id) || [],
        startDate: cAssessment?.startDate || new Date(),
        name: cAssessment?.aggregate.title,
        contactPersonId: cAssessment?.aggregate.contactPerson?.id,
      });
    }
  }, [cAssessment]);
  const upsertCompanyAssessment = useUpsertCompanyAssessment();

  const onSubmit = (data: AssessmentFields) => {
    upsertCompanyAssessment(
      {
        id: cAssessment?.id,
        title: data.name,
        startDate: data.startDate,
        businessUnits: [
          ...data.businessUnits.map((bu) => ({ id: bu, isDeleted: false })),
          ...(cAssessment?.bAssessments || [])
            .filter((ba) => !data.businessUnits.includes(ba.businessUnit?.id))
            .map((ba) => ({ id: ba.businessUnit?.id, isDeleted: true })),
        ],
        contactPersonId: data.contactPersonId,
      },
      cAssessment
    ).then((res) => {
      flagAndUnlockAssessment(cAssessment);
      toast({
        text: cAssessment
          ? t('assessment:form.assessmentUpdated')
          : t('assessment:form.assessmentCreated'),
      });
      if (!cAssessment) {
        navigate(`/${companyId}/assessments/${res.data?.cAssessment?.id}`, { replace: true });
      }
    });
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <VStack spacing="16px" alignItems="stretch" width="520px" pt="16px">
        <VStack spacing="28px" flex="1 0 auto" width="100%" alignItems="stretch">
          <Controller
            name="name"
            rules={{
              required: true,
              validate: (value) => value.trim().length !== 0,
            }}
            control={control}
            render={({ field }) => (
              <FormItem
                title={t('common:assessment.assessmentName')}
                isInvalid={!!errors?.name}
                error={t('assessment:form.assessmentInputName')}
                isRequired
                description={t('common:assessment.assessmentNameDescription')}
              >
                <Input
                  size="md"
                  isInvalid={!!errors?.name}
                  onBlur={field.onBlur}
                  value={field.value}
                  ref={field.ref}
                  onChange={(e) => field.onChange(e.currentTarget.value)}
                />
              </FormItem>
            )}
          />
          <FormItem
            isRequired
            title={t('assessment:form.reportingPeriod.title')}
            description={t('assessment:form.reportingPeriod.description')}
          >
            <Controller
              name="startDate"
              control={control}
              render={({ field }) => (
                <DateInput
                  value={new Date(field.value)}
                  setValue={(date) => field.onChange(dateToString(date ?? new Date()))}
                  showYearPicker
                  dateFormat="yyyy"
                />
              )}
            />
          </FormItem>

          <Controller
            name="contactPersonId"
            rules={{ required: t('assessment:form.contactPerson.requiredRules') }}
            control={control}
            render={({ field: { value, onChange } }) => (
              <FormItem
                isRequired
                title={t('common:assessment.assessmentOwner')}
                description={t('common:assessment.assessmentOwnerDescription')}
                error={errors?.contactPersonId?.message ?? ''}
                isInvalid={!!errors?.contactPersonId}
              >
                <UserAssigner assignedTo={value} setAssignedTo={onChange} />
              </FormItem>
            )}
          />
          {/* <Controller
            name="businessUnits"
            control={control}
            rules={{
              required: t('assessment:form.businessUnits.requiredRules'),
              validate: (value) => value.length > 0,
            }}
            render={({ field }) => (
              <FormControl isInvalid={!!errors.businessUnits}>
                <BusinessUnitsSelector selected={field.value} setSelected={field.onChange} />
                <FormErrorMessage>{errors.businessUnits?.message}</FormErrorMessage>
              </FormControl>
            )}
          /> */}
        </VStack>
        <HStack width="100%">
          <Button type="submit" variant="primary" isDisabled={!isDirty || !isValid}>
            {cAssessment
              ? t('assessment:structure.department.form.save')
              : t('assessment:structure.department.form.create')}
          </Button>
        </HStack>
      </VStack>
    </form>
  );
};

export const AssessmentForm = () => {
  const { cAssessmentId } = useParams();
  const { t } = useTranslation(['assessment', 'common']);
  const { data, loading } = useCompanyAssessmentQuery({
    variables: {
      cAssessmentId,
    },
    skip: !cAssessmentId,
  });

  const cAssessment = useMemo(() => {
    return data?.companyAssessment;
  }, [data]);

  return (
    <ContentLayout
      backButton={true}
      variant="narrow"
      header={
        <ContentHeader
          title={
            cAssessment
              ? t('assessment:form.editAssessment')
              : t('assessment:form.createAssessment')
          }
        />
      }
      isLoading={loading}
    >
      <AssessmentEditor cAssessment={cAssessment} />
    </ContentLayout>
  );
};
