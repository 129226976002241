import { Box, Image } from '@chakra-ui/react';

interface LogoProps {
  size?: string;
  textColor?: 'white' | 'black';
}

export const Logo = ({ size = '48px' }: LogoProps) => (
  <>
    <Box height={`${Number(size.split('px')[0]) / 2.4}px`} width={size} position="relative">
      <Image src={`/assets/logo.svg`} objectFit="contain" />
    </Box>
  </>
);
