import {
  AttachmentBox_Constraint_,
  AttachmentBox_Update_Column_,
  EsrsAssessmentDocument_,
  Esrs_MaterialMetric_Constraint_,
  Esrs_MaterialMetric_Update_Column_,
  GetMaterialStandardDocument_,
  NoteHistory_Constraint_,
  NoteHistory_Update_Column_,
  useEsrsAssessmentQuery,
  useGetEsrsCategoriesQuery,
  useGetParentMaterialityAssessmentQuery,
  MaterialStandardMaterialRequirementsDocument_,
  GetDisclosureRequirementGroupsDocument_,
  GetBuDisclosureRequirementMetricsDocument_,
  CompanyLevelMetricsPerDisclosureDocument_,
  ReportingUnitsMetricsPerDisclosureDocument_,
  GetMetricsDrDocument_,
  useUpsertMultipleMaterialStandardsMutation,
  GroupLevelMetricsPerDisclosureDocument_,
} from 'models';
import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useCompanyType } from 'utils/hooks';
import { DataCollectionLevel } from '../DataCollection';
import { MaterialMap } from './MaterialityAssessment.d';
import { orderCategories } from 'containers/Esrs/utils';

export const useGetMaterialityAssessment = (esrsAssessmentId?: string) => {
  const { data, loading: loadingAssessment } = useEsrsAssessmentQuery({
    variables: { esrsAssessmentId },
    skip: !esrsAssessmentId,
  });

  const { data: parentMaterialityAssessmentData, loading: loadingParentMaterialityAssessment } =
    useGetParentMaterialityAssessmentQuery({
      variables: { childAssessmentId: esrsAssessmentId },
      skip: !esrsAssessmentId,
    });

  const { data: esrsCategoriesData, loading: loadingCategories } = useGetEsrsCategoriesQuery();

  const categories = useMemo(() => {
    return orderCategories(esrsCategoriesData?.EsrsCategory ?? []);
  }, [esrsCategoriesData]);

  const materialityAssessments = useMemo(() => {
    const hasParentMaterialityAssessment =
      !!parentMaterialityAssessmentData?.EsrsAssessment_by_pk?.parentAssessment
        ?.materialStandards &&
      parentMaterialityAssessmentData?.EsrsAssessment_by_pk?.parentAssessment?.materialStandards
        ?.length > 0;
    if (data?.esrsAssessment?.materialStandards) {
      const mappedCategories: MaterialMap[] = categories.map((category) => {
        return {
          categoryName: category.title,
          categoryRef: category.reference,
          materialStandards:
            category.standards
              ?.map((standard) => {
                const materialStandard = data?.esrsAssessment?.materialStandards?.find((ms) => {
                  return ms.standardRef === standard.reference;
                });

                return {
                  id: materialStandard?.id,
                  standardRef: standard.reference,
                  standardName: standard.title,
                  description: standard.description,
                  isTopical: standard.isTopical,
                  disclosureRequirementGroups: standard.disclosureRequirementGroups,
                  isMaterial: materialStandard?.isMaterial,
                  isDataGatheringOnly: materialStandard?.isDataGatheringOnly,
                  isCopiedFromParent: materialStandard?.isCopiedFromParent,
                  isParentMaterial:
                    parentMaterialityAssessmentData?.EsrsAssessment_by_pk?.parentAssessment?.materialStandards.find(
                      (ms) => {
                        return ms.standardRef === standard.reference;
                      }
                    )?.isMaterial,
                  metrics: materialStandard?.materialMetrics ?? [],
                  parentCompanyMetrics:
                    parentMaterialityAssessmentData?.EsrsAssessment_by_pk?.parentAssessment?.materialStandards.find(
                      (ms) => {
                        return ms.standardRef === standard.reference;
                      }
                    )?.materialMetrics ?? [],
                  isDataCollected: materialStandard?.isDataCollected ?? false,
                };
              })
              ?.sort((a, b) => a.standardRef.localeCompare(b.standardRef)) ?? [],
          hasParentMaterialityAssessment,
        };
      });
      return mappedCategories;
    } else return [];
  }, [data, parentMaterialityAssessmentData, esrsCategoriesData]);

  const loading = useMemo(() => {
    return loadingAssessment || loadingCategories || loadingParentMaterialityAssessment;
  }, [loadingAssessment, loadingCategories, loadingParentMaterialityAssessment]);

  return {
    materialityAssessments,
    loading,
  };
};

export const useAddMandatoryMaterialityAssessment = () => {
  const { esrsAssessmentId } = useParams();
  const [addAssessment] = useUpsertMultipleMaterialStandardsMutation();
  const { companyType } = useCompanyType();
  const isGroupOwner = useMemo(() => companyType === 'group-company', [companyType]);

  return useCallback(
    (
      mandatoryStandards: {
        standardRef: string;
        metrics: string[];
      }[]
    ) => {
      addAssessment({
        variables: {
          materialStandards: mandatoryStandards.map((standard) => ({
            assessmentId: esrsAssessmentId,
            standardRef: standard.standardRef,
            isMaterial: true,
            materialMetrics: {
              data:
                standard.metrics
                  ?.filter((m) => m !== '')
                  .map((metricRef) => ({
                    isMaterial: true,
                    metricRef,
                    dataCollection: isGroupOwner
                      ? DataCollectionLevel.group
                      : DataCollectionLevel.company,
                  })) ?? [],
              on_conflict: {
                constraint:
                  Esrs_MaterialMetric_Constraint_.MaterialMetricMaterialStandardIdMetricRefKey_,
                update_columns: [
                  Esrs_MaterialMetric_Update_Column_.Metadata_,
                  Esrs_MaterialMetric_Update_Column_.IsMaterial_,
                  Esrs_MaterialMetric_Update_Column_.DataCollection_,
                  Esrs_MaterialMetric_Update_Column_.IsDataGatheringOnly_,
                ],
              },
            },
            attachmentBox: {
              data: {},
              on_conflict: {
                constraint: AttachmentBox_Constraint_.AttachmentBoxMaterialityAssessmentIdKey_,
                update_columns: [AttachmentBox_Update_Column_.MaterialStandardId_],
              },
            },
            noteHistory: {
              data: {},
              on_conflict: {
                constraint: NoteHistory_Constraint_.NoteHistoryMaterialityAssessmentIdKey_,
                update_columns: [NoteHistory_Update_Column_.MaterialStandardId_],
              },
            },
          })),
        },
        refetchQueries: [
          EsrsAssessmentDocument_,
          GetMaterialStandardDocument_,
          MaterialStandardMaterialRequirementsDocument_,
          GetDisclosureRequirementGroupsDocument_,
          GetMetricsDrDocument_,
          GetBuDisclosureRequirementMetricsDocument_,
          CompanyLevelMetricsPerDisclosureDocument_,
          GroupLevelMetricsPerDisclosureDocument_,
          ReportingUnitsMetricsPerDisclosureDocument_,
        ],
      });
    },
    [addAssessment]
  );
};

export const getUnassessedMandatoryStandardData = (materialityAssessments: MaterialMap[]) => {
  const mandatoryStandards =
    materialityAssessments?.flatMap((assessment) =>
      assessment.materialStandards?.filter((standard) => !standard.isTopical)
    ) ?? [];
  const standardDisclosures = mandatoryStandards?.map((standard) => {
    const disclosureRequirements = standard?.disclosureRequirementGroups?.flatMap(
      (drg) => drg?.requirements
    );

    const currentMaterialMetrics = materialityAssessments
      .flatMap((assessment) => assessment.materialStandards)
      .find((s) => s.standardRef === standard.standardRef)?.metrics;
    const unassessedMetrics =
      disclosureRequirements
        ?.flatMap((req) => req.metrics?.map((metric) => metric.reference))
        ?.filter(
          (reference) => !currentMaterialMetrics?.find((mm) => mm.metricRef === reference)
        ) ?? [];

    return {
      standardRef: standard.standardRef,
      metrics: unassessedMetrics,
    };
  });
  return standardDisclosures;
};
