import {
  Avatar,
  AvatarBadge,
  HStack,
  VStack,
  StackProps,
  Divider,
  useDisclosure,
  Box,
  Center,
} from '@chakra-ui/react';
import { TextWithMentions, UserAvatar } from 'Organisms';
import { Button, EmptyState, ExternalLink, IconButton, Link } from 'Atoms';
import { Notification } from 'models';

import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'utils/translation';
import {
  CompanyInvitationsModal,
  getNotificationLink,
  useNotifications,
  usePendingInvitations,
} from 'Features';
import { setTimeoutInHook } from 'utils/hooks';
import { useSearchParams } from 'react-router-dom';
import { timeSinceDate } from 'utils/date';
import { useUserData } from '@nhost/react';
import { useUserSetting } from 'containers/Navigation';
import { Drawer } from 'Molecules';
import { NotificationIcon } from 'Tokens/Icons/Function';
import { IdeaIcon } from 'Tokens/Icons/Status';
import { COMPANY_LEVEL_ASSESSMENT_ID } from 'containers/Assessments/Assessments.hooks';
import { Typography } from 'Tokens';
import { CompanyIcon } from 'Tokens/Icons/Data';
import { breadcrumbWithEllipsis } from 'utils/strings';
import {
  PrivacyPolicyNotification,
  privacyPolicyNotificationDismissedKey,
} from './Pieces/PrivacyPolicyNotification';

const LATEST_RELEASE_NOTIFICATION = 'new_release_message_july';
const NOTIFICATION_ITEM_ARIA_LABEL = 'clickable notification item';

export const NotificationItem = ({
  notification,
  ...rest
}: {
  notification: Notification;
} & StackProps) => {
  const { comment } = notification;
  const isEsrsAnswer = !!comment.thread.esrsDatapoint;
  const {
    thread: { answer },
  } = comment;
  const businessUnit = answer?.activityReport?.bAssessment?.businessUnit;
  const cAssessmentId = answer?.activityReport?.bAssessment?.cAssessmentId;
  const cAssessmentName = answer?.activityReport?.bAssessment?.cAssessment.aggregate.title ?? '';
  const activityReference = answer?.activityReport?.activity.reference;
  const activityName = answer?.activityReport?.activity.name;
  const businessUnitId = businessUnit?.id ?? COMPANY_LEVEL_ASSESSMENT_ID;
  const businessUnitName = businessUnit?.name ?? 'General';
  const questionId = answer?.question.uniqueId;
  const company = answer?.answerCompany?.company;
  const companyName = answer?.answerCompany?.company?.name ?? '';
  const breadcrumb = breadcrumbWithEllipsis([companyName, cAssessmentName, businessUnitName], 15);

  const {
    thread: { esrsDatapoint },
  } = comment;
  const reportingUnit = esrsDatapoint?.answer.reportingUnit;
  const esrsAssessment = esrsDatapoint?.answer.reportingUnit.esrsAssessment;
  const esrsAssessmentYear = String(esrsAssessment?.reportingYear);

  const disclosureRequirement = esrsDatapoint?.answer.metric.requirement;
  const standardRef = disclosureRequirement?.group.standardRef;
  const esrsCompany = esrsAssessment?.company;
  const esrsBreadcrumb = useMemo(
    () =>
      breadcrumbWithEllipsis(
        [esrsCompany?.name ?? '', esrsAssessmentYear, reportingUnit?.name ?? ''],
        15
      ),
    [esrsCompany, esrsAssessmentYear, reportingUnit]
  );

  const link = useMemo(
    () =>
      getNotificationLink({
        isEsrs: isEsrsAnswer,
        companyId: isEsrsAnswer ? esrsCompany?.id : company?.id,
        assessmentId: isEsrsAnswer ? esrsAssessment?.id : cAssessmentId,
        businessUnitId: isEsrsAnswer ? reportingUnit?.id : businessUnitId,
        activityReference,
        questionId,
        standardRef,
        disclosureRequirement,
        esrsDatapointId: esrsDatapoint?.id ?? '',
        metricRef: esrsDatapoint?.answer.metricRef ?? '',
        isCompanyLevel: reportingUnit?.isCompanyLevel,
      }),
    [
      isEsrsAnswer,
      esrsCompany,
      company,
      esrsAssessment,
      cAssessmentId,
      businessUnitId,
      activityReference,
      questionId,
      standardRef,
      disclosureRequirement,
      reportingUnit,
      esrsDatapoint,
    ]
  );

  const translations = useTranslation('common');
  const { t } = translations;
  const time = timeSinceDate(new Date(comment.createdAt), translations);

  return (
    <VStack
      _hover={{ bg: 'bg.selected' }}
      p="8px 16px 16px"
      aria-label={NOTIFICATION_ITEM_ARIA_LABEL}
      borderTop="1px solid"
      borderColor="border.decorative"
      width="100%"
      alignItems="start"
    >
      <Link to={link} _hover={{ textDecoration: 'none' }} width="100%">
        <HStack pb="8px" w="100%" justifyContent="space-between">
          <HStack width="100%">
            <Center w="16px" h="16px" borderRadius="6px" bg="bg.accent" ml="4px">
              <CompanyIcon boxSize="12px" color="white" />
            </Center>
            <Typography variant="body" color="text.muted">
              {isEsrsAnswer ? esrsBreadcrumb : breadcrumb}
            </Typography>
          </HStack>
          <Typography variant="detail" color="text.muted">
            {time}
          </Typography>
        </HStack>

        <HStack align="start" width="100%" pt="4px" {...rest}>
          <UserAvatar user={comment.author} size="sm" />
          <VStack align="start" width="100%">
            <Box>
              <Typography as="span" variant="bodyStrong">
                {comment?.author?.displayName ?? t('common:user.deletedUserName')}{' '}
              </Typography>
              <Typography as="span" variant="body">
                {t('common:notifications.comment')}{' '}
              </Typography>
              <Typography as="span" variant="bodyStrong" color="text.action">
                {activityName}
              </Typography>
              {isEsrsAnswer ? (
                <Typography as="span" variant="bodyStrong" color="text.action">
                  {esrsDatapoint?.answer?.metric?.shortTitle ??
                    esrsDatapoint?.answer?.metric?.title}
                </Typography>
              ) : null}
            </Box>
            <VStack bg="#F5F5F5" align="start" width="100%" p="16px" borderRadius="md">
              <TextWithMentions value={comment.data} />
            </VStack>
          </VStack>
        </HStack>
      </Link>
    </VStack>
  );
};

export const ReleaseAlertNotification = () => {
  const [, isClosed] = useUserSetting(LATEST_RELEASE_NOTIFICATION, false);
  const { t } = useTranslation('common');
  return (
    <HStack
      align="start"
      width="100%"
      p="16px 16px"
      borderTop="1px solid"
      borderColor="border.decorative"
      justifyContent="space-between"
      spacing="2px"
    >
      <HStack align="start">
        <IdeaIcon color="primary.main" />
        <HStack spacing="3px" align="start">
          <Typography variant="bodyStrong" as="span">
            {t('common:notifications.new')}
          </Typography>
          <Typography variant="body" as="span">
            {t('common:notifications.readMore')}{' '}
          </Typography>
          <ExternalLink
            onClick={() => isClosed(true)}
            alignSelf="start"
            target="_blank"
            rel="noopener noreferrer"
            href="https://celsia.notion.site/July-1st-Celsia-Release-c4b5ccc4aa784acaaae53eb13d3aaf13"
          >
            <HStack alignItems="start">
              <Typography variant="body" as="span">
                {' '}
                {t('common:words.here')}
              </Typography>
            </HStack>
          </ExternalLink>
        </HStack>
      </HStack>
      <Button onClick={() => isClosed(true)} size="xs">
        {t('common:button.dismiss')}
      </Button>
    </HStack>
  );
};

export const EmptyNotifications = () => {
  const { t } = useTranslation('common');
  return (
    <Box w="100%" h="100%" p="16px">
      <EmptyState
        title={t('common:notifications.empty.title')}
        description={t('common:notifications.empty.description')}
        component={true}
      />
    </Box>
  );
};

export function NotificationsMenu() {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const { notifications, readNotifications, totalNotificationsCount, unreadNotificationsCount } =
    useNotifications();
  const { t } = useTranslation('common');
  const user = useUserData();
  const { invitations = [] } = usePendingInvitations({ userEmail: user?.email ?? '' });
  const [searchParams] = useSearchParams();
  const [isReleaseNotificationClosed] = useUserSetting(LATEST_RELEASE_NOTIFICATION, false);
  const [isPrivacyPolicyNotificationClosed] = useUserSetting(
    privacyPolicyNotificationDismissedKey,
    false
  );
  const notificationCount = useMemo(
    () =>
      unreadNotificationsCount +
      invitations?.length +
      (isReleaseNotificationClosed ? 0 : 1) +
      (isPrivacyPolicyNotificationClosed ? 0 : 1),
    [unreadNotificationsCount, isPrivacyPolicyNotificationClosed]
  );
  useEffect(() => {
    const unreadNotificationIds =
      notifications?.filter((n) => !n.isRead).map((n) => n.id) || ([] as string[]);

    if (isOpen && unreadNotificationIds.length) {
      return setTimeoutInHook(() => readNotifications(unreadNotificationIds), 2000);
    }
  }, [isOpen, notifications]);
  const [showInvitation, setShowInvitation] = useState<string>(
    (searchParams.get('invitationId') as string) ?? ''
  );

  useEffect(() => {
    const invitationId = searchParams.get('invitationId');
    if (invitationId) {
      setShowInvitation(invitationId);
    }
  }, [searchParams]);
  return (
    <>
      <Drawer
        onClose={onClose}
        isOpen={isOpen}
        size="md"
        header={
          <Typography variant="bodyStrong">{t('common:notifications.notifications')}</Typography>
        }
        returnFocusOnClose={false}
        isClosable={false}
        closeOnOverlayClick={true}
        headerProps={{ padding: '16px' }}
        bodyProps={{ padding: '0px' }}
      >
        <VStack
          spacing="0px"
          h="100%"
          onClick={(e) => {
            if (['A', 'BUTTON'].includes((e.target as HTMLElement).tagName)) onClose();

            const checkGrandparentsForLabel = (
              parent: HTMLElement | null,
              label: string
            ): boolean => {
              if (parent?.ariaLabel === label) return true;
              if (parent?.parentElement)
                return checkGrandparentsForLabel(parent.parentElement, label);
              return false;
            };

            if (
              checkGrandparentsForLabel(
                (e.target as HTMLElement).parentElement,
                NOTIFICATION_ITEM_ARIA_LABEL
              )
            ) {
              onClose();
            }
          }}
        >
          {!isReleaseNotificationClosed && (
            <>
              <ReleaseAlertNotification />
            </>
          )}
          <PrivacyPolicyNotification />
          {invitations.map((invitation) => (
            <HStack
              key={invitation.id}
              width="100%"
              justifyContent="space-between"
              p="16px"
              borderTop="1px solid"
              borderColor="border.decorative"
            >
              <Typography variant="body">
                {t('common:invitations.invitationNotification')}
              </Typography>
              <Button onClick={() => setShowInvitation(invitation.id)} size="xs">
                {t('common:button.show')}
              </Button>
            </HStack>
          ))}
          {!!invitations.length && <Divider />}
          {notifications.map((notification) => (
            <NotificationItem key={notification.id} notification={notification} />
          ))}
          {totalNotificationsCount === 0 && isReleaseNotificationClosed && <EmptyNotifications />}
        </VStack>
      </Drawer>
      {user && showInvitation && (
        <CompanyInvitationsModal
          invitationId={showInvitation}
          onClose={() => setShowInvitation('')}
          user={user}
        />
      )}
      <IconButton
        variant="secondary"
        size="sm"
        aria-label={t('common:notifications.notifications')}
        onClick={onOpen}
        tooltipLabel={t('common:notifications.notifications')}
        icon={
          <Avatar
            cursor="pointer"
            icon={<NotificationIcon color="inherit" />}
            variant="icon"
            boxSize="28px"
          >
            {notificationCount ? (
              <AvatarBadge
                boxSize="16px"
                bg="bg.critical.accent"
                color="text.onAccent"
                fontSize="10px"
                fontWeight="600"
                animation={notificationCount > 0 ? 'sonar 2s 10' : undefined}
                border="2px solid"
                borderColor="bg.default"
                padding="2px"
              >
                {notificationCount > 99 ? '+99' : notificationCount}
              </AvatarBadge>
            ) : undefined}
          </Avatar>
        }
      />
    </>
  );
}
