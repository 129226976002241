import { Box, HStack } from '@chakra-ui/react';
import { ColumnDefResolved } from '@tanstack/react-table';
import { IconButton, Tooltip, TruncatableText } from 'Atoms';
import { NestedTable, TableData } from 'Molecules/NestedTable';
import { Typography } from 'Tokens';
import { ChevronDownIcon, ChevronRightIcon } from 'Tokens/Icons/Direction';
import { MaximizeIcon } from 'Tokens/Icons/Function';
import { HelpIcon } from 'Tokens/Icons/Status';
import { SelectedMetric } from 'containers/Esrs';
import { mapUnitToCompanyCurrency } from 'containers/Esrs/utils';
import { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useCurrentCompanyId } from 'utils/hooks';
import { FrequencyEnums, QuartersEnums, TimePeriods, TimePeriodsEnums } from '../../Requirement';
import { getMetricChartUrl } from '../Metrics.utils';
import { AggregatedMetricsTableData } from './AggregatedMetrics.hooks';
import { showResult, showResultAsPercentage } from './calculations';
import { MetricRowTitle } from '../InputTable/MetricsUtils';

export const QuarterField = ({
  frequency,
  value,
  selectedQuarter,
  showAsPercentage,
  allMetrics,
  currentMetric,
}: {
  frequency: string;
  value: number | string;
  selectedQuarter: TimePeriods;
  showAsPercentage?: boolean;
  allMetrics: AggregatedMetricsTableData[];
  currentMetric: AggregatedMetricsTableData;
}) => {
  const isYearly = useMemo(() => frequency === FrequencyEnums.yearly, [frequency]);

  const tags = useMemo(() => {
    return currentMetric.tags ?? [];
  }, [currentMetric]);

  const percentageTotal = useMemo(() => {
    if (currentMetric.tags?.length) {
      const parentMetric = allMetrics?.find(
        (m) => m.metric.reference === currentMetric.metric.reference && !m.tags?.length
      );
      return showResultAsPercentage(value, parentMetric?.result?.[selectedQuarter] ?? 0);
    }

    return showResultAsPercentage(100, 100);
  }, [allMetrics, currentMetric]);

  if (isYearly && selectedQuarter !== TimePeriodsEnums.year)
    return (
      <Typography variant="body" color="text.hint">
        N/A
      </Typography>
    );

  if (showAsPercentage) {
    return (
      <Typography variant="body">
        {showResult(value)}

        <Tooltip
          label={`${currentMetric.metric.title} ${tags.length ? ' by ' : ''} ${tags.map(
            (x) => x.tagValue
          )}: ${percentageTotal.replace('(', '').replace(')', '')}`}
          cursor="pointer"
        >
          <span style={{ cursor: 'pointer', fontSize: '14px', marginLeft: '4px' }}>
            {percentageTotal}
          </span>
        </Tooltip>
      </Typography>
    );
  }
  return <Typography variant="body">{showResult(value)}</Typography>;
};

export const AggregatedMetricsTable = ({
  metrics,
  selectedQuarter,
  onDrawerOpen,
  setSelectedMetric,
  isOverview,
  areMetricsYearly,
  isGroup,
  currency,
  companyAssessmentId,
}: {
  metrics: AggregatedMetricsTableData[];
  selectedQuarter: TimePeriods;
  onDrawerOpen: () => void;
  setSelectedMetric: (param: SelectedMetric) => void;
  isOverview: boolean;
  areMetricsYearly: boolean;
  isGroup: boolean;
  currency: string;
  companyAssessmentId: string;
}) => {
  const navigate = useNavigate();
  const { esrsAssessmentId = '', standardRef = '', disclosureRequirementRef = '' } = useParams();
  const { companyId } = useCurrentCompanyId();
  const nestedColumns: ColumnDefResolved<TableData<AggregatedMetricsTableData>, any>[] | undefined =
    useMemo(
      () => [
        {
          id: 'expander',
          meta: {
            width: '36px',
            padding: '0px',
          },
          cell: ({ row }) =>
            row.getCanExpand() && (
              <Box onClick={(e) => e.stopPropagation()}>
                <IconButton
                  variant={'ghost'}
                  size="md"
                  onClick={row.getToggleExpandedHandler()}
                  aria-label="expand"
                  icon={row.getIsExpanded() ? <ChevronDownIcon /> : <ChevronRightIcon />}
                />
              </Box>
            ),
        },
        {
          header: 'Metric',
          meta: {
            width: '50%',
          },
          accessorKey: 'metric',
          cell: ({ row }) => {
            return (
              <MetricRowTitle companyStandardId={companyAssessmentId} row={row} isAggregated />
            );
          },
        },
        {
          header: 'Unit',
          accessorKey: 'unit',
          cell: ({ row }) => (
            <TruncatableText
              text={mapUnitToCompanyCurrency(
                row.original.metric.unitOfMeasurement ?? 'NA',
                currency
              )}
            />
          ),
        },
        {
          header: QuartersEnums.q1,
          meta: {
            width: '10.5%',
          },
          accessorKey: 'quarter1',
          cell: ({ row }) => (
            <QuarterField
              frequency={
                row.original.metric.materialMetrics?.find(
                  (mm) => mm.materialStandardId === companyAssessmentId
                )?.frequency ?? ''
              }
              value={row.original.result?.Q1 ?? 0}
              selectedQuarter={TimePeriodsEnums.q1}
              allMetrics={metrics}
              currentMetric={row.original}
              showAsPercentage={row.original.metric?.showAsPercentage ?? false}
            />
          ),
        },
        {
          header: QuartersEnums.q2,
          meta: {
            width: '10.5%',
          },
          accessorKey: 'quarter2',
          cell: ({ row }) => (
            <QuarterField
              frequency={
                row.original.metric.materialMetrics?.find(
                  (mm) => mm.materialStandardId === companyAssessmentId
                )?.frequency ?? ''
              }
              value={row.original.result?.Q2 ?? 0}
              selectedQuarter={TimePeriodsEnums.q2}
              allMetrics={metrics}
              currentMetric={row.original}
              showAsPercentage={row.original.metric?.showAsPercentage ?? false}
            />
          ),
        },
        {
          header: QuartersEnums.q3,
          meta: {
            width: '10.5%',
          },
          accessorKey: 'quarter3',
          cell: ({ row }) => (
            <QuarterField
              frequency={
                row.original.metric.materialMetrics?.find(
                  (mm) => mm.materialStandardId === companyAssessmentId
                )?.frequency ?? ''
              }
              value={row.original.result?.Q3 ?? 0}
              selectedQuarter={TimePeriodsEnums.q3}
              allMetrics={metrics}
              currentMetric={row.original}
              showAsPercentage={row.original.metric?.showAsPercentage ?? false}
            />
          ),
        },
        {
          header: QuartersEnums.q4,
          meta: {
            width: '10.5%',
          },
          accessorKey: 'quarter4',
          cell: ({ row }) => (
            <QuarterField
              frequency={
                row.original.metric.materialMetrics?.find(
                  (mm) => mm.materialStandardId === companyAssessmentId
                )?.frequency ?? ''
              }
              value={row.original.result?.Q4 ?? 0}
              selectedQuarter={TimePeriodsEnums.q4}
              allMetrics={metrics}
              currentMetric={row.original}
              showAsPercentage={row.original.metric?.showAsPercentage ?? false}
            />
          ),
        },
        {
          header: selectedQuarter !== TimePeriodsEnums.year ? selectedQuarter : 'Total',
          meta: {
            width: '10.5%',
          },
          accessorKey: 'quarter',
          cell: ({ row }) => (
            <QuarterField
              frequency={
                row.original.metric.materialMetrics?.find(
                  (mm) => mm.materialStandardId === companyAssessmentId
                )?.frequency ?? ''
              }
              value={row.original.result?.[selectedQuarter] ?? 0}
              selectedQuarter={selectedQuarter}
              allMetrics={metrics}
              currentMetric={row.original}
              showAsPercentage={row.original.metric?.showAsPercentage ?? false}
            />
          ),
        },
        {
          header: 'Total',
          meta: {
            width: '10.5%',
          },
          accessorKey: 'yearly',
          cell: ({ row }) => {
            return (
              <QuarterField
                frequency={row.original.metric.materialMetrics[0]?.frequency}
                value={row.original.result?.Year ?? 0}
                selectedQuarter={TimePeriodsEnums.year}
                allMetrics={metrics}
                currentMetric={row.original}
                showAsPercentage={row.original.metric?.showAsPercentage ?? false}
              />
            );
          },
        },
        {
          header: 'Frequency',
          meta: {
            width: '10.5%',
          },
          accessorKey: 'frequency',
          cell: ({ row }) => (
            <Typography variant="body">
              {row.original.metric.materialMetrics?.find(
                (mm) => mm.materialStandardId === companyAssessmentId
              )?.frequency ?? ''}
            </Typography>
          ),
        },
        {
          header: '',
          meta: {
            width: '3.125%',
          },
          accessorKey: 'extras',
          cell: ({ row }) => {
            const metric = row.original.metric;

            return (
              <HStack spacing="2px">
                <IconButton
                  aria-label="maximize"
                  variant="ghost"
                  icon={<MaximizeIcon />}
                  size="md"
                  onClick={() => {
                    const url = getMetricChartUrl({
                      row: row.original,
                      metricRef: metric.reference,
                      standardId: companyAssessmentId,
                      companyId,
                      esrsAssessmentId,
                      standardRef,
                      disclosureRequirementRef,
                    });
                    navigate(url);
                  }}
                />
                <IconButton
                  aria-label="learn more"
                  variant="ghost"
                  icon={<HelpIcon />}
                  size="md"
                  onClick={() => {
                    setSelectedMetric({
                      reference: metric.reference,
                      description: metric.description ?? '',
                      tags: metric.materialMetrics
                        ?.find((mm) => mm.materialStandardId === companyAssessmentId)
                        ?.materialMetricTags?.map((tag) => ({
                          type: tag.tagType,
                        })),
                    });
                    onDrawerOpen();
                  }}
                />
              </HStack>
            );
          },
        },
      ],
      [metrics, selectedQuarter, onDrawerOpen, setSelectedMetric, isOverview, areMetricsYearly]
    );

  const filteredColumns = useMemo(() => {
    const quarterFields = ['quarter1', 'quarter2', 'quarter3', 'quarter4'];
    if (isGroup) {
      if (areMetricsYearly) {
        return nestedColumns.filter(
          (col) => col.accessorKey !== 'quarter' && !quarterFields.includes(col.accessorKey ?? '')
        );
      }
      return nestedColumns.filter((col) => col.accessorKey !== 'quarter');
    }
    if (isOverview) {
      if (areMetricsYearly) {
        return nestedColumns.filter(
          (col) => col.accessorKey !== 'quarter' && !quarterFields.includes(col.accessorKey ?? '')
        );
      }
      return nestedColumns.filter((col) => col.accessorKey !== 'quarter');
    }
    if (selectedQuarter === TimePeriodsEnums.year)
      return nestedColumns.filter(
        (column) =>
          !quarterFields.includes(column.accessorKey ?? '') && column.accessorKey !== 'yearly'
      );
    else return nestedColumns.filter((col) => !quarterFields.includes(col.accessorKey ?? ''));
  }, [nestedColumns, selectedQuarter]);

  return (
    <NestedTable<AggregatedMetricsTableData>
      columns={filteredColumns}
      data={metrics}
      withBorder={true}
    />
  );
};
